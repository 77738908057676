import React, { useState, useEffect, useContext } from 'react';
import { baseURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { toast } from 'react-toastify';
import SelectBox from 'components/global/SelectBox.jsx';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { ClipLoader } from 'react-spinners';
import { BsX } from "react-icons/bs";
import { BsFloppy } from "react-icons/bs";

export const EventsNewModal = ({ slot, closeModal, events, setEvents }) => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);

  const [title, setTitle] = useState("");
  const [allDay, setAllDay] = useState(true);
  const [people, setPeople] = useState(null);
  const [startDate, setStartDate] = useState(slot.start);
  const [endDate, setEndDate] = useState(new Date(slot.end.getTime() - 1000));
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/events/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);

      events.push(jsonData.data.doc);
      setEvents([...events]);

      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleNewEventForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!title) {
      errors.push(<div>Event title cannot be empty</div>);
    }

    if (endDate.getTime() < startDate.getTime()) {
      errors.push(<div>Event start date, time can't be earlier than end date, time.</div>);
    }

    let allDayStart, allDayEnd;

    if (allDay) {
      allDayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      allDayEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
    }
    else {
      if (endDate.getTime() === startDate.getTime()) {
        errors.push(<div>Event start date, time can't be equal to end date, time.</div>);
      }
    }

    if (!status) {
      errors.push(<div>Event status is required</div>);
    }

    let payload = {
      people: people ? people : [],
      title,
      allDay,
      startDate: allDay ? allDayStart : startDate,
      endDate: allDay ? allDayEnd : endDate,
      status,
      description: description ? description : ""
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <div className='text-sm'>
        <div className="flex flex-row justify-between mb-6">
          <div>New event</div>
          <button onClick={closeModal}>X</button>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            {message && message}
          </div>
        </div>
        {
          isLoading && (
            <div className="text-center mb-6">
              <ClipLoader size={50} color="#000" />
            </div>
          )
        }
      </div>
    );
  }

  const handleSelectedOptions = (selectedOptions) => {
    let peopleList = [];
    if (selectedOptions && selectedOptions.length > 0) {
      peopleList = selectedOptions.map((option) => {
        return option.value; //an ObjectId
      });
    }
    setPeople(peopleList);
  }

  const statusOptionsList = [
    { value: 'onSchedule', label: 'On Schedule' },
    { value: 'cancelled', label: 'Cancelled' },
  ].map((type) => {
    return {
      value: type.value,
      label: type.label
    }
  });

  let employeesOptionsList = employees && employees.length !== 0 && employees.map((employee, index) => {
    return {
      value: employee._id,
      label: `${employee.full_name} ${employee.custom_id}`
    }
  });

  console.log("Before EventsNewModal() rendering");
  console.debug(slot.start);
  console.debug(slot.end);

  return (
    <div className='text-sm'>
      <div className="flex flex-row justify-between mb-6">
        <div>New event</div>
        <button onClick={closeModal}>X</button>
      </div>

      <div className='flex flex-row justify-start mb-4'>
        <div>
          {message && message}
        </div>
      </div>

      <div className="flex flex-col gap-y-4">

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            Title *
          </div>
          <div className='w-full md:w-8/12'>
            <input
              type="text"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className='border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            People
          </div>
          <div className='w-full md:w-8/12'>
            <Select
              isMulti
              name="people"
              onChange={handleSelectedOptions}
              options={
                employeesOptionsList
              }
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#8E8EA1',
                }),
              }}
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            All day *
          </div>
          <div className='w-full md:w-8/12'>
            <input
              type="checkbox"
              name="allDay"
              defaultChecked={allDay}
              onChange={(e) => setAllDay(e.target.checked)}
            />
          </div>
        </div>

        {
          allDay ? (
            <React.Fragment>
              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  Start Date *
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
              </div>

              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  End Date *
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  Start Date *
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeInput
                    timeInputLabel="Time:"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </div>

              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  End Date *
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeInput
                    timeInputLabel="Time:"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            Status *
          </div>
          <div className='w-full md:w-8/12'>
            <SelectBox
              name="status"
              options={
                statusOptionsList
              }
              handleChange={(selectedOption) => setStatus(selectedOption.value)}
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            Description
          </div>
          <div className='w-full md:w-8/12'>
            <textarea
              rows="4"
              name="description"
              value={description}
              onChange={(e) => {
                e.target.value ? setDescription(e.target.value) : setDescription("");
              }}
              className='mb-4 border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
            >
            </textarea>
          </div>
        </div>

      </div>

      <div className="flex flex-row gap-2 justify-end">
        <button
          disabled={isSubmitting || isDisabled}
          onClick={handleNewEventForm}
          className='flex flex-row gap-x-2 items-center py-1 px-2 border rounded-md bg-primary text-white'
        >
          <BsFloppy />
          {isSubmitting ? <ClipLoader size={14} color="#fff" /> : "Create event"}
        </button>
        <button
          onClick={(e) => closeModal()}
          className='flex flex-row gap-x-2 items-center py-1 px-2 border rounded-md'
        >
          <BsX />
          Close
        </button>
      </div>
    </div>
  );
}