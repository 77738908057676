import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import './vendors.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';

export const Vendors = () => {
  const [message, setMessage] = useState(null);
  const [vendors, setVendors] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  async function fetchData() {
    let url = baseURL + "/vendors/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const vendors = jsonData.data.docs;
    setVendors(vendors);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("vendors")
    ) && (
      <Link to="/vendors/new">
        <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
          <span><FaPlus /></span>
          <span>Add Vendor</span>
        </button>
      </Link>
    )

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-bold'>Vendors</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let vendorsList = [];

  if (vendors && vendors.length !== 0) { //on second render
    vendorsList = vendors.map((vendor, index) => {
      let editLink = "/vendors/edit/" + vendor._id;
      let viewLink = "/vendors/" + vendor._id;
      return (
        <tr key={vendor._id}>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{vendor.vendorID}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
            <Link to={viewLink} className='text-white bg-primary rounded-[40px] p-2'>{vendor.vendorCompanyName}</Link>
          </td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{vendor.vendorAddress}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{vendor.vendorPhone}</td>
          <td className="  px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link></td>
          <td className='px-8 py-4 whitespace-nowrap '>
            <button onClick={handleDelete}><RiDeleteBin6Line className='text-lg text-[#E30411]' /></button></td>
        </tr>
      );
    });
  }

  console.log("Before Vendors() rendering");

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Vendors</h2>
          {NewDocumentLink}
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className='w-full table-auto overflow-x-auto'>
            <thead className='text-[14px] border border-b-[#6843BE] '>
              <tr className='bg-lightSlate'>
                <th scope="col" className="  font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="  font-medium text-primary px-6 py-4 text-left">Vendor Company Name</th>
                <th scope="col" className="  font-medium text-primary px-6 py-4 text-left">Vendor Address</th>
                <th scope="col" className="  font-medium text-primary px-6 py-4 text-left">Vendor Phone</th>
                <th scope="col" className=" font-semibold	text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className=" font-semibold	text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {vendorsList}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}
