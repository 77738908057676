import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link,NavLink } from 'react-router-dom';
import styles from './companies.module.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { FaPlus } from "react-icons/fa6";
import Loader from 'components/global/Loader';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";


export const Companies = () => {
  const [message, setMessage] = useState(null);
  const [companies, setCompanies] = useState([]);
  const { userData } = useContext(AuthContext);
  const [loading,setLoading] = useState(false);

  
  async function fetchData() {
    setLoading(true);
    let url = baseURL + "/companies/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    setLoading(false);
    const companies = jsonData.data.docs;
    setCompanies(companies);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  console.log("Before Companies() rendering");

//   if(companies.length === 0) {
//     return (
//       <Layout>
//  <div className={styles["companies-list"]}>
//         <h2>Companies</h2>
//         {message && message}
//         <div>Fetching data from the server. Please wait!</div>
//       </div>
//       </Layout>
     
//     );
//   }

  let companiesList = [];
  
  if( companies.length !== 0 ) {
    companiesList = companies.map( (company, index) => {
      let editLink = "/companies/edit/" + company._id;
      return (
        <tr key={index}>
          <td className=" text-gray-900  px-6 py-3 whitespace-nowrap">{company.name}</td>
          <td className="  text-primary px-6 py-3 whitespace-nowrap"><Link to={editLink}><CiEdit className='text-2xl '/></Link></td>
          <td className="    whitespace-nowrap   "><button onClick={handleDelete} className='px-7 py-3'><RiDeleteBin6Line className='text-lg text-[#E30411]'/></button></td>
        </tr>
      );
    });
  }

  return (
    <Layout>
    <div className='space-y-4'>
    <div className='flex flex-row justify-between items-center px-3 '>
    <h2 className='text-2xl text-primary font-bold	'>Companies</h2>
    <NavLink to="/companies/new">
    <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
     <span><FaPlus/></span>   <span>Add Company</span>
    </button>
    </NavLink>
    </div>
    {message && message} 
 <div className='overflow-hidden rounded-lg  border border-[#6843BE] '>
 {loading ?<Loader/>:  <table className="w-full     ">
          <thead className="   text-[14px] border border-b-[#6843BE]   " >
            <tr className='bg-lightSlate'>
              <th scope="col" className="  font-medium text-primary px-6 py-4 text-left">Company</th>
              <th scope="col" className="   font-semibold text-primary px-6 py-4 text-left">Edit</th>
              <th scope="col" className="   font-semibold text-primary px-6 py-4 text-left">Delete</th>
            </tr>
          </thead>
          <tbody className='text-[13px] p-4'>
            {companiesList}
          </tbody>
        </table> }
 </div>

    </div>

     
    
    </Layout>
   
  );
}
