import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './paymentVouchersEdit.css';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const PaymentVouchersEdit = () => {
  const [message, setMessage] = useState(null);
  const [paymentVoucher, setPaymentVoucher] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [paymentMode, setPaymentMode] = useState("");

  const [paidAmountOnlineTransfer, setPaidAmountOnlineTransfer] = useState(0);
  const [paidAmountCheque, setPaidAmountCheque] = useState(0);
  const [paidAmountCash, setPaidAmountCash] = useState(0);
  const [bankName, setBankName] = useState("");
  const [iban, setIban] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [chequeType, setChequeType] = useState("");

  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getPaymentVoucherURL = baseURL + "/paymentvouchers/" + params.id;
    const getPaymentVoucherResponse = await fetch(getPaymentVoucherURL, {
      method: "GET",
      headers: headers
    });
    const getPaymentVoucherJSONData = await getPaymentVoucherResponse.json();
    if (!getPaymentVoucherResponse.ok) {
      toast.error(getPaymentVoucherJSONData.data.message);
      setMessage(getPaymentVoucherJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const paymentVoucher = getPaymentVoucherJSONData.data.doc;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    setEmployees(employees);
    setPaymentVoucher(paymentVoucher);
    setPaymentMode(paymentVoucher.paymentMode);
    setPaidAmountOnlineTransfer(paymentVoucher.paidAmountOnlineTransfer);
    setPaidAmountCheque(paymentVoucher.paidAmountCheque);
    setPaidAmountCash(paymentVoucher.paidAmountCash);
    setIban(paymentVoucher.iban);
    setBankName(paymentVoucher.bankName);
    setAccountTitle(paymentVoucher.accountTitle);
    setChequeNumber(paymentVoucher.chequeNumber);
    setChequeType(paymentVoucher.chequeType);
    setIsLoading(false)
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/paymentvouchers/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    console.log(JSON.stringify(payload))
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditPaymentVoucherForm(event) {
    event.preventDefault();
    let errors = [];
    let editPaymentVoucherForm = event.target;
    let payload = {}
    payload.purchaseOrderID = paymentVoucher.purchaseOrderID._id;
    payload.vendorID = paymentVoucher.vendorID._id;
    payload.projectID = paymentVoucher.projectID._id;

    setIsSubmitting(true);

    let paymentVoucherCreator = editPaymentVoucherForm.elements.namedItem("paymentVoucherCreator");
    if (paymentVoucherCreator) {
      if (!paymentVoucherCreator.value) {
        errors.push("Payment Voucher creator is required");
      }
      else {
        payload.paymentVoucherCreator = paymentVoucherCreator.value;
      }
    }

    if (paymentMode === 0) {
      payload.paidAmountOnlineTransfer = paidAmountOnlineTransfer;
      payload.paymentMode = paymentMode;
      payload.iban = iban;
      payload.bankName = bankName;
      payload.accountTitle = accountTitle;
    }
    else if (paymentMode === 1) {
      payload.paidAmountCheque = paidAmountCheque;
      payload.paymentMode = paymentMode;
      payload.chequeNumber = chequeNumber;
      payload.chequeType = chequeType;
    }
    else if (paymentMode === 2) {
      payload.paidAmountCash = paidAmountCash;
      payload.paymentMode = paymentMode;
    }
    else {
      errors.push("Please select a payment mode first.");
    }

    let ceoConsent = editPaymentVoucherForm.elements.namedItem("ceoConsent");
    let ceoComment = editPaymentVoucherForm.elements.namedItem("ceoComment");
    if (ceoConsent) {
      if (ceoConsent.checked) {
        ceoConsent = "accept"
      }
      else {
        ceoConsent = "pending"
      }
      ceoComment = ceoComment.value;
    }

    let mdConsent = editPaymentVoucherForm.elements.namedItem("mdConsent");
    let mdComment = editPaymentVoucherForm.elements.namedItem("mdComment");
    if (mdConsent) {
      if (mdConsent.checked) {
        mdConsent = "accept"
      }
      else {
        mdConsent = "pending"
      }
      mdComment = mdComment.value;
    }

    let cooConsent = editPaymentVoucherForm.elements.namedItem("cooConsent");
    let cooComment = editPaymentVoucherForm.elements.namedItem("cooComment");
    if (cooConsent) {
      if (cooConsent.checked) {
        cooConsent = "accept"
      }
      else {
        cooConsent = "pending"
      }
      cooComment = cooComment.value;
    }

    let fmConsent = editPaymentVoucherForm.elements.namedItem("fmConsent");
    let fmComment = editPaymentVoucherForm.elements.namedItem("fmComment");
    if (fmConsent) {
      if (fmConsent.checked) {
        fmConsent = "accept"
      }
      else {
        fmConsent = "pending"
      }
      fmComment = fmComment.value;
    }

    payload = {
      ...payload,
      ceoConsent: ceoConsent ? ceoConsent : undefined,
      mdConsent: mdConsent ? mdConsent : undefined,
      cooConsent: cooConsent ? cooConsent : undefined,
      fmConsent: fmConsent ? fmConsent : undefined,
      ceoComment: ceoComment ? ceoComment : undefined,
      cooComment: cooComment ? cooComment : undefined,
      mdComment: mdComment ? mdComment : undefined,
      fmComment: fmComment ? fmComment : undefined,
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsDisabled(true);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-bold'>Edit a payment voucher</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];
  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  console.log("Before PaymentVouchersEdit() rendering");

  return (
    <Layout>
      <div className='w-full space-y-2'>

        <div className='flex flex-row justify-between items-center'>
          <h2 className='text-2xl text-primary font-bold'>Edit a payment voucher</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div>
          <Link className="underline" to={"/purchaseorders/edit/" + paymentVoucher.purchaseOrderID._id}>Purchase Order ID: {paymentVoucher.purchaseOrderID.purchaseOrderID}</Link>
        </div>
        <div>Project Name: {paymentVoucher.projectID.projectName}</div>
        <div>Vendor Company Name: {paymentVoucher.vendorID.vendorCompanyName}</div>

        <form id="editPaymentVoucherForm" onSubmit={handleEditPaymentVoucherForm} className='space-y-2'>

          {
            (
              userData.doc.isSuperAdmin ||
              userData.doc.isAdmin
            )
            && (
              <div className="flex flex-col md:flex-row gap-2">
                <div>Payment Voucher Creator:</div>
                <select
                  disabled
                  defaultValue={paymentVoucher.paymentVoucherCreator._id}
                  name="paymentVoucherCreator"
                  className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                >
                  <option value="">Select an employee</option>
                  {employeesList}
                </select>
              </div>
            )
          }

          <div>{`Purchase Order Gross Amount: Rs. ${paymentVoucher.purchaseOrderID.purchaseOrderGrossAmount}`}</div>

          <div className="flex flex-col md:flex-row gap-2">
            <div>Mode of payment:</div>
            <select
              disabled
              name="paymentMode"
              value={paymentMode}
              onChange={(e) => { setPaymentMode(parseInt(e.target.value)) }}
              className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
            >
              <option value="">Select an option</option>
              <option value="0">Online Bank Transfer</option>
              <option value="1">Cheque</option>
              <option value="2">Cash</option>
            </select>
          </div>

          {
            paymentMode === 0 && (
              <div className="flex flex-col md:flex-row md:flex-wrap gap-y-2">
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    Amount (Rs.):
                  </div>
                  <div>
                    <input
                      disabled
                      type="number"
                      name="paidAmount"
                      min="0"
                      value={paidAmountOnlineTransfer || ''}
                      onChange={(e) => {
                        let enteredValue = parseInt(e.target.value)
                        if (isNaN(enteredValue))
                          setPaidAmountOnlineTransfer(0);
                        else
                          setPaidAmountOnlineTransfer(enteredValue);
                      }}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    Bank Name:
                  </div>
                  <div>
                    <input
                      disabled
                      type="text"
                      name="bankName"
                      value={bankName || ''}
                      onChange={(e) => setBankName(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                      placeholder="Bank Alfalah"
                    />
                  </div>
                </div>
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    IBAN:
                  </div>
                  <div>
                    <input
                      disabled
                      type="text"
                      name="iban"
                      value={iban || ''}
                      onChange={(e) => setIban(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                      placeholder="PK08HABB001204"
                    />
                  </div>
                </div>
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    Account Title:
                  </div>
                  <div>
                    <input
                      disabled
                      type="text"
                      name="accountTitle"
                      value={accountTitle || ''}
                      onChange={(e) => setAccountTitle(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>
              </div>
            )
          }

          {
            paymentMode === 1 && (
              <div className="flex flex-col md:flex-row gap-2">

                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Amount (Rs.):
                  </div>
                  <div>
                    <input
                      disabled
                      type="number"
                      name="paidAmount"
                      min="0"
                      value={paidAmountCheque || ""}
                      onChange={(e) => {
                        let enteredValue = parseInt(e.target.value)
                        if (isNaN(enteredValue))
                          setPaidAmountCheque(0);
                        else
                          setPaidAmountCheque(enteredValue);
                      }}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>

                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Cheque Number:
                  </div>
                  <div>
                    <input
                      disabled
                      type="text"
                      name="chequeNumber"
                      value={chequeNumber || ""}
                      onChange={(e) => setChequeNumber(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>

                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Cheque Type:
                  </div>
                  <select
                    disabled
                    name="chequeType"
                    value={chequeType || ""}
                    onChange={(e) => setChequeType(e.target.value)}
                    className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                  >
                    <option value="cash">Cash</option>
                    <option value="cross">Cross</option>
                  </select>
                </div>
              </div>
            )
          }
          {
            paymentMode === 2 && (
              <div className="flex flex-col md:flex-row gap-2">
                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Amount (Rs.):
                  </div>
                  <div>
                    <input
                      disabled
                      type="number"
                      name="paidAmount"
                      min="0"
                      value={paidAmountCash || ""}
                      onChange={(e) => {
                        let enteredValue = parseInt(e.target.value)
                        if (isNaN(enteredValue))
                          setPaidAmountCash(0);
                        else
                          setPaidAmountCash(enteredValue);
                      }}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>
              </div>
            )
          }

          <div className='flex flex-col gap-y-3 text-sm text-gray-900 font-normal text-left'>
            <div className='w-full p-2 border border-[#8E8EA1]'>
              CEO says: <div className='p-2'>{paymentVoucher.ceoComment ? paymentVoucher.ceoComment : <i>No comments yet</i>}</div>
            </div>
            <div className='w-full p-2 border border-[#8E8EA1]'>
              MD says: <div className='p-2'>{paymentVoucher.mdComment ? paymentVoucher.mdComment : <i>No comments yet</i>}</div>
            </div>
            <div className='w-full p-2 border border-[#8E8EA1]'>
              COO says: <div className='p-2'>{paymentVoucher.cooComment ? paymentVoucher.cooComment : <i>No comments yet</i>}</div>
            </div>
            <div className='w-full p-2 border border-[#8E8EA1]'>
              Finance Manager says: <div className='p-2'>{paymentVoucher.fmComment ? paymentVoucher.fmComment : <i>No comments yet</i>}</div>
            </div>
          </div>

          {
            (
              userData.doc.isSuperAdmin ||
              (userData.doc.isAdmin && userData.doc.managementRank === "ceo")
            ) && (
              <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                <div className='w-full'>
                  Chief Executive Officer:
                </div>
                <div className='w-full'>
                  <input type="checkbox" name="ceoConsent" value="accept" defaultChecked={paymentVoucher.ceoConsent === "accept"} className='me-1' />
                  <span>Accept</span>
                </div>
                <div className='w-full'>
                  <div className='mb-2'>Comments: </div>
                  <textarea name="ceoComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={paymentVoucher.ceoComment} placeholder='Please enter your comments here.'></textarea>
                </div>
              </div>
            )
          }

          {
            (
              userData.doc.isSuperAdmin ||
              (userData.doc.isAdmin && userData.doc.managementRank === "md")
            ) && (
              <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                <div className='w-full'>
                  Managing Director:
                </div>
                <div className='w-full'>
                  <input type="checkbox" name="mdConsent" value="accept" defaultChecked={paymentVoucher.mdConsent === "accept"} className='me-1' />
                  <span>Accept</span>
                </div>
                <div className='w-full'>
                  <div className='mb-2'>Comments: </div>
                  <textarea name="mdComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={paymentVoucher.mdComment} placeholder='Please enter your comments here.'></textarea>
                </div>
              </div>
            )
          }

          {
            (
              userData.doc.isSuperAdmin ||
              (userData.doc.isAdmin && userData.doc.managementRank === "coo")
            ) && (
              <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                <div className='w-full'>
                  Chief Operations Officer:
                </div>
                <div className='w-full'>
                  <input type="checkbox" name="cooConsent" value="accept" defaultChecked={paymentVoucher.cooConsent === "accept"} className='me-1' />
                  <span>Accept</span>
                </div>
                <div className='w-full'>
                  <div className='mb-2'>Comments: </div>
                  <textarea name="cooComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={paymentVoucher.cooComment} placeholder='Please enter your comments here.'></textarea>
                </div>
              </div>
            )
          }

          {
            (
              userData.doc.isSuperAdmin ||
              userData.role.create.includes("paymentvouchers")
            ) && (
              <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                <div className='w-full'>
                  Finance Manager:
                </div>
                <div className='w-full'>
                  <input type="checkbox" name="fmConsent" value="accept" defaultChecked={paymentVoucher.fmConsent === "accept"} className='me-1' />
                  <span>Accept</span>
                </div>
                <div className='w-full'>
                  <div className='mb-2'>Comments: </div>
                  <textarea name="fmComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={paymentVoucher.fmComment} placeholder='Please enter your comments here.'></textarea>
                </div>
              </div>
            )
          }

          <div className='flex flex-row'>
            <button
              disabled={isSubmitting || isDisabled}
              type="submit"
              className='text-white bg-primary rounded-[40px] w-[299px] h-9 mt-6'
            >
              {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
            </button>
          </div>

        </form>
      </div>
    </Layout>
  );
}
