import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './projectsView.css';
import { Link, useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';

export const ProjectsView = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [projectOverview, setProjectOverview] = useState(null);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let getProjectOverviewURL = baseURL + "/projects/overview/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getProjectOverviewResponse = await fetch(getProjectOverviewURL, {
      method: "GET",
      headers: headers
    });
    const getProjectOverviewJSONData = await getProjectOverviewResponse.json();
    if (!getProjectOverviewResponse.ok) {
      toast.error(getProjectOverviewJSONData.data.message);
      setMessage(getProjectOverviewJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const projectOverview = getProjectOverviewJSONData.data.doc;

    setProjectOverview(projectOverview);
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);


  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-semibold'>Project Overview</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let siteEngineers = "";
  let staffMembers = "";

  if (projectOverview.project?.siteEngineers && projectOverview.project.siteEngineers.length > 0) {
    let siteEngineersNames = projectOverview.project.siteEngineers.map((engineer, index) => {
      return engineer.full_name;
    });
    siteEngineers = siteEngineersNames.join(", ");
  }

  if (projectOverview.project?.staffMembers && projectOverview.project.staffMembers.length > 0) {
    let staffMembersNames = projectOverview.project.staffMembers.map((member, index) => {
      return member.full_name;
    });
    staffMembers = staffMembersNames.join(", ");
  }

  console.log("Before ProjectsView() rendering");

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-semibold'>Project Overview</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div id="projectOverview" className='overflow-x-auto rounded-lg border border-[#6843BE] m-4 md:m-0 p-4 text-sm space-y-5'>

          <div className='flex flex-col md:flex-row md:flex-wrap gap-y-2'>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Project ID:</span> {projectOverview.project.projectID}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Project Name:</span> {projectOverview.project.projectName}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Project Category:</span> {projectOverview.project.projectCategory.projectCategoryName}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Project Owner Company:</span> {projectOverview.project.projectOwnerCompany?.name}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Project Owner Employee:</span> {projectOverview.project.projectOwnerEmployee?.full_name}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Project Manager:</span> {projectOverview.project.projectManager.full_name}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Site Engineers:</span> {" "}
                {
                  siteEngineers
                }
              </div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Staff Members:</span> {" "}
                {
                  staffMembers
                }
              </div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Covered Area:</span> {projectOverview.project.coveredArea}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Plot Area:</span> {projectOverview.project.plotArea}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>No. of Units:</span> {projectOverview.project.numberOfUnits}</div>
            </div>

          </div>

          <div className='flex flex-col md:flex-row md:flex-wrap gap-y-2'>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <span className='font-semibold'>Soil Report:</span> {" "}
              {projectOverview.project.projectSoilReport ? (
                <a
                  className="underline"
                  href={`${staticFilesURL}/projects/${projectOverview.project.projectSoilReport.fileNameOnServerDisk}`}
                >
                  Soil Report
                </a>
              ) : (
                "No soil report found!"
              )
              }
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <span className='font-semibold'>BOQ:</span> {" "}
              {projectOverview.project.projectBOQDocument ?
                <a
                  className="underline"
                  href={`${staticFilesURL}/projects/${projectOverview.project.projectBOQDocument.fileNameOnServerDisk}`}
                >
                  {projectOverview.project.projectBOQDocument.fileNameOnServerDisk}
                </a>
                : "No BOQ document found!"
              }
            </div>

          </div>

          <div className='flex flex-col gap-y-5'>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Demand ID</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      projectOverview.demands && projectOverview.demands.length > 0 ? (
                        projectOverview.demands.map((demand) => {
                          let editLink = '/demands/edit/' + demand._id;
                          let demandAcceptanceStatus = false;
                          if (
                            demand.cooConsent === "accept" &&
                            demand.mdConsent === "accept" &&
                            demand.projectManagerConsent === "accept"
                          ) {
                            demandAcceptanceStatus = true;
                          }
                          return <tr key={demand._id}>
                            <td>
                              {demand.demandID}
                            </td>
                            <td>
                              {demandAcceptanceStatus ? "Accepted" : "Not yet approved or rejected"}
                            </td>
                            <td>
                              {demand.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No demands found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Quotation ID</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      projectOverview.quotations && projectOverview.quotations.length > 0 ? (
                        projectOverview.quotations.map((quotation) => {
                          let editLink = '/quotations/edit/' + quotation._id;
                          return <tr key={quotation._id}>
                            <td>
                              {quotation.quotationID}
                            </td>
                            <td>
                              {
                                quotation.selectedForPurchaseOrder ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {quotation.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No quotations found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Purchase Order ID</th>
                      <th>Gross Amount</th>
                      <th>Vendor</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      projectOverview.purchaseOrders && projectOverview.purchaseOrders.length > 0 ? (
                        projectOverview.purchaseOrders.map((purchaseOrder) => {
                          let editLink = '/purchaseorders/edit/' + purchaseOrder._id;
                          return <tr key={purchaseOrder._id}>
                            <td>
                              {purchaseOrder.purchaseOrderID}
                            </td>
                            <td>
                              {purchaseOrder.purchaseOrderGrossAmount}
                            </td>
                            <td>
                              {purchaseOrder.vendorID.vendorCompanyName}
                            </td>
                            <td>
                              {
                                purchaseOrder.selectedForPaymentVoucher ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {purchaseOrder.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No purchase orders found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Payment Voucher ID</th>
                      <th>Payment Mode</th>
                      <th>Paid Amount</th>
                      <th>Vendor</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      projectOverview.paymentVouchers && projectOverview.paymentVouchers.length > 0 ? (
                        projectOverview.paymentVouchers.map((paymentVoucher) => {
                          let paymentMode;
                          let paidAmount;
                          if (paymentVoucher.paymentMode === 0) {
                            paymentMode = "Online Bank Transfer";
                            paidAmount = paymentVoucher.paidAmountOnlineTransfer;
                          }
                          else if (paymentVoucher.paymentMode === 1) {
                            paymentMode = "Cheque";
                            paidAmount = paymentVoucher.paidAmountCheque;
                          }
                          else if (paymentVoucher.paymentMode === 2) {
                            paymentMode = "Cash";
                            paidAmount = paymentVoucher.paidAmountCash;
                          }
                          let editLink = '/paymentvouchers/edit/' + paymentVoucher._id;
                          return <tr key={paymentVoucher._id}>
                            <td>
                              {paymentVoucher.paymentVoucherID}
                            </td>
                            <td>
                              {paymentMode}
                            </td>
                            <td>
                              {paidAmount}
                            </td>
                            <td>
                              {paymentVoucher.vendorID.vendorCompanyName}
                            </td>
                            <td>
                              {
                                paymentVoucher.selectedForMaterialReceiptSlip ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {paymentVoucher.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No payment vouchers found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Material Receipt Slip ID</th>
                      <th>Vendor</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      projectOverview.materialReceiptSlips && projectOverview.materialReceiptSlips.length > 0 ? (
                        projectOverview.materialReceiptSlips.map((materialReceiptSlip) => {
                          let editLink = '/materialreceiptslips/edit/' + materialReceiptSlip._id;
                          return <tr key={materialReceiptSlip._id}>
                            <td>
                              {materialReceiptSlip.materialReceiptSlipID}
                            </td>
                            <td>
                              {materialReceiptSlip.vendorID.vendorCompanyName}
                            </td>
                            <td>
                              {
                                materialReceiptSlip.selectedForFuture ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {materialReceiptSlip.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No material receipt slips found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Material Issue Slip ID</th>
                      <th>Material</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Issue Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      projectOverview.materialIssueSlips && projectOverview.materialIssueSlips.length > 0 ? (
                        projectOverview.materialIssueSlips.map((materialIssueSlip) => {
                          return <tr key={materialIssueSlip._id}>
                            <td>
                              {materialIssueSlip.materialIssueID}
                            </td>
                            <td>
                              {materialIssueSlip.stockItemObjectId.stockItemName}
                            </td>
                            <td>
                              {materialIssueSlip.materialIssueQuantity}
                            </td>
                            <td>
                              {materialIssueSlip.stockItemObjectId.stockItemUnitOfMeasure}
                            </td>
                            <td>
                              {materialIssueSlip.createdAt.split('T')[0]}
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No material issue slips found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>

          <div className='flex flex-col md:flex-row md:flex-wrap items-center  gap-y-6'>
            {
              projectOverview.project.projectPictures.length > 0 &&
              projectOverview.project.projectPictures.map((picture, index) => {
                return (
                  <div className='md:basis-1/2'>
                    <img
                      className='w-11/12 mx-auto'
                      src={`${staticFilesURL}/projects/${picture}`}
                      alt="photograph of project"
                    />
                  </div>
                )
              })
            }
          </div>

        </div>
      </div>
    </Layout>
  );
}
