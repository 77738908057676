import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import styles from './companiesEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';

export const CompaniesEdit = () => {
  const [message, setMessage] = useState(null);
  const [company, setCompany] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let getCompanyURL = baseURL + "/companies/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getCompanyResponse = await fetch(getCompanyURL, {
      method: "GET",
      headers: headers
    });
    const getCompanyJSONData = await getCompanyResponse.json();
    if (!getCompanyResponse.ok) {
      toast.error(getCompanyJSONData.data.message);
      setMessage(getCompanyJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const company = getCompanyJSONData.data.doc;

    setCompany(company);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/companies/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
    }
  }

  async function handleEditCompanyForm(event) {
    event.preventDefault();
    let editCompanyForm = event.target;
    let payload = {}; //empty object
    let companyName = editCompanyForm.elements.namedItem("company_name").value;
    payload.company_name = companyName;
    sendAPIRequest(payload);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit a company</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          { isLoading && <Loader /> }
        </div>
      </Layout>
    );
  }

  console.log("Before CompaniesEdit() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit a company</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='flex flex-col  py-3 px-4 border border-[#2F80ED] rounded-lg'>
          {isLoading ? <Loader /> : <form id="editCompanyForm" onSubmit={handleEditCompanyForm}>
            <div className='flex flex-col space-y-3 w-full md:w-2/5'>
              <label className='text-[#8E8EA1] text-base font-normal text-left'>Company Name</label>
              <input type="text" name="company_name" placeholder='Company Name' className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' defaultValue={company.name} />
            </div>
            <div className='flex flex-row justify-start my-5'>
              <input type="submit" name="submit" value="Submit" className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9 cursor-pointer' />
            </div>
          </form>
          }
        </div>
      </div>
    </Layout>
  )
}
