import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './employeesTasksEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { BsCalendar3 } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { TaskTabEdit } from 'components/taskTabEdit/TaskTabEdit.jsx';
import { TaskTabSubtasks } from 'components/taskTabSubtasks/TaskTabSubtasks.jsx';
import { TaskTabAttachments } from 'components/taskTabAttachments/TaskTabAttachments.jsx';
import { TaskTabDiscussion } from 'components/taskTabDiscussion/TaskTabDiscussion.jsx';


export const EmployeesTasksEdit = () => {
  const [message, setMessage] = useState(null);
  const [task, setTask] = useState(null);
  const [activeTab, setActiveTab] = useState("overview");
  const [employees, setEmployees] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const getTaskURL = baseURL + "/employeestasks/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getTaskResponse = await fetch(getTaskURL, {
      method: "GET",
      headers: headers
    });
    const getTaskJSONData = await getTaskResponse.json();
    if (!getTaskResponse.ok) {
      toast.error(getTaskJSONData.data.message);
      setMessage(getTaskJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const task = getTaskJSONData.data.doc;

    let getEmployeesURL = baseURL + "/employees/"
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    console.log(task);

    //Deleting _id

    for (let i = 0; i < task.subtasks.length; i++) {
      delete task.subtasks[i]._id;
    }

    setTask(task);
    setEmployees(employees);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit Employee Task</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before EmployeesTasksEdit() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5 text-gray-900 text-sm'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit Employee Task</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-primary p-8 space-y-3'>

          <div className='flex flex-row gap-2'>
            <div>
              <button
                onClick={(e) => setActiveTab("overview")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Overview
              </button>
            </div>

            <div>
              <button
                onClick={(e) => setActiveTab("edit")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Edit
              </button>
            </div>

            <div>
              <button
                onClick={(e) => setActiveTab("subtasks")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Subtasks
              </button>
            </div>

            <div>
              <button
                onClick={(e) => setActiveTab("discussion")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Discussion
              </button>
            </div>

            <div>
              <button
                onClick={(e) => setActiveTab("attachments")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Files
              </button>
            </div>
          </div>

          {
            activeTab === "overview" && (
              <div>
                <div className="flex flex-col max-w-screen-sm">

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Title</div>
                    <div className="w-1/2">{task.taskTitle}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Start Date</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsCalendar3 />{task.taskStartByDate.split('T')[0]}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Approx. Finish Date</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsCalendar3 />{task.taskFinishByDate.split('T')[0]}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Actual Finish Date</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsCalendar3 />{task.taskActualFinishDate?.split('T')[0]}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Team</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsPeopleFill />
                      {
                        task.employeeIDs.map(
                          (employee) => {
                            return employee.full_name;
                          }
                        ).join(', ')
                      }
                    </div>
                  </div>

                  <div className="flex flex-row py-2 my-2">
                    <div>Description</div>
                    <div>{ }</div>
                  </div>

                </div>
              </div>
            )
          }

          {
            activeTab === "edit" && (
              <TaskTabEdit task={task} setTask={setTask} initialEmployees={employees} params={params} />
            )
          }

          {
            activeTab === "subtasks" && (
              <TaskTabSubtasks initialSubtasks={task.subtasks} setTask={setTask} initialEmployees={employees} params={params} setMessage={setMessage} />
            )
          }

          {
            activeTab === "discussion" && (
              <TaskTabDiscussion comments={task.comments} setTask={setTask} initialEmployees={employees} params={params} />
            )
          }

          {
            activeTab === "attachments" && (
              <TaskTabAttachments initialAttachments={task.attachments} setTask={setTask} initialEmployees={employees} params={params} />
            )
          }

        </div>

      </div >

    </Layout >
  );
}
