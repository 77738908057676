import React from 'react';
import { baseURL } from 'config/api.js';
import { useState } from 'react';
//import styles from './companiesNew.module.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';

export const CompaniesNew = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    setIsLoading(true);
    let url = baseURL + "/companies/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    setIsLoading(false);
    if (jsonData.success) {
      setMessage(jsonData.data.message);
    }
    else {
      setMessage(jsonData.data.message);
    }
  }

  async function handleCreateCompanyForm(event) {
    event.preventDefault();
    let createCompanyForm = event.target;
    let payload = {}; //empty object
    let companyName = createCompanyForm.elements.namedItem("company_name").value;
    payload.company_name = companyName;
    sendAPIRequest(payload);
  }

  console.log("Before CompaniesNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create a new company</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='flex flex-col  py-3 px-4 border border-[#2F80ED] rounded-lg'>
          {isLoading ? <Loader /> : <form id="createCompanyForm" onSubmit={handleCreateCompanyForm}>
            <div className='flex flex-col space-y-3 w-full md:w-2/5'>
              <label className='text-[#8E8EA1] text-base font-normal text-left'>Company Name</label>
              <input type="text" name="company_name" placeholder='Company Name' className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' />
            </div>
            <div className='flex flex-row justify-start my-5'>
              <input type="submit" name="submit" value="Submit" className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9' />
            </div>
          </form>
          }
        </div>
      </div>
    </Layout>
  )
}
