import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './tasksOfEmployee.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { CiEdit } from 'react-icons/ci';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';

export const TasksOfEmployee = () => {
  const [message, setMessage] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [totalTasksCount, setTotalTasksCount] = useState(null);
  const [pendingTasksCount, setPendingTasksCount] = useState(null);
  const [finishedTasksCount, setFinishedTasksCount] = useState(null);
  const [percentTasksFinished, setPercentTasksFinished] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let url = baseURL + "/employeestasks/of-employee/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const tasks = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;

    //Fetching employee so we can know his full_name
    let url2 = baseURL + "/employees/" + params.id;
    const response2 = await fetch(url2, {
      method: "GET",
      headers: headers
    });
    const jsonData2 = await response2.json();
    if (!response2.ok) {
      toast.error(jsonData2.data.message);
      setMessage(jsonData2.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employee = jsonData2.data.doc;

    setTasks(tasks);
    setEmployee(employee);
    setTotalTasksCount(docsCount);
    setPendingTasksCount(jsonData.data.pendingTasksCount);
    setFinishedTasksCount(jsonData.data.finishedTasksCount);
    setPercentTasksFinished(jsonData.data.percentTasksFinished);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>Tasks of Employee</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          <Loader />
        </div>
      </Layout>
    );
  }

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>Tasks of Employee</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          { isLoading && <Loader /> }
        </div>
      </Layout>
    );
  }

  let tasksList = [];

  if (tasks && tasks.length !== 0) {
    tasksList = tasks.map((task, index) => {
      const assignees = []
      for (const employee of task.employeeIDs) {
        assignees.push(employee.full_name);
      }
      const displayAssignees = assignees.join(', ');
      let editLink = "/employeesTasks/edit/" + task._id;
      let numberOfSubtasks = task.subtasks.length;
      let completionPercentage;
      if (numberOfSubtasks > 0) {
        let completeSubtasks = 0;
        for (let i = 0; i < numberOfSubtasks; i++) {
          const subtask = task.subtasks[i];
          if (subtask.subtaskStatus === 1) {
            completeSubtasks++;
          }
        }
        completionPercentage = ((completeSubtasks / numberOfSubtasks) * 100);
        if (isNaN(completionPercentage)) {
          completionPercentage = 0;
        }
      }
      else {
        completionPercentage = "No subtasks!";
      }

      let taskStatus, taskPriority;

      if (task.taskStatus === 0)
        taskStatus = "Pending";
      else if (task.taskStatus === 1)
        taskStatus = "Finished";

      if (task.taskPriority === 0)
        taskPriority = "Low";
      else if (task.taskPriority === 1)
        taskPriority = "Normal";
      else if (task.taskPriority === 2)
        taskPriority = "High";
      else if (task.taskPriority === 3)
        taskPriority = "Urgent";

      return (
        <tr key={index}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{task.taskTitle}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{task.supervisor.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{displayAssignees}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{taskStatus}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{completionPercentage}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{taskPriority}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{task.taskStartByDate?.split('T')[0]}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{task.taskFinishByDate?.split('T')[0]}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{task.taskActualFinishDate?.split('T')[0]}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <button data-id={employee._id} onClick={handleDelete}  >
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }
  else if (tasks && tasks.length === 0) {    
    tasksList =
      <tr>
        <td className="text-gray-900 px-6 py-4 whitespace-nowrap">No tasks assigned to this employee yet!</td>
      </tr>
  }

  console.log("Before TasksOfEmployee() rendering");

  return (
    <Layout>
      <div className="space-y-5">
        <div className='flex flex-row justify-between items-center px-3 my-2'>
          <h2 className='text-2xl text-primary font-bold'>Tasks of Employee</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='border border-primary p-8 rounded-[10px]'>
          <div className='space-y-2'>
            <div>Name: {employee.full_name}</div>
          </div>
          <div className='mt-2 space-y-2'>
            <div>Pending Tasks: {pendingTasksCount}</div>
            <div>Finished Tasks: {finishedTasksCount}</div>
            <div>Finished Percentage: {percentTasksFinished}%</div>
            <div>Total Tasks: {totalTasksCount}</div>
          </div>
            <div className='overflow-x-auto rounded-lg border border-[#6843BE] mt-8'>
              <table className="w-full table-auto overflow-x-auto">
                <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
                  <tr>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Task Title</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Supervisor</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Assigned to Employees</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Task Status</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Finished (%)</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Task Priority</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Task Start Date</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Task Approx. Finish Date</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Task Actual Finish Date</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Edit</th>
                    <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Delete</th>
                  </tr>
                </thead>
                <tbody className='text-[13px] p-4'>
                  {tasksList}
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </Layout>
  );
}
