import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import './materialIssues.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { FaPlus } from 'react-icons/fa6';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import Pagination from 'components/global/pagination';

export const MaterialIssues = () => {
  const [message, setMessage] = useState(null);
  const [materialIssues, setMaterialIssues] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  async function fetchData() {
    let url = baseURL + "/materialissues/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const materialIssues = jsonData.data.docs;
    setMaterialIssues(materialIssues);
    setIsLoading(false)

  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);


  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-4'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Material Issue Slips</h2>
            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.create.includes("materialissues")
              ) &&
              <Link to="/materialissues/new">
                <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
                  <span><FaPlus /></span>
                  <span>New Material Issue Slip</span>
                </button>
              </Link>
            }
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let materialIssuesList = [];

  if (materialIssues && materialIssues.length !== 0) {
    materialIssuesList = materialIssues.map((materialIssue, index) => {
      return (
        <tr key={materialIssue._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{materialIssue.materialIssueID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/projects/${materialIssue.projectObjectId._id}`}>
              {materialIssue.projectObjectId.projectName}
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{materialIssue.stockItemObjectId?.stockItemSKU}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{materialIssue.stockItemObjectId?.stockItemName}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{materialIssue.materialIssueDate.split('T')[0]}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{materialIssue.materialIssueQuantity}</td>
        </tr>
      );
    });
  }

  console.log("Before MaterialIssues() rendering");

  return (
    <Layout>
      <div className="space-y-4">
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Material Issue Slips</h2>
          {
            (
              userData.doc.isSuperAdmin ||
              userData.doc.isAdmin ||
              userData.role.create.includes("materialissues")
            ) && (
              <Link to="/materialissues/new">
                <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
                  <span><FaPlus /></span>   <span>New Material Issue Slip</span>
                </button>
              </Link>
            )
          }
        </div>
        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto ">
            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Stock Item SKU</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Stock Item Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Issue Date</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Quantity Issued</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {materialIssuesList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='12' >
                  <Pagination
                    currentPage={1}
                    pageCount={1}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>

  );
}
