import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import './paymentVouchersFind.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import Pagination from 'components/global/pagination';

export const PaymentVouchersFind = () => {
  const [message, setMessage] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [paymentVouchers, setPaymentVouchers] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const params = useParams();

  async function fetchData() {
    let url = baseURL + "/paymentvouchers/of-purchaseorder/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const paymentVouchers = jsonData.data.docs;

    let getPurchaseOrderURL = baseURL + "/purchaseorders/" + params.id;
    const getPurchaseOrderResponse = await fetch(getPurchaseOrderURL, {
      method: "GET",
      headers: headers
    });
    const getPurchaseOrderJSONData = await getPurchaseOrderResponse.json();
    if (!getPurchaseOrderResponse.ok) {
      toast.error(getPurchaseOrderJSONData.data.message);
      setMessage(getPurchaseOrderJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const purchaseOrder = getPurchaseOrderJSONData.data.doc;

    setPaymentVouchers(paymentVouchers);
    setPurchaseOrder(purchaseOrder);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-bold'>Payment Vouchers of Purchase Order</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let paymentVouchersList = [];
  let paymentVouchersSum = 0;

  if (paymentVouchers && paymentVouchers.length !== 0) { //on second render
    paymentVouchersList = paymentVouchers.map((paymentVoucher, index) => {
      let paymentMode;
      let amountPaid
      if (paymentVoucher.paymentMode === 0) {
        paymentMode = "Online Bank Transfer";
        paymentVouchersSum = paymentVouchersSum + paymentVoucher.paidAmountOnlineTransfer;
        amountPaid = paymentVoucher.paidAmountOnlineTransfer;
      }
      else if (paymentVoucher.paymentMode === 1) {
        paymentMode = "Cheque";
        paymentVouchersSum = paymentVouchersSum + paymentVoucher.paidAmountCheque;
        amountPaid = paymentVoucher.paidAmountCheque;
      }
      else if (paymentVoucher.paymentMode === 2) {
        paymentMode = "Cash";
        paymentVouchersSum = paymentVouchersSum + paymentVoucher.paidAmountCash;
        amountPaid = paymentVoucher.paidAmountCash;
      }
      return (
        <tr key={paymentVoucher._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{paymentVoucher.paymentVoucherID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{paymentVoucher.vendorID.vendorCompanyName}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{paymentVoucher.paymentVoucherCreator.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{paymentVoucher.projectID.projectName}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{paymentMode}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{amountPaid}</td>
        </tr>
      );
    });
  }

  console.log("Before PaymentVouchersFind() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Payment Vouchers of Purchase Order</h2>
        </div>

        <div className='flex flex-row justify-between'>
          {message && message}
        </div>
        <div className='overflow-x-auto rounded-lg border border-[#6843BE] text-sm'>
          <table className="w-full table-auto overflow-x-auto">
            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Payment Voucher ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Vendor Company Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Payment Voucher Creator</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Payment mode</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Amount paid</th>
              </tr>
            </thead>
            <tbody>
              {paymentVouchersList}
            </tbody>
          </table>
        </div>

        <div className='flex flex-col gap-2 text-sm'>
          <div>
            Gross Total: {purchaseOrder.purchaseOrderGrossAmount}
          </div>
          <div>
            Payment Vouchers Sum: {paymentVouchersSum}
          </div>
          <div>
            Total Remaining: {purchaseOrder.purchaseOrderGrossAmount - paymentVouchersSum}
          </div>
        </div>
      </div>
    </Layout>
  );
}
