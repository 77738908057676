import React from 'react';
import { baseURL } from 'config/api.js';
import Layout from 'components/global/Layout';

export const Dashboard = () => {

  console.log("Before Dashboard() rendering");

  return (
    <Layout>
      <div>
        <p>I'm dashboard</p>
      </div>
    </Layout>
  );
}
