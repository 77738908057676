import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './employeesLeavesEdit.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { useParams } from "react-router-dom";
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';

export const EmployeesLeavesEdit = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const params = useParams();

  const [employee, setEmployee] = useState("");
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getLeaveURL = baseURL + "/employeesleaves/" + params.id;
    const getLeaveResponse = await fetch(getLeaveURL, {
      method: "GET",
      headers: headers
    });
    const getLeaveJSONData = await getLeaveResponse.json();
    if (!getLeaveResponse.ok) {
      toast.error(getLeaveJSONData.data.message);
      setMessage(getLeaveJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const leave = getLeaveJSONData.data.doc;

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setEmployee(leave.employeeID._id);
    setTitle(leave.title);
    setStartDate(leave.startDate.split('T')[0]);
    setEndDate(leave.endDate.split('T')[0]);
    setStatus(leave.status);
    setDescription(leave.description ? leave.description : "");
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeesleaves/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditEmployeeLeaveForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!title) {
      errors.push(<div>Leave title cannot be empty</div>);
    }

    if (!employee) {
      errors.push(<div>Please select an employee</div>);
    }

    if (!startDate) {
      errors.push(<div>Leave start date is required</div>);
    }

    if (!endDate) {
      errors.push(<div>Leave end date is required</div>);
    }

    if (!status) {
      errors.push(<div>Leave status is required</div>);
    }

    let payload = {
      employeeID: employee,
      title,
      startDate,
      endDate,
      status,
      description: description ? description : undefined
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit leave application</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before EmployeesLeavesEdit() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit leave application</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form id="editEmployeeLeaveForm" onSubmit={handleEditEmployeeLeaveForm}>

          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Title *</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Employee Name *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="employee"
                    defaultValue={employee}
                    options={
                      employees && employees.length !== 0 && employees.map((employee, index) => {
                        return {
                          value: employee._id,
                          label: employee.full_name
                        }
                      })
                    }
                    handleChange={(selectedOption) => setEmployee(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Start Date *</label>
                </div>
                <div>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>End Date *</label>
                </div>
                <div>
                  <input
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Status *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="status"
                    defaultValue={status}
                    options={
                      [
                        { value: 'Pending', label: 'Pending' },
                        { value: 'Granted', label: 'Granted' },
                        { value: 'Declined', label: 'Declined' }
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setStatus(selectedOption.value)}
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col gap-y-3'>
              <div>
                <label className='font-normal'>Description</label>
              </div>
              <div>
                <textarea
                  rows="4"
                  cols="50"
                  name="description"
                  value={description}
                  onChange={(e) => {
                    e.target.value ? setDescription(e.target.value) : setDescription('');
                  }}
                  className='border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
                >
                </textarea>
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting || isDisabled} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div >
    </Layout >
  );
}