import { Link } from "react-router-dom"

const Footer = () => {
    console.log("Before Footer() rendering");
    return (
        <div className="fixed bottom-0 bg-white shadow   w-full h-12  duration-300 z-10">

        <div className='flex items-center justify-between sm:px-8 px-2 py-4 sm:text-sm text-xs text-grayText'>
            <div className="text-center">
            Designed and Developed by GhairMulki Software House Pvt. Ltd.
            </div>
        </div>
        </div>
    )
}

export default Footer