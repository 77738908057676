import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { DateTime } from "luxon";
import { useSearchParams } from 'react-router-dom';
//import './employeesAttendances.css'
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';

export const EmployeesAttendances = () => {
  const [message, setMessage] = useState(null);

  const [attendances, setAttendances] = useState(null);
  const [attendancesTotalCount, setAttendancesTotalCount] = useState(null);

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingError, setIsLoadingError] = useState(false);
  
  const { userData } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/employeesattendances?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const attendances = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;

    setAttendances(attendances);
    setAttendancesTotalCount(docsCount);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>Employees Attendances</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let attendancesList = [];

  if (attendances && attendances.length !== 0) {
    attendancesList = attendances.map((att, index) => {
      let co = DateTime.fromISO(att.checkOutTime);
      return (
        <tr key={att._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{att.employeeAttendanceID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{att.employeeID.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{att.status}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{DateTime.fromISO(att.checkInTime).toLocaleString(DateTime.DATETIME_FULL)}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{co.isValid ? co.toLocaleString(DateTime.DATETIME_FULL) : ""}</td>
        </tr>
      );
    });
  }
  else if (attendances && attendances.length === 0) {
    attendancesList =
      <tr>
        <td className="text-gray-900 px-6 py-4 whitespace-nowrap">No attendance found!</td>
      </tr>
  }

  console.log("Before EmployeesAttendance() rendering");

  return (
    <Layout>
      <div className="space-y-5">

        <div className='flex flex-row justify-between items-center px-3 my-2'>
          <h2 className='text-2xl text-primary font-bold'>Employees Attendances</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE] mt-8'>

          <table className="w-full table-auto overflow-x-auto">

            <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Full Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Status</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Check In Time</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Check Out Time</th>
              </tr>
            </thead>

            <tbody className='text-[13px] p-4'>
              {attendancesList}
            </tbody>

            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(attendancesTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </Layout >
  );
}
