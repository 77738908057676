import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { Calendar, luxonLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DateTime } from 'luxon';
import { baseURL } from 'config/api.js';
//import './calendar.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { BsClock } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';
import Modal from 'react-modal';
import { EventsEditModal } from 'components/eventsEditModal/EventsEditModal.jsx';
import { BsPencilSquare } from "react-icons/bs";
import { BsTrash3 } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { EventsNewModal } from 'components/eventsNewModal/EventsNewModal.jsx';

const defaultDateStr = new Date().toISOString().split('T')[0]

function getDate(str, DateTimeObj) {
  return DateTimeObj.fromISO(str).toJSDate()
}

export const CompanyCalendar = () => {
  const [message, setMessage] = useState(null);
  const [events, setEvents] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTab, setModalTab] = useState("details");
  const [slot, setSlot] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/events?&skip=no`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const events = jsonData.data.docs;

    setEvents(events);
    setIsLoading(false);
  }

  const { defaultDate, getNow, localizer, myEvents, scrollToTime } =
    useMemo(() => {
      return {
        defaultDate: getDate(defaultDateStr, DateTime),
        getNow: () => DateTime.local().toJSDate(),
        localizer: luxonLocalizer(DateTime),
        myEvents: events.map((event) => {
          let startDate = new Date(event.startDate);
          let endDate = new Date(event.endDate);
          return {
            id: event.eventID,
            allDay: event.allDay,
            title: event.title,
            start: startDate,
            end: endDate,
          }
        }),
        scrollToTime: DateTime.local().toJSDate(),
      }
    }, [events]);

  const handleSelectSlot = useCallback(
    (slotInfo) => {
      setSlot(slotInfo);
      setModalTab("new");
      openModal();
    },
    [setSlot, setModalTab]
  )

  const handleSelectedEvent = (event) => {
    let eventStoredInDB = events.find((element) => element.eventID === event.id);
    setSelectedEvent(eventStoredInDB);
    openModal();
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setModalTab("details");
    setIsOpen(false);
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>Calendar</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before Calendar() rendering");

  console.log(myEvents);

  return (
    <Layout>
      <div className="space-y-5">

        <div className='flex flex-row justify-between items-center px-3 my-2'>
          <h2 className='text-2xl text-primary font-bold'>Calendar</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='w-full' style={{ "height": "900px" }}>
          <Calendar
            defaultDate={defaultDate}
            defaultView={Views.MONTH}
            onSelectEvent={(event) => handleSelectedEvent(event)}
            events={myEvents}
            getNow={getNow}
            localizer={localizer}
            scrollToTime={scrollToTime}
            onSelectSlot={handleSelectSlot}
            selectable
          />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.65)',
              zIndex: 100,
            },
            content: {
              left: '20%',
              right: '20%',
              bottom: 'auto',
              borderRadius: '10px'
            }
          }}
        >
          {
            modalTab === "details" ? (
              <div className='text-sm'>
                <div className="flex flex-row justify-between mb-6">
                  <div>Event Details</div>
                  <button onClick={closeModal}>X</button>
                </div>
                <div className="flex flex-col gap-y-3">
                  <div className='text-lg font-bold'>
                    {selectedEvent?.title}
                  </div>
                  <div className='flex flex-row gap-x-2 items-center text-[11px]'>
                    <BsClock />
                    {new Date(selectedEvent?.startDate).toString().split('(')[0]} - {new Date(selectedEvent?.endDate).toString().split('(')[0]}
                  </div>
                  <div className='bg-[#eee] border-s-4 pl-2 border-s-fuchsia-900'>
                    {selectedEvent?.description}
                  </div>
                  <div className='flex flex-row gap-x-2 items-center'>
                    <BsPeopleFill />
                    {
                      selectedEvent?.people.length > 0 ? selectedEvent?.people.map((person, index) => {
                        return person.full_name;
                      }).join(', ') : "No attendees!"
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-2 justify-end">
                  <button
                    className='flex flex-row gap-x-2 items-center py-1 px-2 border rounded-md'
                  >
                    <BsTrash3 />
                    Delete event
                  </button>
                  <button
                    onClick={(e) => setModalTab("edit")}
                    className='flex flex-row gap-x-2 items-center py-1 px-2 border rounded-md'
                  >
                    <BsPencilSquare />
                    Edit event
                  </button>
                  <button
                    onClick={(e) => closeModal()}
                    className='flex flex-row gap-x-2 items-center py-1 px-2 border rounded-md bg-primary text-white'
                  >
                    <BsX />
                    Close
                  </button>
                </div>
              </div>
            )
              :
              modalTab === "edit" ? (
                <EventsEditModal
                  eventStoredInDB={selectedEvent}
                  closeModal={closeModal}
                  events={events}
                  setEvents={setEvents}
                />
              ) : modalTab === "new" && (
                <EventsNewModal
                  slot={slot}
                  closeModal={closeModal}
                  events={events}
                  setEvents={setEvents}
                />
              )
          }
        </Modal>
      </div>
    </Layout>
  );
}
