import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './employeesIncrements.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { FaPlus } from "react-icons/fa6";
//import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Pagination from 'components/global/pagination';
import { toast } from 'react-toastify';

export const EmployeesIncrements = () => {
  const [message, setMessage] = useState(null);
  const [employeesIncrements, setEmployeesIncrements] = useState(null);
  const [employeesIncrementsTotalCount, setEmployeesIncrementsTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/employeeincrements?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employeesIncrements = jsonData.data.docs;

    setEmployeesIncrements(employeesIncrements);
    setEmployeesIncrementsTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  const handleDelete = async (e) => {
    if (!isLoading) {
      setIsLoading(true);
    }
    let deleteButton = e.target.parentElement;
    let id = deleteButton.getAttribute("data-id");
    if (!id) { //the case when e.target was button element and not svg element
      return; //early return
    }
    let url = baseURL + "/employeeincrements/delete/" + id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers
      });
      const jsonData = await response.json();
      if (!response.ok) {
        toast.error(jsonData.data.message);
        setMessage(jsonData.data.message);
        setIsLoading(false);
        return; //early return
      }
      setEmployeesIncrements(employeesIncrements.filter((e) => e._id !== id));
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoading(false);
    }
    catch (error) {
      toast.error("An error occurred while communicating with the server.");
      setMessage("An error occurred while communicating with the server.");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("employeesincrements")
    ) &&
    <Link to="/employees-increments/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>Add Employee Increment</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Employees Increments</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesIncrementsList = [];

  if (employeesIncrements && employeesIncrements.length !== 0) { //on second render
    employeesIncrementsList = employeesIncrements.map((employeeIncrement, index) => {
      //const editLink = "/employeesIncrements/edit/" + employeeIncrement._id;

      let incrementType;

      if (employeeIncrement.incrementType === "costOfLiving")
        incrementType = "Cost of Living";
      else if (employeeIncrement.incrementType === "performance")
        incrementType = "Performance";
      else if (employeeIncrement.incrementType === "promotion")
        incrementType = "Promotion";

      return (
        <tr key={employeeIncrement._id}>
          <td className="px-6 py-3 whitespace-nowrap">{employeeIncrement.employeeIncrementID}</td>
          <td className="px-6 py-3 whitespace-nowrap">{employeeIncrement.employeeID.full_name}</td>
          <td className="px-6 py-3 whitespace-nowrap">{incrementType}</td>
          <td className="px-6 py-3 whitespace-nowrap">{employeeIncrement.incrementAmount}</td>
          <td className="px-6 py-3 whitespace-nowrap">{employeeIncrement.createdAt.split('T')[0]}</td>
          <td className="text-primary px-6 py-4 whitespace-nowrap text-center">
            <button data-id={employeeIncrement._id} onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }

  console.log("Before EmployeesIncrements() rendering");

  return (
    <Layout>
      <div className="space-y-4 text-gray-900">

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Employees Increments</h2>
          {NewDocumentLink}
        </div>

        <div className='flex flex-row justify-between'>
          {message && message}
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto">
            <thead className="text-[14px] border border-b-[#6843BE]">
              <tr className='bg-lightSlate'>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Full Name</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Increment Type</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Increment Amount</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Creation Date</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {employeesIncrementsList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(employeesIncrementsTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
