import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './employeesTasksView.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { PDFViewer } from 'components/PDFViewer/PDFViewer.jsx';

export const EmployeesTasksView = () => {
  const [message, setMessage] = useState(null);
  const [task, setTask] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [whichSubtask, setWhichSubtask] = useState("");
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const getTaskURL = baseURL + "/employeestasks/my-tasks/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getTaskResponse = await fetch(getTaskURL, {
      method: "GET",
      headers: headers
    });
    const getTaskJSONData = await getTaskResponse.json();
    if (!getTaskResponse.ok) {
      toast.error(getTaskJSONData.data.message);
      setMessage(getTaskJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const task = getTaskJSONData.data.doc;

    setTask(task);
    setAttachments(task.attachments);
    setIsLoading(false);
  }

  const sendUpdateSubtaskStatusRequest = async (subtaskObjectID) => {
    setWhichSubtask({ subtask: subtaskObjectID, isSubmitting: true });
    let payload = {
      subtaskObjectID,
      employeeTaskObjectId: params.id
    }
    let url = `${baseURL}/employeestasks/update-subtask-status`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(payload)
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setWhichSubtask({ subtask: subtaskObjectID, isSubmitting: false });
      setTask(jsonData.data.doc);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setWhichSubtask({ subtask: subtaskObjectID, isSubmitting: false });
      return Promise.reject("failure");
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Employee Task</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let subtasksList = [];
  let attachedFilesList = [];

  if (task.subtasks.length === 0) {
    subtasksList = null;
  }
  else {
    subtasksList = task.subtasks.map((subtask, index) => {
      const newSerialNumber = index + 1;
      return (
        <div key={subtask._id} className='flex flex-col md:flex-row border border-primary p-4 m-2 rounded-[10px]'>

          <div className='flex flex-col gap-y-3 w-full md:w-1/5'>
            <label className='text-base font-normal text-left'>Subtask Title #{newSerialNumber}:</label>
            <input
              disabled
              type="text"
              name="subtaskTitle"
              defaultValue={subtask.subtaskTitle}
              className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
            />
          </div>

          <div className='flex flex-col gap-y-3 w-full md:w-1/5'>
            <label className=' text-base font-normal text-left'>Subtask Description:</label>
            <textarea
              disabled
              name="subtaskDescription"
              defaultValue={subtask.subtaskDescription}
              rows="4"
              cols="30"
              className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[10px] px-3 outline-none text-sm'
            ></textarea>
          </div>

          <div className='flex flex-col gap-y-3 w-full md:w-1/5'>
            <label className=' text-base font-normal text-left'>Assigned to:</label>
            <select
              name="subtaskStatus"
              disabled
              defaultValue={subtask.employeeID?._id}
              className='border border-[#8E8EA1] w-full md:w-11/12 py-2 h-10 rounded-[40px] px-3 outline-none text-sm'
            >
              <option value={subtask.employeeID?._id}>{subtask.employeeID?.full_name}</option>
            </select>
          </div>

          <div className='flex flex-col gap-y-3 w-full md:w-1/5'>
            <label className=' text-base font-normal text-left'>Subtask Status:</label>
            <select
              value={subtask.subtaskStatus}
              disabled
              name="subtaskStatus"
              className='border border-[#8E8EA1] w-full md:w-11/12 py-2 h-10 rounded-[40px] px-3 outline-none text-sm'
            >
              <option value="0">Pending</option>
              <option value="1">Finished</option>
            </select>
          </div>

          <div className='flex flex-col gap-y-3 w-full md:w-1/5'>
            <label className=' text-base font-normal text-left'>Change Status:</label>
            <button
              data-subtaskobjectid={subtask._id}
              className='w-full md:w-11/12 text-white bg-primary rounded-[40px] w-full h-9'
              onClick={async (e) => {
                e.preventDefault();
                let subtaskObjectID = e.target.getAttribute("data-subtaskobjectid");
                if (subtaskObjectID) {
                  try {
                    await sendUpdateSubtaskStatusRequest(subtaskObjectID);
                  }
                  catch (error) {
                    console.log(error);
                  }
                }
              }}
            >
              {
                (
                  whichSubtask.subtask === subtask._id && whichSubtask.isSubmitting
                ) ?
                  <ClipLoader size={17} color="#fff" />
                  :
                  "Mark as complete"
              }
            </button>
          </div>

        </div>
      );
    });
  }

  if (attachments && attachments.length > 0) {
    const openWithInWebApp = ["jpeg", "jpg", "png"];

    attachedFilesList = attachments.map((attachment, index) => {
      let link = "#"
      if (attachment.fileNameOnServerDisk)
        link = staticFilesURL + "/employees/" + attachment.fileNameOnServerDisk;

      const tokens = attachment.fileNameOnServerDisk.split('.');

      let content = null;

      if ("pdf".localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
        content = <PDFViewer link={link} />
      }
      else {
        for (const ext of openWithInWebApp) {
          if (ext.localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
            content = <img src={link} alt="attached photograph" />
          }
        }
      }

      if (!content) {
        content = "No preview available";
      }

      return (
        <div key={index} className='flex flex-col gap-y-3'>
          <div>
            {`${index + 1}) ${attachment.clientFileName}`}
          </div>
          <div className='flex flex-row border border-[#8E8EA1] rounded-[10px]'>
            <div className='border border-r-[#8E8EA1] p-3 md:w-5/6'>
              {
                content
              }
            </div>
            <div className='p-3 md:w-1/6'>
              <a
                key={attachment._id}
                className='btn-primary justify-center'
                href={link}
                download
              >
                Download
              </a>
            </div>
          </div>
        </div>
      );
    })
  }
  else {
    attachedFilesList = <div>"No files attached!"</div>;
  }

  console.log("Before EmployeesTasksView() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5 text-gray-900'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Employee Task</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-primary p-8 space-y-3'>

          <form id="editEmployeeTaskForm" className='space-y-3'>

            <div>
              ID: {" "}
              {
                task.employeeTaskID
              }
            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                <label className='text-base font-normal text-left'>Employees:</label>
                {
                  task.employeeIDs.map((employee) => {
                    return employee.full_name
                  }).join(", ")
                }
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                <label className='text-base font-normal text-left'>Supervisor:</label>
                <div>
                  {task.supervisor.full_name}
                </div>
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                <label
                  htmlFor="taskTitle"
                  className=' text-base font-normal text-left'
                >
                  Task Title
                </label>
                <input
                  disabled
                  type="text"
                  name="taskTitle"
                  id="taskTitle"
                  defaultValue={task.taskTitle}
                  className='border border-[#8E8EA1]  w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                <label
                  htmlFor="taskDescription"
                  className=' text-base font-normal text-left'
                >
                  Task Description
                </label>
                <textarea
                  disabled
                  id="taskDescription"
                  name="taskDescription"
                  rows="4"
                  cols="50"
                  defaultValue={task.taskDescription}
                  className='border border-[#8E8EA1]  w-full md:w-11/12 py-2 rounded-[10px] px-3 outline-none text-sm'
                >
                </textarea>
              </div>

            </div>

            <div className='flex flex-col gap-y-3 w-full'>

              <div id="subtasks">
                {subtasksList}
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskStartByDate"
                  className=' text-base font-normal text-left'
                >
                  Task Start Date
                </label>
                <input
                  disabled
                  type="date"
                  name="taskStartByDate"
                  defaultValue={task.taskStartByDate.split('T')[0]}
                  className='border border-[#8E8EA1]  w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />{" "}
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskFinishByDate"
                  className=' text-base font-normal text-left'
                >
                  Task Approx. Finish Date
                </label>
                <input
                  disabled
                  type="date"
                  name="taskFinishByDate"
                  defaultValue={task.taskFinishByDate.split('T')[0]}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />{" "}
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskActualFinishDate"
                  className=' text-base font-normal text-left'
                >
                  Task Actual Finish Date
                </label>
                <input
                  disabled
                  type="date"
                  name="taskActualFinishDate"
                  defaultValue={task.taskActualFinishDate?.split('T')[0]}
                  className='border border-[#8E8EA1]  w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />{" "}
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskStatus"
                  className=' text-base font-normal text-left'
                >
                  Task Status
                </label>
                <select
                  disabled
                  id="taskStatus"
                  name="taskStatus"
                  defaultValue={task.taskStatus}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 h-10  rounded-[40px] px-3 outline-none text-sm'
                >
                  <option value="0">Pending</option>
                  <option value="1">Finished</option>
                </select>{" "}
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskPriority"
                  className=' text-base font-normal text-left'
                >
                  Task Priority
                </label>
                <select
                  disabled
                  id="taskPriority"
                  name="taskPriority"
                  defaultValue={task.taskPriority}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 h-10 rounded-[40px] px-3 outline-none text-sm'
                >
                  <option value="0">Low</option>
                  <option value="1">Normal</option>
                  <option value="2">High</option>
                  <option value="3">Urgent</option>
                </select>{" "}
              </div>

            </div>

            <div>
              Currently attached files with this task:
            </div>

            <div className='flex flex-col gap-y-3'>
              {attachedFilesList}
            </div>

          </form>

        </div>

      </div >

    </Layout >
  );
}