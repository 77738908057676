import Footer from "./footer"
import Header from "./header"
import Sidebar from "./sidebar"

const Layout = ({ children, bgGray = true }) => {

  console.log("Before Layout() rendering");

  return (
    <div className="sm:pb-0 pb-20 space">
      <Sidebar />
      <main className="">
        <Header />
        <div className={`sm:py-6 py-4 sm:px-6 px-2 ${bgGray ? 'bg-gray' : ''}`}>
          {children}
        </div>
        <Footer />
      </main>
    </div>
  );
}

export default Layout