import Select from 'react-select';

const SelectBox = ({ name, options, handleChange, defaultValue = '' }) => {

  const selectedOption = options.find(option => option.value === defaultValue);

  return (
    <Select
      name={name}
      options={options}
      onChange={handleChange}
      defaultValue={selectedOption}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: '#8E8EA1',
        }),
      }}
    />
  );
}

export default SelectBox;