import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import styles from './projectsCategoriesNew.module.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';

export const ProjectsCategoriesNew = () => {
  const [message, setMessage] = useState(null);
  const [loading,setLoading]=useState(false);
  const { userData } = useContext(AuthContext);
  

  async function sendAPIRequest(payload) {
    setLoading(true);
    let url = baseURL + "/projectscategories/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch( url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
     setLoading(false);
    if (jsonData.success) {
      setMessage(jsonData.data.message);
    }
    else {
      //setMessage("An error occurred.");
      setMessage(jsonData.data.message);
    }
  }

  async function handleCreateProjectCategoryForm(event) {
    event.preventDefault();
    let createProjectCategoryForm = event.target;
    let payload = {}; //empty object
    let projectCategoryName = createProjectCategoryForm.elements.namedItem("projectCategoryName").value;
    payload.projectCategoryName = projectCategoryName;
    sendAPIRequest(payload);
  }

  console.log("Before ProjectsCategoriesNew() rendering");

  return (
    <Layout>
        <div className='w-full space-y-5'>
        <div className='flex flex-row justify-start '>
    <h2 className='text-2xl text-primary font-bold'>Create a new category for projects</h2>
    </div>
    <div className='flex flex-row justify-start'>
      <div>
          {message && message}
      </div>
     </div>
     <div className='flex flex-col  py-3 px-4 border border-[#2F80ED] rounded-lg'>
     {loading ?<Loader/>: <form id="createProjectCategoryForm" onSubmit={handleCreateProjectCategoryForm}>
     <div className='flex flex-col space-y-3    w-full md:w-2/5'>
     <label className='text-[#8E8EA1] text-base font-normal text-left '>Project Category Name</label>
     <input type="text" name="projectCategoryName" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'/>
     </div>
     <div className='flex flex-row justify-start my-5    '>
        <input type="submit" name="submit" value="Submit" className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9' />
        </div>
     </form>

     }



  </div>




   </div>
      
    </Layout>


 



     
      
  )
}
