import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import './purchaseOrders.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import Pagination from 'components/global/pagination';

export const PurchaseOrders = () => {
  const [message, setMessage] = useState(null);
  const [purchaseOrders, setPurchaseOrders] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  async function fetchData() {
    let url = baseURL + "/purchaseorders/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const purchaseOrders = jsonData.data.docs;

    setPurchaseOrders(purchaseOrders);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  console.log("Before PurchaseOrders() rendering");

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Purchase Orders</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let purchaseOrdersList = [];

  if (purchaseOrders && purchaseOrders.length !== 0) { //on second render
    purchaseOrdersList = purchaseOrders.map((purchaseOrder, index) => {
      let editLink = "/purchaseOrders/edit/" + purchaseOrder._id;
      const newPaymentVoucherLink = "/paymentvouchers/new/of-purchaseorder/" + purchaseOrder._id;
      const trackPaymentVouchers = "/paymentvouchers/of-purchaseorder/" + purchaseOrder._id;
      return (
        <tr key={purchaseOrder._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{purchaseOrder.purchaseOrderID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/vendors/${purchaseOrder.vendorID._id}`}>
              {purchaseOrder.vendorID.vendorCompanyName}
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{purchaseOrder.purchaseOrderCreator.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/projects/${purchaseOrder.quotationID.projectID._id}`}>
              {purchaseOrder.quotationID.projectID.projectName}
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            {
              purchaseOrder.selectedForPaymentVoucher ?
                <Link to={newPaymentVoucherLink} className='text-white bg-primary rounded-[40px] p-2 h-9'>
                  New Payment Voucher
                </Link>
                : "No approval yet"
            }
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            {
              purchaseOrder.selectedForPaymentVoucher ?
                <Link to={trackPaymentVouchers} className='text-white bg-primary rounded-[40px] p-2 h-9'>
                  Track Payments
                </Link>
                : "No approval yet"
            }
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link to={editLink} className='text-2xl text-primary'>
              <CiEdit />
            </Link>
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-center">
            <button onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Purchase Orders</h2>
        </div>
        <div className='flex flex-row justify-center'>
          {message && message}
        </div>
        <div className='overflow-x-auto  rounded-lg  border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto ">
            <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Purchase Order ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Vendor Company Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Purchase Order Creator</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Generate Payment Voucher</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Track Payments</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {purchaseOrdersList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='12'>
                  <Pagination
                    currentPage={1}
                    pageCount={1}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
