import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import styles from './vendorsNew.module.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';

export const VendorsNew = () => {
  const [message, setMessage] = useState(null);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    console.log("Inside sendAPIRequest");
    let url = baseURL + "/vendors/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch( url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setMessage(jsonData.data.message);
    }
    else {
      //setMessage("An error occurred.");
      setMessage( jsonData.data.message );
    }
  }

  async function handleCreateVendorForm(event) {
    event.preventDefault();
    let errors = [];
    let createVendorForm = event.target;
    let vendorCompanyName = createVendorForm.elements.namedItem("vendorCompanyName").value;
    let vendorEmail = createVendorForm.elements.namedItem("vendorEmail").value;
    let vendorAddress = createVendorForm.elements.namedItem("vendorAddress").value;
    let vendorPhone = createVendorForm.elements.namedItem("vendorPhone").value;

    let payload = {
      vendorCompanyName,
      vendorEmail,
      vendorAddress,
      vendorPhone
    };

    if(errors.length > 0) {
      let errorMessage = errors.join(', ');
      setMessage(errorMessage);
    }
    else {
      sendAPIRequest(payload);
    }

  }

  useEffect(() => {
    //
  }, []);

  console.log("Before VendorsNew() rendering");

  return (
    <Layout>
    <div className='space-y-4'>
    <div className='flex flex-row justify-between items-center px-3 '>
    <h2 className='text-2xl text-primary font-bold	'>Create a new vendor</h2>
    </div>
    <div className='flex flex-row justify-between'>
    {message && message}
    </div>
    <div className='overflow-x-auto rounded-lg  border border-[#6843BE] p-4 '>      {/* <h2>Create a new vendor</h2> */}
      {message && message}
      <form id="createVendorForm" onSubmit={handleCreateVendorForm}>
      <div className='space-y-2'>
        <p>Vendor Company Name: <input id="vendorCompanyName" type="text" name="vendorCompanyName" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
        <p>Vendor Email: <input id="vendorEmail" type="text" name="vendorEmail" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
        <p>Vendor Address: <input id="vendorAddress" type="text" name="vendorAddress" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
        <p>Vendor Phone: <input id="vendorAddress" type="text" name="vendorPhone" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
        </div>
        {/* Vendor Company Name: <input type="text" name="vendorCompanyName" /><br /><br />
        Vendor Email: <input type="text" name="vendorEmail" /><br /><br />
        Vendor Address: <input type="text" name="vendorAddress" /><br /><br />
        Vendor Phone: <input type="text" name="vendorPhone" /><br /><br /> */}
        <input type="submit" name="submit" value="Submit"   className='text-white bg-primary rounded-[40px] w-[299px] h-9 my-4'/>
      </form>
    </div>
    </div>
    </Layout>
    
  )
}
