import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import './utilities.css';
import App from './App.jsx';

import { AuthContextProvider } from './context/AuthContext.jsx';
import DrawerContextProvider from 'context/DrawerContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

let localStorageData;

if (!localStorage.getItem("technoPropertiesData")) { //first visit of the visitor
  localStorageData = { doc: null, token: null, role: null }; //to be used in AuthContextProvider component
}
else {
  localStorageData = JSON.parse(atob(localStorage.getItem("technoPropertiesData")));
}

root.render(
  
    <AuthContextProvider localStorageData={localStorageData}>
      <DrawerContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DrawerContextProvider>
    </AuthContextProvider>
  
);

/*root.render(
  <React.StrictMode>
    <AuthContextProvider localStorageData={localStorageData}>
      <DrawerContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DrawerContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);*/
