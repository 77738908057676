import React from 'react';
import { baseURL } from 'config/api.js';
import { useState } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';

export const AdminNew = () => {
  const [message, setMessage] = useState(null);
  const { userData } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.target.elements.namedItem("submit").setAttribute("disabled", "disabled");
    let form = event.target;
    let payload = {};
    let fullName = form.elements.namedItem("fullName").value;
    let email = form.elements.namedItem("email").value;
    let password = form.elements.namedItem("password").value;
    let confirmPassword = form.elements.namedItem("confirmPassword").value;
    if (password !== confirmPassword) {
      setMessage("Passwords don't match!");
      event.target.elements.namedItem("submit").removeAttribute("disabled");
      return; //early return
    }
    let managementRank = form.elements.namedItem("managementRank").value;
    payload.email = email;
    payload.password = password;
    payload.managementRank = managementRank;
    payload.fullName = fullName;

    let url = baseURL + "/users/createadmin";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + userData.token
      },
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setMessage(jsonData.data.message);
    }
    else {
      setMessage(jsonData.data.message);
    }
  };

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold	'>Create a new admin</h2>
        </div>
        <div className='overflow-x-auto rounded-lg  border border-[#6843BE] p-4 '>
          {/* <h2>Create a new admin</h2> */}
          {message && message}
          <form id="myForm" onSubmit={handleSubmit}>
            <div className='space-y-2'>
              <p>Full Name: <input type="text" name="fullName" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
              <p>Email: <input type="text" name="email" id="email" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
              <p>Password: <input type="password" name="password" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
              <p>Confirm Password: <input type="password" name="confirmPassword" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /></p>
              Management Rank:
              <select name="managementRank" className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm'>
                <option value=""></option>
                <option value="ceo">Chief Executive Officer</option>
                <option value="coo">Chief Operations Officer</option>
                <option value="md">Managing Director</option>
              </select><br /><br />
              <input type="submit" name="submit" value="Submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9 my-4' />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
