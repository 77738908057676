import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './departmentsNew.module.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Loader from 'components/global/Loader';
import Layout from 'components/global/Layout';

export const DepartmentsNew = () => {
  const [message, setMessage] = useState(null);
  const [companies, setCompanies] = useState([]);
  const { userData } = useContext(AuthContext);
  const [loading ,setLoading]=useState(false);
  
  async function fetchData() {
    setLoading(true);
    let url = baseURL + "/companies/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    setLoading(false);
    const companies = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;
    if (docsCount > 0) {
      setCompanies(companies);
    }
    else {
      setMessage("No companies found! Please create a new company before creating its departments.")
    }
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/departments/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch( url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setMessage(jsonData.data.message);
    }
    else {
      //setMessage("An error occurred.");
      setMessage(jsonData.data.message);
    }
  }

  async function handleCreateDepartmentForm(event) {
    event.preventDefault();
    let createDepartmentForm = event.target;
    let department_name = createDepartmentForm.elements.namedItem("department_name").value;
    let company_id = createDepartmentForm.elements.namedItem("company_id").value;
    const payload = {department_name, company_id};
    sendAPIRequest(payload);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  console.log("Before DepartmentsNew() rendering");

  // if(companies.length === 0) { //on first render
  //   <main className={styles["departments-new"]}>
  //     <h2>Create new Department</h2>
  //     {message && message}
  //     <div>Fetching data from the server. Please wait!</div>
  //   </main>
  // }

  let companiesList = [];
  
  if( companies.length !== 0 ) { //After calling companies/ endpoint
    companiesList = companies.map( (company, index) => {
      return (
        <option key={index} value={company._id}>{company.name}</option>
      );
    });
  }

  return (
    <Layout >
    <div className='w-full space-y-5'>
    <div className='flex flex-row justify-between items-center px-3 '>
    <h2 className='text-2xl text-primary font-bold'>Create new Department</h2>
    </div>
     
    <div className='flex flex-row justify-start'>
      <div>
          {message && message}
      </div>
     </div>
    
     <div className='flex flex-col   px-4 border border-[#2F80ED] rounded-lg py-3'>
     {loading ?<Loader/>: 
     <form id="createDepartmentForm" onSubmit={handleCreateDepartmentForm}>

<div className='flex flex-col space-y-3    w-full md:w-2/5'>
 <label  className='text-[#8E8EA1] text-base font-normal text-left '>Department Name</label>
 <input type="text" name="department_name" placeholder='Department Name'  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' />
 <label  className='text-[#8E8EA1] text-base font-normal text-left '>Company</label>
        <select name="company_id"  className='border border-[#8E8EA1] text-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
          {companiesList}
        </select>
</div>
<div className='flex flex-row justify-start my-5    '>
   <input type="submit" name="submit" value="Submit" className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9' />
   </div>
 </form>}
      </div>
     
    {/* </div> */}
  </div>
    </Layout>
  );
}
