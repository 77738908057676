import React from "react";
import { baseURL } from 'config/api.js';
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//import styles from "./login.module.scss";
import { AuthContext } from "../../context/AuthContext.jsx";
import { useContext } from "react";
import { toast } from 'react-toastify';

import logo from "../../assets/logo.png";
import { PulseLoader } from "react-spinners";
import CustomInput from "components/global/CustomInput";

export const Login = () => {
  const { addUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    console.log("Inside Login.jsx HandleSumbit");
    e.preventDefault();
    let payload = {};
    setLoading(true);
    payload.email = email;
    payload.password = password;
    let loginURL = baseURL + "/login";
    let response;
    try {
      response = await fetch(loginURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    }
    catch (error) {
      toast.error("An error occurred while communicating with the server.");
      setLoading(false);
      return; //early return
    }
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setLoading(false);
      return; //early return
    }
    const doc = jsonData.data.doc;
    const token = jsonData.data.token;
    const role = jsonData.data.role;
    addUserData({ doc, token, role });
    setLoading(false);

    const origin = location.state?.from?.pathname || "/dashboard";
    navigate(origin);
  };

  console.log("Before Login() rendering");

  return (
    <div className='bg-white w-full min-h-screen max-h-full pl-0 '>
      <div className="flex flex-col items-center justify-center px-6 py-8 md:h-screen">
        <div>
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
          >
            <img className="h-12" src={logo} alt="technoproperties" />
          </a>
        </div>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-gray-900 md:text-2xl">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <CustomInput
                label="Email"
                name="email"
                type="email"
                placeholder="name@company.com"
                data={email}
                setData={setEmail}
                required
              />
              <CustomInput
                label="Password"
                placeholder="Password"
                name="password"
                data={password}
                setData={setPassword}
                required
                isPassword={true}
              />

              <div>
                <button
                  type="submit"
                  className="bg-primary  py-2.5 text-pure uppercase font-semibold w-full text-center rounded-full hover:bg-primaryHover"
                >
                  {loading ? <PulseLoader size={10} color="#fff" /> : "Sign In"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};