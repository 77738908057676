import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './employeesTasksNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const EmployeesTasksNew = () => {
  const [message, setMessage] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    let url = baseURL + "/companies/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const companies = jsonData.data.docs;

    let getEmployeesURL = baseURL + "/employees/of-company/" + companies[0]._id;
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const jsonData2 = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(jsonData2.data.message);
      setMessage(jsonData2.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = jsonData2.data.docs;

    setCompanies(companies);
    setEmployees(employees);
    setIsLoading(false);
  }

  const handleCompanyChange = async () => {
    if (!isCompanyLoading) {
      setIsCompanyLoading(true);
    }
    let subtasks = document.getElementById("subtasks");
    subtasks.innerHTML = "";
    let companyObjectId = document.getElementsByName("company")[0].value;
    let url = baseURL + "/employees/of-company/" + companyObjectId;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    const employees = jsonData.data.docs;
    setEmployees(employees);
    setIsCompanyLoading(false);
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeestasks/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateEmployeeTaskForm(event) {
    event.preventDefault();
    const createEmployeeTaskForm = event.target;
    const errors = [];

    setIsSubmitting(true);

    const employeeIDs = [];
    //Assumption: Checkboxes are used for employee IDs only
    const checkboxes = createEmployeeTaskForm.querySelectorAll('input[type=checkbox]:checked');
    for (let i = 0; i < checkboxes.length; i++) {
      employeeIDs.push(checkboxes[i].value);
    }
    const supervisor = createEmployeeTaskForm.elements.namedItem("supervisor").value;
    const taskTitle = createEmployeeTaskForm.elements.namedItem("taskTitle").value;
    const taskDescription = createEmployeeTaskForm.elements.namedItem("taskDescription").value;
    const taskStartByDate = createEmployeeTaskForm.elements.namedItem("taskStartByDate").value;
    const taskFinishByDate = createEmployeeTaskForm.elements.namedItem("taskFinishByDate").value;
    const taskActualFinishDate = createEmployeeTaskForm.elements.namedItem("taskActualFinishDate").value;
    const taskStatus = createEmployeeTaskForm.elements.namedItem("taskStatus").value;
    const taskPriority = createEmployeeTaskForm.elements.namedItem("taskPriority").value;

    const subtasks = []; //default: 0 sub tasks
    const employeesOfSubTasks = []; //to synchronize
    let subtasksDiv = document.getElementById("subtasks");
    const currentNumberOfSubTasks = subtasksDiv.children.length;
    for (let i = 1; i <= currentNumberOfSubTasks; i++) {
      let subtaskDiv = document.getElementById(`subtask${i}`);
      const subtaskTitle = subtaskDiv.getElementsByTagName("input")[0].value;
      const subtaskDescription = subtaskDiv.getElementsByTagName("textarea")[0].value;
      const subtaskEmployee = subtaskDiv.getElementsByTagName("select")[0].value;
      const subtaskStatus = subtaskDiv.getElementsByTagName("select")[1].value;

      employeesOfSubTasks.push(subtaskEmployee);

      if (subtaskTitle.length > 0) {
        subtasks.push({
          subtaskTitle,
          subtaskDescription,
          subtaskStatus,
          employeeID: subtaskEmployee
        });
      }
      else {
        errors.push("Subtask title can't be empty");
      }
    }

    const attachments = [];
    let taskFileAttachment = createEmployeeTaskForm.elements.namedItem("taskFileAttachment").files[0];
    if (taskFileAttachment) { //undefined if no file was selected
      const clientFileName = taskFileAttachment.name;
      if (((taskFileAttachment.size / 1024) / 1024) > 5) { //if greater than 5 megabytes
        errors.push("Attached file size should be less than 5 MB");
      }
      else {
        const fileAsBase64 = await convertToBase64(taskFileAttachment);
        attachments.push({ clientFileName, fileAsBase64 });
      }
    }

    for (let i = 0; i < employeesOfSubTasks.length; i++) {
      if (!employeeIDs.includes(employeesOfSubTasks[i]))
        errors.push("An employee selected for a subtask is not included in the group selected for the main task");
    }

    const payload = {
      employeeIDs,
      supervisor,
      taskTitle,
      taskDescription,
      taskStartByDate,
      taskFinishByDate,
      taskActualFinishDate,
      taskStatus,
      taskPriority,
      subtasks,
      attachments
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  const handleAddNewSubtaskButton = (e) => {
    e.preventDefault(); //submit event bubbles up and causes submission of form

    const totalNumberOfSubtasksAllowed = 10;
    let subtasks = document.getElementById("subtasks");
    const currentNumberOfSubTasks = subtasks.children.length; //includes only element nodes, excludes text nodes and HTML comment nodes
    if (currentNumberOfSubTasks === totalNumberOfSubtasksAllowed) {
      toast.error(`Only ${totalNumberOfSubtasksAllowed} subtasks are allowed`);
      setMessage(`Only ${totalNumberOfSubtasksAllowed} subtasks are allowed`);
      return; //early return
    }
    const newSerialNumber = currentNumberOfSubTasks + 1;
    let newSubtaskDiv = document.createElement("div");
    newSubtaskDiv.setAttribute('id', `subtask${newSerialNumber}`);
    newSubtaskDiv.setAttribute('class', 'flex flex-col md:flex-row border border-primary p-4 m-2 rounded-[10px]');

    let titleInputDiv = document.createElement("div");
    titleInputDiv.setAttribute('class', 'flex flex-col gap-y-3 w-full md:w-1/4');

    let titleInputLabel = document.createElement("label");
    titleInputLabel.setAttribute('class', 'text-base font-normal text-left');
    const titleTextNode = document.createTextNode(`Subtask Title #${newSerialNumber}: `);
    titleInputLabel.appendChild(titleTextNode);

    const titleInput = document.createElement("input");
    titleInput.setAttribute('type', 'text');
    titleInput.setAttribute('name', 'subTaskTitle');
    titleInput.setAttribute('class', 'border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm');

    titleInputDiv.appendChild(titleInputLabel);
    titleInputDiv.appendChild(titleInput);

    newSubtaskDiv.appendChild(titleInputDiv);

    let descriptionTextAreaDiv = document.createElement("div");
    descriptionTextAreaDiv.setAttribute('class', 'flex flex-col gap-y-3 w-full md:w-1/4');

    let descriptionTextAreaLabel = document.createElement("label");
    descriptionTextAreaLabel.setAttribute('class', 'text-base font-normal text-left');
    const descriptionTextNode = document.createTextNode(`Subtask Description: `);
    descriptionTextAreaLabel.appendChild(descriptionTextNode);

    const descriptionTextArea = document.createElement("textarea");
    descriptionTextArea.setAttribute('name', 'subtaskDescription');
    descriptionTextArea.setAttribute('rows', '4');
    descriptionTextArea.setAttribute('cols', '30');
    descriptionTextArea.setAttribute('class', 'border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[10px] px-3 outline-none text-sm');

    descriptionTextAreaDiv.appendChild(descriptionTextAreaLabel);
    descriptionTextAreaDiv.appendChild(descriptionTextArea);

    newSubtaskDiv.appendChild(descriptionTextAreaDiv);

    let employeeSelectInputDiv = document.createElement("div");
    employeeSelectInputDiv.setAttribute('class', 'flex flex-col gap-y-3 w-full md:w-1/4');

    let employeeSelectInputLabel = document.createElement("label");
    employeeSelectInputLabel.setAttribute('class', 'text-base font-normal text-left');
    const employeeTextNode = document.createTextNode(`Assign to: `);
    employeeSelectInputLabel.appendChild(employeeTextNode);

    const employeeSelectInput = document.createElement("select");
    employeeSelectInput.setAttribute('name', 'subtaskEmployee');
    for (let i = 0; i < employees.length; i++) {
      employeeSelectInput.add(new Option(`${employees[i].full_name} ${employees[i].custom_id}`, `${employees[i]._id}`));
    }
    employeeSelectInput.setAttribute('class', 'border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm');

    employeeSelectInputDiv.appendChild(employeeSelectInputLabel);
    employeeSelectInputDiv.appendChild(employeeSelectInput);

    newSubtaskDiv.appendChild(employeeSelectInputDiv);

    let selectInputDiv = document.createElement("div");
    selectInputDiv.setAttribute('class', 'flex flex-col gap-y-3 w-full md:w-1/4');

    let selectInputLabel = document.createElement("label");
    selectInputLabel.setAttribute('class', 'text-base font-normal text-left');
    const statusTextNode = document.createTextNode(`Subtask Status: `);
    selectInputLabel.appendChild(statusTextNode);

    const selectInput = document.createElement("select");
    selectInput.setAttribute('name', 'subtaskStatus');
    let pendingOption = new Option('Pending', '0');
    let finishedOption = new Option('Finished', '1');
    selectInput.add(pendingOption);
    selectInput.add(finishedOption);
    selectInput.setAttribute('class', 'border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm');

    selectInputDiv.appendChild(selectInputLabel);
    selectInputDiv.appendChild(selectInput);

    newSubtaskDiv.appendChild(selectInputDiv);

    subtasks.appendChild(newSubtaskDiv);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a new task</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before EmployeesTasksNew() rendering");

  let companiesList = [];
  let employeesList = [];
  let supervisorsList = [];

  if (companies && companies.length !== 0) {
    companiesList = companies.map((company, index) => {
      return (
        <option key={company._id} value={company._id}>{company.name}</option>
      );
    });
  }

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      let inputName = "employee" + index;
      return (
        <div key={employee._id}>
          <input type="checkbox" name={inputName} defaultValue={employee._id} />
          {" "}{employee.full_name}
        </div>
      );
    });

    supervisorsList = employees.map((employee, index) => {
      return (
        <div key={employee._id}>
          <input type="radio" name="supervisor" defaultValue={employee._id} />
          {" "}{employee.full_name}
        </div>
      );
    });

  }

  return (
    <Layout >
      <div className='w-full space-y-5 text-gray-900'>

        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Create a new task</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-primary p-8 space-y-3'>

          <div className='space-y-3 w-full my-3'>
            <div className='text-sm'>
              Select a company to show employees working for this company.
            </div>
          </div>

          <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
            <label className=' text-base font-normal text-left'>
              Company
            </label>
            <select
              name="company"
              onChange={handleCompanyChange}
              className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
            >
              {companiesList}
            </select>
          </div>

          <form id="createEmployeeTaskForm" onSubmit={handleCreateEmployeeTaskForm} className='space-y-3'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              {
                isCompanyLoading ? (
                  <div className='flex flex-row md:w-1/3 justify-around m-4'>
                    <ClipLoader size={25} color="#000" />
                  </div>
                ) :
                  (
                    <React.Fragment>
                      <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                        <label className='text-base font-normal text-left'>Employees:</label>
                        <div>
                          {employeesList}
                        </div>
                      </div>

                      <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                        <label className='text-base font-normal text-left'>Supervisors:</label>
                        <div>
                          {supervisorsList}
                        </div>
                      </div>
                    </React.Fragment>
                  )
              }
            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                <label
                  htmlFor="taskTitle"
                  className=' text-base font-normal text-left'
                >
                  Task Title
                </label>
                <input
                  type="text"
                  name="taskTitle"
                  id="taskTitle"
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/2'>
                <label
                  htmlFor="taskDescription"
                  className=' text-base font-normal text-left'
                >
                  Task Description
                </label>
                <textarea
                  id="taskDescription"
                  name="taskDescription"
                  rows="4"
                  cols="50"
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[10px] px-3 outline-none text-sm'
                >
                </textarea>
              </div>

            </div>

            <div className='flex flex-col gap-y-3 w-full'>

              <div className='font-semibold'>Maximum 10 sub tasks can be added.</div>

              <div id="subtasks">
              </div>

            </div>

            <button
              id="addNewSubtaskButton"
              className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              onClick={handleAddNewSubtaskButton}
            >
              Add New Sub Task
            </button>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskStartByDate"
                  className=' text-base font-normal text-left'
                >
                  Task Start Date
                </label>
                <input
                  type="date"
                  name="taskStartByDate"
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />{" "}
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskFinishByDate"
                  className='text-base font-normal text-left'
                >
                  Task Approx. Finish Date
                </label>
                <input
                  type="date"
                  name="taskFinishByDate"
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />{" "}
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskActualFinishDate"
                  className=' text-base font-normal text-left'
                >
                  Task Actual Finish Date
                </label>
                <input
                  type="date"
                  name="taskActualFinishDate"
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />{" "}
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskStatus"
                  className=' text-base font-normal text-left'
                >
                  Task Status
                </label>
                <select
                  id="taskStatus"
                  name="taskStatus"
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 h-10  rounded-[40px] px-3 outline-none text-sm'
                >
                  <option value="0">Pending</option>
                  <option value="1">Finished</option>
                </select>{" "}
              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-1/3'>
                <label
                  htmlFor="taskPriority"
                  className=' text-base font-normal text-left'
                >
                  Task Priority
                </label>
                <select
                  id="taskPriority"
                  name="taskPriority"
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 h-10 rounded-[40px] px-3 outline-none text-sm'
                >
                  <option value="0">Low</option>
                  <option value="1">Normal</option>
                  <option value="2">High</option>
                  <option value="3">Urgent</option>
                </select>{" "}
              </div>

            </div>

            <div>
              <input
                type="file"
                name="taskFileAttachment"
              />
            </div>

            <div className='flex flex-row justify-center my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                id="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </form>

        </div>

      </div >

    </Layout >
  );
}
