import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './quotationsNew.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const QuotationsNew = () => {
  const [message, setMessage] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [demand, setDemand] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let url = baseURL + "/vendors/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const vendors = jsonData.data.docs;

    let getDemandURL = baseURL + "/demands/" + params.id;
    const getDemandResponse = await fetch(getDemandURL, {
      method: "GET",
      headers: headers
    });
    const getDemandJSONData = await getDemandResponse.json();
    if (!getDemandResponse.ok) {
      toast.error(getDemandJSONData.data.message);
      setMessage(getDemandJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const demand = getDemandJSONData.data.doc;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    setVendors(vendors);
    setDemand(demand);
    setEmployees(employees);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    console.log("Inside sendAPIRequest");
    let url = baseURL + "/quotations/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateQuotationForm(event) {
    event.preventDefault();
    let errors = [];
    let createQuotationForm = event.target;

    setIsSubmitting(true);

    let vendorID = createQuotationForm.elements.namedItem("vendorID");
    if (vendorID) {
      if (!vendorID.value) {
        errors.push("Vendor is required");
      }
      else {
        vendorID = vendorID.value;
      }
    }

    let quotationCreator = createQuotationForm.elements.namedItem("quotationCreator");
    if (quotationCreator) {
      if (!quotationCreator.value) {
        errors.push("Quotation creator is required");
      }
      else {
        quotationCreator = quotationCreator.value;
      }
    }

    let materials = [];
    let quotationItemsDiv = document.querySelector(`#createQuotationForm #quotationItemsList`);
    const currentNumberOfItems = quotationItemsDiv.children.length;

    for (let i = 1; i <= currentNumberOfItems; i++) {
      let itemDiv = quotationItemsDiv.querySelector(`#itemCount` + i);
      const stockItemObjectId = itemDiv.getAttribute(`data-stockid`);
      const stockItemName = itemDiv.querySelector(`#nameItemCount${i}`).childNodes[0].nodeValue;
      const demandQuantity = parseFloat(itemDiv.querySelector(`#demandQuantityItemCount${i}`).childNodes[0].nodeValue);
      const quoteQuantity = parseFloat(itemDiv.querySelector(`input[name="quoteItemCount${i}"`).value);
      const rate = parseFloat(itemDiv.querySelector(`input[name="rateItemCount${i}"`).value);

      if (isNaN(demandQuantity)) {
        errors.push("Demand quantity is not a number");
      }
      if (isNaN(quoteQuantity)) {
        errors.push("Quote quantity is not a number");
      }
      if (isNaN(rate)) {
        errors.push("Quoted rate is not a number");
      }

      materials.push({
        stockItemObjectId,
        stockItemName,
        demandQuantity,
        quoteQuantity,
        rate
      });
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        vendorID,
        projectID: demand.projectObjectId._id,
        demandID: params.id,
        quotationCreator: quotationCreator ? quotationCreator : undefined,
        materials
      };
      try {
        await sendAPIRequest(payload);
        setIsDisabled(true);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a new quotation</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let vendorsList = [];
  let employeesList = [];
  let demandItemsList = [];

  if (vendors && vendors.length !== 0) {
    vendorsList = vendors.map((vendor, index) => {
      return (
        <option key={vendor._id} value={vendor._id}>{vendor.vendorCompanyName}</option>
      );
    });
  }

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (demand.materials && demand.materials.length !== 0) {
    let count = 0;
    demandItemsList = demand.materials.map((material, index) => {
      count++;
      return (
        <tr key={material._id} id={`itemCount` + count} data-stockid={material.stockItemObjectId} >
          <td id={`nameItemCount` + count}>{`${material.stockItemName}`}</td>
          <td id={`demandQuantityItemCount` + count}>{`${material.quantity}`}</td>
          <td><input name={`quoteItemCount` + count} type="number" min="0" step="0.01" defaultValue={0} className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm' /></td>
          <td><input name={`rateItemCount` + count} type="number" min="0" step="0.01" defaultValue={0} className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm' /></td>
        </tr>
      );
    });
  }

  console.log("Before QuotationsNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create a new quotation</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg border border-primary p-8'>
          <form id="createQuotationForm" onSubmit={handleCreateQuotationForm} className="space-y-5 text-gray-900">

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3  my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Project Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{demand.projectObjectId.projectName}</div>
              </div>

              <div className="flex flex-col space-y-3 w-full md:w-1/3">
                <label className="text-base font-normal text-left">Vendor</label>
                <select name="vendorID" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                  <option value={""}>Select a vendor</option>
                  {vendorsList}
                </select>
              </div>
              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                &&
                <div className="flex flex-col space-y-3 w-full md:w-1/3">
                  <label className="text-base font-normal text-left">Quotation Creator</label>
                  <select name="quotationCreator" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                    <option value={""}>Select an employee</option>
                    {employeesList}
                  </select>
                </div>
              }
            </div>
            <div className='overflow-x-auto rounded-lg border'>
              <table>
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Demand</th>
                    <th>Quote</th>
                    <th>Rate</th>
                  </tr>
                </thead>
                <tbody id="quotationItemsList">
                  {demandItemsList}
                </tbody>
              </table>
            </div>

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting || isDisabled} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
