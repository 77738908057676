import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './paymentVouchersNew.css';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const PaymentVouchersNew = () => {
  const [message, setMessage] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [paymentMode, setPaymentMode] = useState("");

  const [paidAmountOnlineTransfer, setPaidAmountOnlineTransfer] = useState(0);
  const [paidAmountCheque, setPaidAmountCheque] = useState(0);
  const [paidAmountCash, setPaidAmountCash] = useState(0);
  const [bankName, setBankName] = useState("");
  const [iban, setIban] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [chequeType, setChequeType] = useState("cash");

  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getPurchaseOrderURL = baseURL + "/purchaseorders/" + params.id;
    const getPurchaseOrderResponse = await fetch(getPurchaseOrderURL, {
      method: "GET",
      headers: headers
    });
    const getPurchaseOrderJSONData = await getPurchaseOrderResponse.json();
    if (!getPurchaseOrderResponse.ok) {
      toast.error(getPurchaseOrderJSONData.data.message);
      setMessage(getPurchaseOrderJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const purchaseOrder = getPurchaseOrderJSONData.data.doc;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    setEmployees(employees);
    setPurchaseOrder(purchaseOrder);
    setIsLoading(false)
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/paymentvouchers/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    console.log(JSON.stringify(payload))
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleNewPaymentVoucherForm(event) {
    event.preventDefault();
    let errors = [];
    let newPaymentVoucherForm = event.target;
    let payload = {}
    payload.purchaseOrderID = params.id;
    payload.vendorID = purchaseOrder.vendorID._id;
    payload.projectID = purchaseOrder.quotationID.projectID._id;

    setIsSubmitting(true);

    let paymentVoucherCreator = newPaymentVoucherForm.elements.namedItem("paymentVoucherCreator");
    if (paymentVoucherCreator) {
      if (!paymentVoucherCreator.value) {
        errors.push("Payment Voucher creator is required");
      }
      else {
        payload.paymentVoucherCreator = paymentVoucherCreator.value;
      }
    }

    if (paymentMode === 0) {
      payload.paidAmountOnlineTransfer = paidAmountOnlineTransfer;
      payload.paymentMode = paymentMode;
      payload.iban = iban;
      payload.bankName = bankName;
      payload.accountTitle = accountTitle;
    }
    else if (paymentMode === 1) {
      payload.paidAmountCheque = paidAmountCheque;
      payload.paymentMode = paymentMode;
      payload.chequeNumber = chequeNumber;
      payload.chequeType = chequeType;
    }
    else if (paymentMode === 2) {
      payload.paidAmountCash = paidAmountCash;
      payload.paymentMode = paymentMode;
    }
    else {
      errors.push("Please select a payment mode first.");
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsDisabled(true);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-bold'>Create a new payment voucher</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];
  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  console.log("Before PaymentVouchersNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-2'>

        <div className='flex flex-row justify-between items-center'>
          <h2 className='text-2xl text-primary font-bold'>Create a new payment voucher</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div>
          <Link className="underline" to={"/purchaseorders/edit/" + purchaseOrder._id}>Purchase Order ID: {purchaseOrder.purchaseOrderID}</Link>
        </div>
        <div>Project Name: {purchaseOrder.quotationID.projectID.projectName}</div>
        <div>Vendor Company Name: {purchaseOrder.vendorID.vendorCompanyName}</div>
        <div>Purchase Order Creator: {purchaseOrder.purchaseOrderCreator.full_name}</div>

        <form id="newPaymentVoucherForm" onSubmit={handleNewPaymentVoucherForm} className='space-y-2'>

          {
            (
              userData.doc.isSuperAdmin ||
              userData.doc.isAdmin
            )
            && (
              <div className="flex flex-col md:flex-row gap-2">
                <div>Payment Voucher Creator:</div>
                <select name="paymentVoucherCreator" className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'>
                  <option value="">Select an employee</option>
                  {employeesList}
                </select>
              </div>
            )
          }

          <div>{`Purchase Order Gross Amount: Rs. ${purchaseOrder.purchaseOrderGrossAmount}`}</div>

          <div className="flex flex-col md:flex-row gap-2">
            <div>Mode of payment:</div>
            <select
              name="paymentMode"
              onChange={(e) => { setPaymentMode(parseInt(e.target.value)) }}
              className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
            >
              <option value="">Select an option</option>
              <option value="0">Online Bank Transfer</option>
              <option value="1">Cheque</option>
              <option value="2">Cash</option>
            </select>
          </div>

          {
            paymentMode === 0 && (
              <div className="flex flex-col md:flex-row md:flex-wrap gap-y-2">
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    Amount (Rs.):
                  </div>
                  <div>
                    <input
                      type="number"
                      name="paidAmount"
                      min="0"
                      value={paidAmountOnlineTransfer}
                      onChange={(e) => {
                        let enteredValue = parseInt(e.target.value)
                        if (isNaN(enteredValue))
                          setPaidAmountOnlineTransfer(0);
                        else
                          setPaidAmountOnlineTransfer(enteredValue);
                      } }
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    Bank Name:
                  </div>
                  <div>
                    <input
                      type="text"
                      name="bankName"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                      placeholder="Bank Alfalah"
                    />
                  </div>
                </div>
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    IBAN:
                  </div>
                  <div>
                    <input
                      type="text"
                      name="iban"
                      value={iban}
                      onChange={(e) => setIban(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                      placeholder="PK08HABB001204"
                    />
                  </div>
                </div>
                <div className="flex md:items-center flex-col md:flex-row md:basis-1/3 gap-2">
                  <div>
                    Account Title:
                  </div>
                  <div>
                    <input
                      type="text"
                      name="accountTitle"
                      value={accountTitle}
                      onChange={(e) => setAccountTitle(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>
              </div>
            )
          }

          {
            paymentMode === 1 && (
              <div className="flex flex-col md:flex-row gap-2">

                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Amount (Rs.):
                  </div>
                  <div>
                    <input
                      type="number"
                      name="paidAmount"
                      min="0"
                      value={paidAmountCheque}
                      onChange={(e) => {
                        let enteredValue = parseInt(e.target.value)
                        if (isNaN(enteredValue))
                          setPaidAmountCheque(0);
                        else
                          setPaidAmountCheque(enteredValue);
                      } }
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>

                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Cheque Number:
                  </div>
                  <div>
                    <input
                      type="text"
                      name="chequeNumber"
                      value={chequeNumber}
                      onChange={(e) => setChequeNumber(e.target.value)}
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>

                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Cheque Type:
                  </div>
                  <select
                    name="chequeType"
                    value={chequeType}
                    onChange={(e) => setChequeType(e.target.value)}
                    className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                  >
                    <option value="cash">Cash</option>
                    <option value="cross">Cross</option>
                  </select>
                </div>
              </div>
            )
          }
          {
            paymentMode === 2 && (
              <div className="flex flex-col md:flex-row gap-2">
                <div className="flex md:items-center flex-col md:flex-row gap-2">
                  <div>
                    Amount (Rs.):
                  </div>
                  <div>
                    <input
                      type="number"
                      name="paidAmount"
                      min="0"
                      value={paidAmountCash}
                      onChange={(e) => {
                        let enteredValue = parseInt(e.target.value)
                        if (isNaN(enteredValue))
                          setPaidAmountCash(0);
                        else
                          setPaidAmountCash(enteredValue);
                      } }
                      className='border border-[#8E8EA1] py-1 rounded-md px-2 outline-none text-sm'
                    />
                  </div>
                </div>
              </div>
            )
          }

          <div className='flex flex-row'>
            <button
              disabled={isSubmitting || isDisabled}
              type="submit"
              className='text-white bg-primary rounded-[40px] w-[299px] h-9 mt-6'
            >
              {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
            </button>
          </div>

        </form>
      </div>
    </Layout>
  );
}
