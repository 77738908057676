import "./styles.css";
import { useContext, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDrawerContext } from "context/DrawerContext";
import useClickOutside from "utils/clickOutside";
import { AuthContext } from "context/AuthContext";
import logo from "assets/logo.png";

const Sidebar = () => {
  const sidebarRef = useRef();
  const location = useLocation();
  /*const [showCatDrop, setShowCatDrop] = useState(false);
  const [showSettingsDrop, setShowSettingsDrop] = useState(false);
  const [showReportDropMenu, setShowReportDropMenu] = useState(false);*/
  const { showDrawer, setShowDrawer } = useDrawerContext();
  const { userData, removeUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  useClickOutside(sidebarRef, () => setShowDrawer(false));

  function handleLogOutButton(event) {
    removeUserData();
    navigate("/dashboard");
  }

  const isActive = (path, home) => { //not needed as NavLink component does the same thing
    if (home) {
      return (location.pathname === "/" || location.pathname === "/dashboard");
    }
    else {
      return location.pathname.split("/").includes(path);
    }
  };

  console.log("Before Sidebar() rendering");
  console.log(userData.doc);
  console.log(userData.role);

  return (
    <aside>
      {showDrawer && (
        <div className="fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-10"></div>
      )}
      <div
        className={`sidebar ${showDrawer ? "show" : ""
          } fixed top-0 md:left-0 -left-[200%] w-[250px] min-h-screen h-full overflow-auto py-4 px-6 z-50 border-r bg-pure`}
        ref={sidebarRef}
      >
        <div className="overflow-auto pb-8">
          <NavLink to="/" className="text-3xl font-bold text-primary">
            <img className="h-10" src={logo} alt="technoproperties" />
          </NavLink>
          <ul className="sideMenu-list mt-6 text-dark min-h-screen h-full">
            <li>
              <NavLink
                to="/dashboard"
                className="sideMenu-item"
              >
                <i className="uil uil-home"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {/* HR Section */}

            <li>
              <p className="sideMenu-heading">HR Management</p>
              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin ||
                  userData.role.create.includes("employees")
                ) && (
                  <p className="sideMenu-heading">Employee Management</p>
                )
              }
              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin ||
                  userData.role.create.includes("employees")
                ) && (
                  <NavLink
                    to="/employees"
                    className="sideMenu-item"
                  >
                    <i className="uil uil-shopping-cart"></i>
                    <span>All Employees</span>
                  </NavLink>
                )
              }
            </li>

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.create.includes("employees")
              ) && (
                <li>
                  <NavLink to="/employees-salaries" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Employees Salaries</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("employeeincrements")
              ) && (
                <li>
                  <NavLink to="/employees-increments" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Employees Increments</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("employeeleaves")
              ) && (
                <li>
                  <NavLink to="/employees-leaves" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Employees Leaves</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("employeebonuses")
              ) && (
                <li>
                  <NavLink to="/employees-bonuses" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Employees Bonuses</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("employeecomplaints")
              ) && (
                <li>
                  <NavLink to="/employees-complaints" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Employees Complaints</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("employeeattendances")
              ) && (
                <li>
                  <NavLink to="/employees-attendances" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Employees Attendances</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("leaverules")
              ) && (
                <li>
                  <NavLink to="/leave-rules" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Leave Rules</span>
                  </NavLink>
                </li>
              )
            }

            {(userData.doc.isSuperAdmin || userData.doc.isAdmin) && (
              <li className="space-y-4">
                <p className="sideMenu-heading">Company Management</p>
                <NavLink
                  to="/companies"
                  className="sideMenu-item"
                >
                  <i className="uil uil-shopping-cart"></i>
                  <span>All Companies</span>
                </NavLink>
                <NavLink
                  to="/departments"
                  className="sideMenu-item"
                >
                  <i className="uil uil-shopping-cart"></i>
                  <span>All Departments</span>
                </NavLink>
              </li>
            )}

            {/* Tasks Section */}

            {
              (
                userData.role?.read.includes("mytasks") ||
                userData.role?.read.includes("myleaves") ||
                userData.role?.read.includes("mycomplaints")
              )
              && (
                <li className="space-y-4">
                  <p className="sideMenu-heading">My Documents</p>
                  <NavLink to="/mytasks" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>My Tasks</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.role?.read.includes("myleaves")
              )
              && (
                <li className="space-y-4">
                  <NavLink to="/my-leaves" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>My Leaves</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.role?.read.includes("mycomplaints")
              )
              && (
                <li className="space-y-4">
                  <NavLink to="/my-complaints" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>My Complaints</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.role?.read.includes("mycomplaints")
              )
              && (
                <li className="space-y-4">
                  <NavLink to="/my-attendance" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>My Attendance</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                (userData.role.read.includes("employeetasks") && userData.role.create.includes("employeetasks"))
              )
              && (
                <li className="space-y-4">
                  <p className="sideMenu-heading">Task Management</p>
                  <NavLink to="/employeestasks" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Employees' Tasks</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("events")
              )
              && (
                <li className="space-y-4">
                  <NavLink to="/events" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Meetings / Events</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("events")
              )
              && (
                <li className="space-y-4">
                  <NavLink to="/calendar" className="sideMenu-item">
                    <i className="uil uil-shopping-cart"></i>
                    <span>Calendar</span>
                  </NavLink>
                </li>
              )
            }

            {/* Stock Management Section */}

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("stockitems") ||
                userData.role.read.includes("materialissues")
              ) &&
              <li className="space-y-4">
                <p className="sideMenu-heading">Stock Management</p>
                {
                  (
                    userData.doc.isSuperAdmin ||
                    userData.doc.isAdmin ||
                    userData.role.read.includes("stockitems")
                  ) &&
                  <NavLink to="/stockitems" className={`sideMenu-item`}>
                    <i className="uil uil-chart-pie-alt"></i>
                    <span>Stock Items</span>
                  </NavLink>
                }
              </li>
            }
            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("materialissues")
              ) &&
              <li>
                <NavLink to="/materialissues" className="sideMenu-item">
                  <i className="uil uil-chart-pie-alt"></i>
                  <span>Material Issue Slips</span>
                </NavLink>
              </li>
            }
            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("materialreceiptslips")
              ) &&
              <li>
                <NavLink to="/materialreceiptslips" className="sideMenu-item">
                  <i className="uil uil-chart-pie-alt"></i>
                  <span>Material Receipt Slips</span>
                </NavLink>
              </li>
            }
            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("stockcategory")
              ) &&
              <li>
                <NavLink to="/stockcategories" className="sideMenu-item">
                  <i className="uil uil-chart-pie-alt"></i>
                  <span>Stock Categories</span>
                </NavLink>
              </li>
            }

            {/* Project Section */}
            {(userData.doc.isSuperAdmin ||
              userData.doc.isAdmin ||
              userData.role.read.includes("projects") ||
              userData.role.read.includes("projectcategories")
            )
              && <li className="space-y-4">
                <p className="sideMenu-heading">Project Management</p>
                {(userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin ||
                  userData.role.read.includes("projects")
                ) && <NavLink
                  to="/projects"
                  className="sideMenu-item"
                >
                    <i className="uil uil-chart-pie-alt"></i>
                    <span>Projects</span>
                  </NavLink>
                }
                {(userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin ||
                  userData.role.read.includes("projectcategories")
                ) && <NavLink
                  to="/projectscategories"
                  className="sideMenu-item"
                >
                    <i className="uil uil-chart-pie-alt"></i>
                    <span>Projects' Categories</span>
                  </NavLink>}
              </li>}

            {(userData.doc.isSuperAdmin ||
              userData.doc.isAdmin ||
              userData.role.read.includes("demands")
            )
              && <li>
                <NavLink
                  to="/demands"
                  className="sideMenu-item"
                >
                  <i className="uil uil-diamond"></i>
                  <span>All Demands</span>
                </NavLink>
              </li>}
            {(userData.doc.isSuperAdmin ||
              userData.doc.isAdmin ||
              userData.role.read.includes("quotations")
            )
              && <li>
                <NavLink
                  to="/quotations"
                  className="sideMenu-item"
                >
                  <i className="uil uil-scenery"></i>
                  <span>All Quotations</span>
                </NavLink>
              </li>}
            {(userData.doc.isSuperAdmin ||
              userData.doc.isAdmin ||
              userData.role.read.includes("purchaseorders")
            )
              && <li>
                <NavLink
                  to="/purchaseorders"
                  className="sideMenu-item"
                >
                  <i className="uil uil-file-exclamation-alt"></i>
                  <span>Purchase Orders</span>
                </NavLink>
              </li>}
            {(userData.doc.isSuperAdmin ||
              userData.doc.isAdmin ||
              userData.role.read.includes("paymentvouchers")
            )
              && <li>
                <NavLink
                  to="/paymentvouchers"
                  className="sideMenu-item"
                >
                  <i className="uil uil-file-exclamation-alt"></i>
                  <span>Payment Vouchers</span>
                </NavLink>
              </li>}
            {(userData.doc.isSuperAdmin ||
              userData.doc.isAdmin ||
              userData.role.read.includes("vendors")

            )
              && <li>
                <NavLink
                  to="/vendors"
                  className="sideMenu-item"
                >
                  <i className="uil uil-envelopes"></i>
                  <span>Vendors</span>
                </NavLink>

              </li>
            }

            {/* Reports Section */}

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("employees")
              )
              && (
                <li>
                  <div className="sideMenu-heading">Reports</div>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("employees")
              )
              && (
                <li>
                  <NavLink
                    to="/single-employee-report"
                    className="-mt-4 sideMenu-item"
                  >
                    <i className="uil uil-envelopes"></i>
                    <span>Employee</span>
                  </NavLink>
                </li>
              )
            }

            {/* Setting Section */}

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("workinghours")
              )
              && (
                <li>
                  <div className="sideMenu-heading">Settings</div>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin ||
                userData.role.read.includes("workinghours")
              )
              && (
                <li>
                  <NavLink
                    to="/working-hours"
                    className="-mt-4 sideMenu-item"
                  >
                    <i className="uil uil-envelopes"></i>
                    <span>Office Timings</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin
              )
              && (
                <li>
                  <NavLink
                    to="/roles"
                    className="sideMenu-item"
                  >
                    <i className="uil uil-envelopes"></i>
                    <span>Roles</span>
                  </NavLink>
                </li>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin
              )
              && (
                <li>
                  <NavLink
                    to="/admins"
                    className="sideMenu-item"
                  >
                    <i className="uil uil-envelopes"></i>
                    <span>All Admins</span>
                  </NavLink>
                </li>
              )
            }

            <div>
              <button className="text-primary font-bold" onClick={handleLogOutButton}>Log Out</button>
            </div>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
