import React, { useState, useContext } from 'react';
import { baseURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { toast } from 'react-toastify';
import SelectBox from 'components/global/SelectBox.jsx';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';

export const TaskTabEdit = ({ task, setTask, initialEmployees, params }) => {

  let peopleIDs = []

  for (let obj of task.employeeIDs) {
    peopleIDs.push(obj._id);
  }

  const [title, setTitle] = useState(task.taskTitle);
  const [taskStartByDate, setTaskStartByDate] = useState(task.taskStartByDate.split('T')[0]);
  const [taskFinishByDate, setTaskFinishByDate] = useState(task.taskFinishByDate.split('T')[0]);
  const [taskActualFinishDate, setTaskActualFinishDate] = useState(task.taskActualFinishDate?.split('T')[0]);
  const [employeeIDs, setEmployeeIDs] = useState(peopleIDs);
  const [supervisor, setSupervisor] = useState(task.supervisor._id);
  const [description, setDescription] = useState(task.taskDescription);
  const [status, setStatus] = useState(parseInt(task.taskStatus));
  const [priority, setPriority] = useState(parseInt(task.taskPriority));

  const employees = initialEmployees;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeestasks/update-details/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setTask(jsonData.data.doc)
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditTaskForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!title) {
      errors.push(<div>Title can't be empty</div>)
    }

    if (!taskStartByDate) {
      errors.push(<div>Task start date can't be empty</div>)
    }

    if (!taskFinishByDate) {
      errors.push(<div>Task finish date can't be empty</div>)
    }

    if (employeeIDs.length < 1) {
      errors.push(<div>Task should be assigned to atleast one person</div>)
    }

    if (!supervisor) {
      errors.push(<div>Supervisor can't be empty</div>)
    }

    let payload = {
      employeeIDs,
      supervisor,
      taskTitle: title,
      taskDescription: description,
      taskStartByDate,
      taskFinishByDate,
      taskActualFinishDate,
      taskStatus: status,
      taskPriority: priority,
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsDisabled(false);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  console.log("Before TaskTabEdit() rendering");

  return (
    <div className='space-y-3 text-sm'>
      <div className="flex flex-col md:flex-row md:flex-wrap gap-y-2 bg-darkSlate p-4 mb-4 rounded">

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Title <span className="required">*</span>
            </label>
          </div>
          <div>
            <input
              type="text"
              name="taskTitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className='border border-[#8E8EA1] rounded w-full md:w-11/12 py-1 px-2 outline-none'
            />
          </div>
        </div>

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Start Date <span className="required">*</span>
            </label>
          </div>
          <div>
            <input
              type="date"
              name="taskStartByDate"
              value={taskStartByDate}
              onChange={(e) => setTaskStartByDate(e.target.value)}
              className='border border-[#8E8EA1] rounded w-full md:w-11/12 py-1 px-2 outline-none'
            />
          </div>
        </div>

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Approx. Finish Date <span className="required">*</span>
            </label>
          </div>
          <div>
            <input
              type="date"
              name="taskFinishByDate"
              value={taskFinishByDate}
              onChange={(e) => setTaskFinishByDate(e.target.value)}
              className='border border-[#8E8EA1] rounded w-full md:w-11/12 py-1 px-2 outline-none'
            />
          </div>
        </div>

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Actual Finish Date <span className="required">*</span>
            </label>
          </div>
          <div>
            <input
              type="date"
              name="taskActualFinishDate"
              value={taskActualFinishDate}
              onChange={(e) => setTaskActualFinishDate(e.target.value)}
              className='border border-[#8E8EA1] rounded w-full md:w-11/12 py-1 px-2 outline-none'
            />
          </div>
        </div>

      </div>

      <div className="flex flex-col md:flex-row md:flex-wrap gap-y-2 bg-darkSlate p-4 mb-4 rounded">

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Employees <span className="required">*</span>
            </label>
          </div>
          <div className='w-full md:w-11/12'>
            <Select
              isMulti
              name="employeeIDs"
              onChange={(selectedOptions) => {
                let employeesList = [];
                if (selectedOptions && selectedOptions.length > 0) {
                  employeesList = selectedOptions.map((option) => {
                    return option.value; //an employee ObjectId
                  });
                }
                setEmployeeIDs(employeesList);
              }}
              defaultValue={
                employees.map((employee, index) => {
                  return {
                    value: employee._id,
                    label: `${employee.full_name} ${employee.custom_id}`
                  }
                }).filter(option => {
                  if (employeeIDs.includes(option.value))
                    return true;
                  else
                    return false;
                })
              }
              options={employees && employees.length > 0 && employees.map((employee, index) => {
                return {
                  value: employee._id,
                  label: `${employee.full_name} ${employee.custom_id}`
                }
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#8E8EA1',
                }),
              }}
            />
          </div>
        </div>

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Supervisor <span className="required">*</span>
            </label>
          </div>
          <div className='w-full md:w-11/12'>
            <SelectBox
              name="supervisor"
              handleChange={(selectedOption) => setSupervisor(selectedOption.value)}
              defaultValue={supervisor}
              options={employees && employees.length > 0 && employees.map((employee, index) => {
                return {
                  value: employee._id,
                  label: `${employee.full_name} ${employee.custom_id}`
                }
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#8E8EA1',
                }),
              }}
            />
          </div>
        </div>

      </div>

      <div className="flex flex-col md:flex-row md:flex-wrap gap-y-2 bg-darkSlate p-4 mb-4 rounded">

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Status <span className="required">*</span>
            </label>
          </div>
          <div className='w-full md:w-11/12'>
            <SelectBox
              name="taskStatus"
              handleChange={(selectedOption) => setStatus(selectedOption.value)}
              defaultValue={status}
              options=
              {
                [
                  { val: 0, lab: "Pending" },
                  { val: 1, lab: "Finished" }
                ].map(
                  (element, index) => {
                    return {
                      value: element.val,
                      label: element.lab
                    }
                  }
                )
              }
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#8E8EA1',
                }),
              }}
            />
          </div>
        </div>

        <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
          <div>
            <label
              className='font-normal text-left'
            >
              Priority <span className="required">*</span>
            </label>
          </div>
          <div className='w-full md:w-11/12'>
            <SelectBox
              name="taskPriority"
              handleChange={(selectedOption) => setPriority(selectedOption.value)}
              defaultValue={priority}
              options=
              {
                [
                  { val: 0, lab: "Low" },
                  { val: 1, lab: "Normal" },
                  { val: 2, lab: "High" },
                  { val: 3, lab: "Urgent" }
                ].map(
                  (element, index) => {
                    return {
                      value: element.val,
                      label: element.lab
                    }
                  }
                )
              }
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#8E8EA1',
                }),
              }}
            />
          </div>
        </div>

      </div>

      <div>

        <div className='flex flex-col gap-y-1 bg-darkSlate p-4 mb-4 rounded'>
          <div>
            <label
              className='font-normal text-left'
            >
              Description
            </label>
          </div>
          <div className='w-full'>
            <textarea
              name="taskDescription"
              rows="4"
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value) ? setDescription(e.target.value) : ""}
              className='border border-[#8E8EA1] w-full py-1 rounded px-2 outline-none'
            >
            </textarea>
          </div>
        </div>

      </div>

      <div className='flex flex-row my-5'>
        <button
          disabled={isSubmitting || isDisabled}
          onClick={handleEditTaskForm}
          className='bg-primary py-1 px-4 text-white border rounded shadow-md'
        >
          {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Update"}
        </button>
      </div>

    </div>
  );
}