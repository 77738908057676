import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './vendorsView.css';
import { Link, useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';

export const VendorsView = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [vendorOverview, setVendorOverview] = useState(null);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let getVendorURL = baseURL + "/vendors/overview/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getVendorResponse = await fetch(getVendorURL, {
      method: "GET",
      headers: headers
    });
    const getVendorJSONData = await getVendorResponse.json();
    if (!getVendorResponse.ok) {
      toast.error(getVendorJSONData.data.message);
      setMessage(getVendorJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const vendor = getVendorJSONData.data.doc;

    vendor["purchaseReceivedReport"] = new Map(Object.entries(vendor["purchaseReceivedReport"]));

    setVendorOverview(vendor);
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  console.log("Before VendorsView() rendering");

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-bold'>Vendor</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let reportRows = [];

  if (vendorOverview.purchaseReceivedReport && vendorOverview.purchaseReceivedReport.size > 0) {
    for (let item of vendorOverview.purchaseReceivedReport) {
      reportRows.push(
        <tr key={item[0]}>
          <td>{item[1].name}</td>
          <td>{item[1].orderedQuantity}</td>
          <td>{item[1].receivedQuantity}</td>
          <td>{item[1].orderedQuantity - item[1].receivedQuantity}</td>
        </tr>
      );
    }
  }

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Vendor</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div id="vendorOverview" className='overflow-x-auto rounded-lg border border-[#6843BE] p-4 text-sm space-y-5'>

          <div className='flex flex-col md:flex-row md:flex-wrap gap-y-2'>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Company Name:</span> {vendorOverview.vendor.vendorCompanyName}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Email:</span> {vendorOverview.vendor.vendorEmail}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Address:</span> {vendorOverview.vendor.vendorAddress}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Phone:</span> {vendorOverview.vendor.vendorPhone}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Total Purchased:</span> {vendorOverview.vendor.totalPurchased}</div>
            </div>

            <div className='md:basis-1/2 lg:basis-1/3'>
              <div><span className='font-semibold'>Total Paid:</span> {vendorOverview.vendor.totalPaid}</div>
            </div>

          </div>

          <div className='flex flex-col gap-y-5'>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Quotation ID</th>
                      <th>Project</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      vendorOverview.quotations && vendorOverview.quotations.length > 0 ? (
                        vendorOverview.quotations.map((quotation) => {
                          let editLink = '/quotations/edit/' + quotation._id;
                          return <tr key={quotation._id}>
                            <td>
                              {quotation.quotationID}
                            </td>
                            <td>
                              {quotation.projectID.projectName}
                            </td>
                            <td>
                              {
                                quotation.selectedForPurchaseOrder ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {quotation.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No quotations found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Purchase Order ID</th>
                      <th>Gross Amount</th>
                      <th>Project</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      vendorOverview.purchaseOrders && vendorOverview.purchaseOrders.length > 0 ? (
                        vendorOverview.purchaseOrders.map((purchaseOrder) => {
                          let editLink = '/purchaseorders/edit/' + purchaseOrder._id;
                          return <tr key={purchaseOrder._id}>
                            <td>
                              {purchaseOrder.purchaseOrderID}
                            </td>
                            <td>
                              {purchaseOrder.purchaseOrderGrossAmount}
                            </td>
                            <td>
                              {purchaseOrder.projectID.projectName}
                            </td>
                            <td>
                              {
                                purchaseOrder.selectedForPaymentVoucher ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {purchaseOrder.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No purchase orders found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Payment Voucher ID</th>
                      <th>Payment Mode</th>
                      <th>Paid Amount</th>
                      <th>Project</th>
                      <th>Purchase Order</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      vendorOverview.paymentVouchers && vendorOverview.paymentVouchers.length > 0 ? (
                        vendorOverview.paymentVouchers.map((paymentVoucher) => {
                          let paymentMode;
                          let paidAmount;
                          if (paymentVoucher.paymentMode === 0) {
                            paymentMode = "Online Bank Transfer";
                            paidAmount = paymentVoucher.paidAmountOnlineTransfer;
                          }
                          else if (paymentVoucher.paymentMode === 1) {
                            paymentMode = "Cheque";
                            paidAmount = paymentVoucher.paidAmountCheque;
                          }
                          else if (paymentVoucher.paymentMode === 2) {
                            paymentMode = "Cash";
                            paidAmount = paymentVoucher.paidAmountCash;
                          }
                          let editLink = '/paymentvouchers/edit/' + paymentVoucher._id;
                          return <tr key={paymentVoucher._id}>
                            <td>
                              {paymentVoucher.paymentVoucherID}
                            </td>
                            <td>
                              {paymentMode}
                            </td>
                            <td>
                              {paidAmount}
                            </td>
                            <td>
                              {paymentVoucher.projectID.projectName}
                            </td>
                            <td>
                              <Link to={`/purchaseorders/edit/${paymentVoucher.purchaseOrderID._id}`}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                            <td>
                              {
                                paymentVoucher.selectedForMaterialReceiptSlip ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {paymentVoucher.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No payment vouchers found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Material Receipt Slip ID</th>
                      <th>Project</th>
                      <th>Purchase Order</th>
                      <th>Approval Status</th>
                      <th>Creation Date</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      vendorOverview.materialReceiptSlips && vendorOverview.materialReceiptSlips.length > 0 ? (
                        vendorOverview.materialReceiptSlips.map((materialReceiptSlip) => {
                          let editLink = '/materialreceiptslips/edit/' + materialReceiptSlip._id;
                          return <tr key={materialReceiptSlip._id}>
                            <td>
                              {materialReceiptSlip.materialReceiptSlipID}
                            </td>
                            <td>
                              {materialReceiptSlip.projectID.projectName}
                            </td>
                            <td>
                              <Link to={`/purchaseorders/edit/${materialReceiptSlip.purchaseOrderID._id}`}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                            <td>
                              {
                                materialReceiptSlip.selectedForFuture ? "Accepted" : "Not yet approved or rejected"
                              }
                            </td>
                            <td>
                              {materialReceiptSlip.createdAt.split('T')[0]}
                            </td>
                            <td>
                              <Link to={editLink}>
                                <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
                              </Link>
                            </td>
                          </tr>
                        })) : (
                        <tr>
                          <td>No material receipt slips found!</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              Across all projects:
            </div>

            <div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Stock Item</th>
                      <th>Ordered Quantity</th>
                      <th>Received Quantity</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportRows}
                  </tbody>
                </table>
              </div>
            </div>

          </div>

        </div>
      </div>
    </Layout>
  );
}
