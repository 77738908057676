import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './employeesIncrementsNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Loader from 'components/global/Loader';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';
import Select from 'react-select';
import DatePicker from "react-datepicker";

export const EmployeesIncrementsNew = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);

  const [compensationType, setCompensationType] = useState("");

  const [employee, setEmployee] = useState("");
  const [incrementType, setIncrementType] = useState("");
  const [incrementAmount, setIncrementAmount] = useState(0);

  const [bonusAwardees, setBonusAwardees] = useState(null);
  const [bonusType, setBonusType] = useState("");
  const [bonusMonth, setBonusMonth] = useState(new Date());
  const [bonusAmount, setBonusAmount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url;
    if (compensationType === "increment")
      url = baseURL + "/employeeincrements/create";
    else if (compensationType === "bonus")
      url = baseURL + "/employeesbonuses/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateEmployeeIncrementForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    let payload;

    if (compensationType === "increment") {
      if (!employee) {
        errors.push("Please select an employee.");
      }

      if (!incrementType) {
        errors.push("Please select an increment type.");
      }

      if (incrementAmount < 1) {
        errors.push("Increment amount should be greater than zero.");
      }

      payload = {
        employeeID: employee,
        incrementType,
        incrementAmount,
      };
    }
    else if (compensationType === "bonus") {
      if (!(bonusAwardees && bonusAwardees.length > 0)) {
        errors.push("Please select at least one bonus awardee.")
      }
      if (!bonusType) {
        errors.push("Please select a bonus type.");
      }

      if (!bonusMonth) {
        errors.push("Bonus month is required.");
      }

      if (bonusAmount < 1) {
        errors.push("Bonus amount should be greater than zero.");
      }
      payload = {
        employeeIDs: bonusAwardees,
        bonusType,
        bonusAmount,
        bonusMonth,
      };
    }
    else {
      errors.push("Please select compensation type first");
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(' ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create Employee Increment</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before EmployeesIncrementsNew() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create Employee Increment</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form id="createEmployeeIncrementForm" onSubmit={handleCreateEmployeeIncrementForm}>
          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>
              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Increment Type *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="compensationType"
                    options={
                      [
                        { value: 'increment', label: 'Increment' },
                        { value: 'bonus', label: 'Bonus' },
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setCompensationType(selectedOption.value)}
                  />
                </div>
              </div>
            </div>

            {
              compensationType === "increment" ? (
                <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

                  <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                    <div>
                      <label className='text-[#22242C] font-normal'>
                        Employee *
                      </label>
                    </div>
                    <div className='w-full md:w-11/12'>
                      <SelectBox
                        name="employee"
                        options={
                          employees && employees.length !== 0 && employees.map((employee, index) => {
                            return {
                              value: employee._id,
                              label: `${employee.full_name} ${employee.custom_id}`
                            }
                          })
                        }
                        handleChange={(selectedOption) => setEmployee(selectedOption.value)}
                      />
                    </div>
                  </div>

                  <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                    <div>
                      <label className='text-[#22242C] font-normal'>
                        Increment Type *
                      </label>
                    </div>
                    <div className='w-full md:w-11/12'>
                      <SelectBox
                        name="incrementType"
                        options={
                          [
                            { value: 'costOfLiving', label: 'Cost of Living' },
                            { value: 'performance', label: 'Performance' },
                            { value: 'promotion', label: 'Promotion' }
                          ].map((type) => {
                            return {
                              value: type.value,
                              label: type.label
                            }
                          })
                        }
                        handleChange={(selectedOption) => setIncrementType(selectedOption.value)}
                      />
                    </div>
                  </div>

                  <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                    <div>
                      <label className='text-[#22242C] font-normal'>Increment Amount *</label>
                    </div>
                    <div>
                      <input
                        type="number"
                        min="0"
                        name="incrementAmount"
                        value={incrementAmount}
                        onChange={(e) => {
                          let enteredValue = parseInt(e.target.value)
                          if (isNaN(enteredValue))
                            setIncrementAmount(0);
                          else
                            setIncrementAmount(enteredValue);
                        }}
                        className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                      />
                    </div>
                  </div>

                </div>
              ) : (
                null
              )
            }

            {
              compensationType === "bonus" ? (
                <React.Fragment>
                  <div className='text-sm'>
                    If more than one employee is selected, then bonus amount will be considered for each employee. For example, if Bonus Amount is 4000 then both employee A and employee B will be awarded 4000.
                  </div>

                  <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='text-[#22242C] font-normal'>
                          Employees *
                        </label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <Select
                          isMulti
                          name="bonusAwardees"
                          onChange={(selectedOptions) => {
                            let employeesList = [];
                            if (selectedOptions && selectedOptions.length > 0) {
                              employeesList = selectedOptions.map((option) => {
                                return option.value; //an employee ObjectId
                              });
                            }
                            setBonusAwardees(employeesList);
                          }}
                          options={
                            employees && employees.length !== 0 && employees.map((employee, index) => {
                              return {
                                value: employee._id,
                                label: `${employee.full_name} ${employee.custom_id}`
                              }
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='text-[#22242C] font-normal'>
                          Bonus Type *
                        </label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <SelectBox
                          name="bonusType"
                          options={
                            [
                              { value: 'yearEnd', label: 'Year end' },
                              { value: 'eidAlFitr', label: 'Eid-al-Fitr' },
                              { value: 'eidAlAdha', label: 'Eid-al-Adha' },
                              { value: 'other', label: 'Other' },
                            ].map((type) => {
                              return {
                                value: type.value,
                                label: type.label
                              }
                            })
                          }
                          handleChange={(selectedOption) => setBonusType(selectedOption.value)}
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>Bonus for Month *</label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={bonusMonth}
                          onChange={(date) => setBonusMonth(date)}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          className='border border-[#8E8EA1] py-2 rounded-md px-3 outline-none'
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='text-[#22242C] font-normal'>Bonus Amount *</label>
                      </div>
                      <div>
                        <input
                          type="number"
                          min="0"
                          name="bonusAmount"
                          value={bonusAmount}
                          onChange={(e) => {
                            let enteredValue = parseInt(e.target.value)
                            if (isNaN(enteredValue))
                              setBonusAmount(0);
                            else
                              setBonusAmount(enteredValue);
                          }}
                          className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                        />
                      </div>
                    </div>

                  </div>
                </React.Fragment>
              ) : (
                null
              )
            }

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting || isDisabled} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div >
    </Layout >
  );
}