import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link,NavLink } from 'react-router-dom';
import styles from './projectsCategories.module.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from 'components/global/Loader';


export const StockCategories = () => {
  const [message, setMessage] = useState(null);
  const [stocksCategories, setstocksCategories] = useState([]);
  const [loading,setLoading]=useState(false);
  const { userData } = useContext(AuthContext);

  
  async function fetchData() {
    setLoading(true);
    let url = baseURL + "/stockcategories/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    setLoading(false);
    const stocksCategories = jsonData.data.docs;
    setstocksCategories(stocksCategories);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // if (stocksCategories.length === 0) { //on first render
  //   return (
  //     <Layout>
  //     <div className={styles["stocksCategories-list"]}>
  //       <h2>stocksCategories</h2>
  //       {message && message}
  //       <div>Fetching data from the server. Please wait!</div>
  //     </div>
  //     </Layout>
      
  //   );
  // }

  let stocksCategoriesList = [];
  
  if( stocksCategories.length !== 0 ) {
    stocksCategoriesList = stocksCategories.map( (projectCategory, index) => {
      let editLink = "/stockcategories/edit/" + projectCategory._id;
      return (
        <tr key={projectCategory._id}>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{projectCategory.stockCategoryName}</td>
          <td className="  px-7 py-4 whitespace-nowrap"><Link to={editLink}><CiEdit className='text-2xl text-primary'/></Link></td>
          <td className=" text-primary px-9 py-4 whitespace-nowrap ">
          <button onClick={handleDelete}><RiDeleteBin6Line className='text-lg text-[#E30411]'/></button>
          </td>
        </tr>
      );
    });
  }

  console.log("Before stocksCategories() rendering");

  return (
    <Layout>
        
     <div className='space-y-4'>
     <div className='flex flex-row justify-between items-center px-3 '>
    <h2 className='text-2xl text-primary font-bold	'>Stocks' Categories</h2>
    <NavLink to="/stockcategories/new">
    <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
     <span><FaPlus/></span>   <span>Add Stock Categories</span>
    </button>
    </NavLink>
    </div>
    <div className='flex flex-row justify-between'>
       <div>
        {message && message}
       </div>
     </div>
{loading ? <Loader/>:
<div className='overflow-x-auto  rounded-lg  border border-[#6843BE] ' >

<table  className="w-full table-auto overflow-x-auto ">
          <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
            <tr  >
              <th scope="col" className="  font-medium text-primary px-6 py-4 text-left">Stock Category</th>
              <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Edit</th>
              <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Delete</th>
            </tr>
          </thead>
          <tbody className='text-[13px] p-4'>
            {stocksCategoriesList}
          </tbody>
        </table>
        </div>
}
      
        </div>
    </Layout>
   
  );
}
