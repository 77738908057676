import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './employeesComplaintsEdit.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';
import { useParams } from 'react-router-dom';

export const EmployeesComplaintsEdit = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);

  const [employee, setEmployee] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  const params = useParams();

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getComplaintURL = baseURL + "/employeescomplaints/" + params.id;
    const getComplaintResponse = await fetch(getComplaintURL, {
      method: "GET",
      headers: headers
    });
    const getComplaintJSONData = await getComplaintResponse.json();
    if (!getComplaintResponse.ok) {
      toast.error(getComplaintJSONData.data.message);
      setMessage(getComplaintJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const complaint = getComplaintJSONData.data.doc;

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setEmployee(complaint.employeeID._id);
    setType(complaint.type);
    setStatus(complaint.status);
    setSubject(complaint.subject);
    setDescription(complaint.description ? complaint.description : "");
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeescomplaints/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditEmployeeComplaintForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!employee) {
      errors.push(<div>Please select an employee</div>);
    }

    if (!type) {
      errors.push(<div>Complaint type cannot be empty</div>);
    }

    if (!status) {
      errors.push(<div>Complaint status cannot be empty</div>);
    }

    if (!subject) {
      errors.push(<div>Complaint subject cannot be empty</div>);
    }

    let payload = {
      employeeID: employee,
      type,
      status,
      subject,
      description: description ? description : ""
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit complaint or suggestion</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before EmployeesComplaintsEdit() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit complaint or suggestion</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form id="createEmployeeComplaintForm" onSubmit={handleEditEmployeeComplaintForm}>

          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Employee *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="employee"
                    defaultValue={employee}
                    options={
                      employees && employees.length !== 0 && employees.map((employee, index) => {
                        return {
                          value: employee._id,
                          label: `${employee.full_name} ${employee.custom_id}`
                        }
                      })
                    }
                    handleChange={(selectedOption) => setEmployee(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Type *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="type"
                    defaultValue={type}
                    options={
                      [
                        { value: 'complaint', label: 'Complaint' },
                        { value: 'suggestion', label: 'Suggestion' },
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setType(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Status *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="status"
                    defaultValue={status}
                    options={
                      [
                        { value: 'pending', label: 'Pending' },
                        { value: 'solved', label: 'Solved' },
                        { value: 'unsolvable', label: 'Unsolvable' },
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setStatus(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Subject *</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col gap-y-3'>
              <div>
                <label className='font-normal'>Description</label>
              </div>
              <div>
                <textarea
                  rows="4"
                  cols="50"
                  name="description"
                  value={description}
                  onChange={(e) => {
                    e.target.value ? setDescription(e.target.value) : setDescription("");
                  }}
                  className='border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
                >
                </textarea>
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                id="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div >
    </Layout >
  );
}