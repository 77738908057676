import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './materialReceiptSlipsNew.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const MaterialReceiptSlipsNew = () => {
  const [message, setMessage] = useState(null);
  const [po, setPO] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getPOURL = baseURL + "/purchaseorders/" + params.id;
    const getPOResponse = await fetch(getPOURL, {
      method: "GET",
      headers: headers
    });
    const getPOJSONData = await getPOResponse.json();
    if (!getPOResponse.ok) {
      toast.error(getPOJSONData.data.message);
      setMessage(getPOJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const po = getPOJSONData.data.doc;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    setEmployees(employees);
    setPO(po);
    setIsLoading(false);
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function sendAPIRequest(payload) {
    let url = baseURL + "/materialreceiptslips/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleNewMaterialReceiptSlipForm(event) {
    event.preventDefault();
    let errors = [];
    let newMaterialReceiptSlipForm = event.target;

    setIsSubmitting(true);

    let materialReceiptSlipCreator = newMaterialReceiptSlipForm.elements.namedItem("materialReceiptSlipCreator");
    if (materialReceiptSlipCreator) {
      if (!materialReceiptSlipCreator.value) {
        errors.push("Material Receipt Slip creator is required");
      }
      else {
        materialReceiptSlipCreator = materialReceiptSlipCreator.value;
      }
    }

    let materials = [];

    let tbody = event.target.querySelector(`tbody`);
    const currentNumberOfItems = tbody.children.length;
    for (let i = 1; i <= currentNumberOfItems; i++) {
      let row = tbody.querySelector(`tr#itemCount` + i);
      const stockItemObjectId = row.getAttribute(`data-stockid`);
      const stockItemName = row.querySelector(`#nameItemCount${i}`).childNodes[0].nodeValue;
      const demandQuantity = parseFloat(row.querySelector(`#demandQuantityItemCount${i}`).childNodes[0].nodeValue);
      const quoteQuantity = parseFloat(row.querySelector(`#quoteQuantityItemCount${i}`).childNodes[0].nodeValue);
      let rate = parseFloat(row.querySelector(`#rateItemCount${i}`).childNodes[0].nodeValue);
      let orderedQuantity = parseFloat(row.querySelector(`#orderedQuantityItemCount${i}`).childNodes[0].nodeValue);
      let receivedQuantity = parseFloat(row.querySelector(`input[name="receivedQuantityItemCount${i}"`).value);

      if (isNaN(demandQuantity)) {
        errors.push("Demand quantity is not a number");
      }
      if (isNaN(quoteQuantity)) {
        errors.push("Quote quantity is not a number");
      }
      if (isNaN(rate)) {
        errors.push("Quoted rate is not a number");
      }
      if (isNaN(orderedQuantity)) {
        errors.push(`${stockItemName} ordered quantity is not a number`);
      }
      if (isNaN(receivedQuantity)) {
        errors.push(`${stockItemName} received quantity is not a number`);
      }

      materials.push({
        stockItemObjectId,
        stockItemName,
        demandQuantity,
        quoteQuantity,
        rate,
        orderedQuantity,
        receivedQuantity
      });
    }

    let deliveryChallan = newMaterialReceiptSlipForm.elements.namedItem("deliveryChallan").files[0];
    if (deliveryChallan) { //undefined if no file was selected
      const clientFileName = deliveryChallan.name;
      if (((deliveryChallan.size / 1024) / 1024) > 5) { //if greater than 5 megabytes
        errors.push("Attached file size should be less than 5 MB");
      }
      else {
        const fileAsBase64 = await convertToBase64(deliveryChallan);
        deliveryChallan = { clientFileName, fileAsBase64 };
      }
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        vendorID: po.vendorID._id,
        projectID: po.quotationID.projectID._id,
        purchaseOrderID: params.id,
        materialReceiptSlipCreator: materialReceiptSlipCreator ? materialReceiptSlipCreator : undefined,
        materials,
        deliveryChallan: deliveryChallan ? deliveryChallan : undefined
      };
      try {
        await sendAPIRequest(payload);
        setIsDisabled(true);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a material receipt slip</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];
  let poItemsList = [];

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (po && po.materials.length !== 0) {
    let count = 0;
    poItemsList = po.materials.map((material, index) => {
      count++;
      return (
        <tr key={material._id} id={`itemCount` + count} data-stockid={material.stockItemObjectId}>
          <td id={`nameItemCount` + count}>{`${material.stockItemName}`}</td>
          <td id={`demandQuantityItemCount` + count}>{`${material.demandQuantity}`}</td>
          <td id={`quoteQuantityItemCount` + count}>{`${material.quoteQuantity}`}</td>
          <td id={`rateItemCount` + count}>{`${material.rate}`}</td>
          <td id={`orderedQuantityItemCount` + count}>{material.orderedQuantity}</td>
          <td>
            <input
              name={`receivedQuantityItemCount` + count}
              type="number"
              defaultValue={0}
              min="0"
              step="0.01"
              className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm'
            />
          </td>
        </tr>
      );
    })
  }

  console.log("Before MaterialReceiptSlipsNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create a material receipt slip</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg border border-primary p-8 text-gray-900'>
          <form id="newMaterialReceiptSlipForm" onSubmit={handleNewMaterialReceiptSlipForm} className="space-y-5">

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Project Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{po.quotationID.projectID.projectName}</div>
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Vendor Company Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{po.vendorID.vendorCompanyName}</div>
              </div>

              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                && (
                  <div className="flex flex-col space-y-3 w-full md:w-1/3">
                    <label className="text-base font-normal text-left">Material Receipt Slip Creator</label>
                    <select
                      name="materialReceiptSlipCreator"
                      className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                    >
                      <option value={""}>Select an employee</option>
                      {employeesList}
                    </select>
                  </div>
                )
              }
            </div>

            <div className='overflow-x-auto rounded-lg border'>
              <table>
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Demand</th>
                    <th>Quote</th>
                    <th>Rate</th>
                    <th>Ordered Quantity</th>
                    <th>Received Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsList}
                </tbody>
              </table>
            </div>

            <div className='flex flex-col md:flex-row gap-3 md:items-center'>
              <div>
                Delivery Challan:
              </div>
              <div>
                <input
                  type="file"
                  name="deliveryChallan"
                  className='border border-[#8E8EA1] text-[#8E8EA1] py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting || isDisabled} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
