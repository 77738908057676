import React from 'react'

const ArrowLeft = () => {
    return (
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.53801 11.8401L0.652009 6.53005C0.400009 6.31405 0.400009 5.91805 0.652009 5.70205L6.53801 0.39205C6.91601 0.0500505 7.52801 0.30205 7.52801 0.80605L7.52801 11.4261C7.52801 11.9301 6.91601 12.1821 6.53801 11.8401Z" fill="var(--primary)"/>
        </svg>

    )
}

export default ArrowLeft