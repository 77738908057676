import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './rolesEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const RolesEdit = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [role, setRole] = useState(null);
  const [collectionNames, setCollectionNames] = useState(null);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let url = baseURL + "/getCollectionNames";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const collectionNames = jsonData.data.collectionNames;

    let getRoleURL = baseURL + "/roles/" + params.id;
    const getRoleResponse = await fetch(getRoleURL, {
      method: "GET",
      headers: headers
    });
    const getRoleJSONData = await getRoleResponse.json();
    if (!getRoleResponse.ok) {
      toast.error(getRoleJSONData.data.message);
      setMessage(getRoleJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const role = getRoleJSONData.data.doc;

    setRole(role);
    setCollectionNames(collectionNames);
    setIsLoading(false)
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/roles/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditRoleForm(event) {
    event.preventDefault();
    let errors = [];
    let editRoleForm = event.target;

    setIsSubmitting(true);

    let payload = {}; //empty object
    let checkboxes = editRoleForm.querySelectorAll('input[type=checkbox]:checked');
    for (let i = 0; i < checkboxes.length; i++) {
      payload[checkboxes[i].name] = checkboxes[i].value;
    }
    let roleName = editRoleForm.elements.namedItem("role_name").value;
    let roleSlug = editRoleForm.elements.namedItem("role_slug").value;
    payload.roleName = roleName;
    payload.roleSlug = roleSlug;

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit a role</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before RolesEdit() rendering");

  let collectionNamesList = [];

  if (collectionNames.length !== 0) {
    collectionNamesList = collectionNames.map((name, index) => {
      let defaultCreateChecked = false;
      let defaultReadChecked = false;
      let defaultUpdateChecked = false;
      let defaultDeleteChecked = false;
      let defaultPDeleteChecked = false;
      const create = role.create;
      const read = role.read;
      const update = role.update;
      const mydelete = role.delete;
      const pdelete = role.pdelete;
      if (create.includes(name)) {
        defaultCreateChecked = true;
      }
      if (read.includes(name)) {
        defaultReadChecked = true;
      }
      if (update.includes(name)) {
        defaultUpdateChecked = true;
      }
      if (mydelete.includes(name)) {
        defaultDeleteChecked = true;
      }
      if (pdelete.includes(name)) {
        defaultPDeleteChecked = true;
      }
      return (
        <tr key={index}>
          <td>{name}</td>
          <td className='text-center'><input type="checkbox" name={name + "_create"} value="yes" defaultChecked={defaultCreateChecked} /></td>
          <td className='text-center'><input type="checkbox" name={name + "_read"} value="yes" defaultChecked={defaultReadChecked} /></td>
          <td className='text-center'><input type="checkbox" name={name + "_update"} value="yes" defaultChecked={defaultUpdateChecked} /></td>
          <td className='text-center'><input type="checkbox" name={name + "_delete"} value="yes" defaultChecked={defaultDeleteChecked} /></td>
          <td className='text-center'><input type="checkbox" name={name + "_pdelete"} value="yes" defaultChecked={defaultPDeleteChecked} /></td>
        </tr>
      );
    });
  }

  return (
    <Layout>
      <div className="space-y-4">
        <div className='w-full space-y-2 '>
          <div className='flex flex-row justify-between items-center px-3 '>
            <h2 className='text-2xl text-primary font-bold'>Edit a role</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg  border border-[#6843BE] p-4 '>

          <form id="editRoleForm" onSubmit={handleEditRoleForm}>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3  my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Role Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">
                  <input
                    id="role_name"
                    type="text"
                    name="role_name"
                    defaultValue={role.name}
                    className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm'
                  />
                </div>
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Role Slug</label>
                <div className="w-full md:w-11/12 py-2 text-sm">
                  <input
                    id="role_slug"
                    type="text"
                    name="role_slug"
                    defaultValue={role.slug}
                    className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm'
                  />
                </div>
              </div>
            </div>

            <table>
              <thead>
                <tr>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">MongoDB Collections</th>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Create</th>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Read</th>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Update</th>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Permanently Delete</th>
                </tr>
              </thead>
              <tbody>
                {collectionNamesList}
              </tbody>
            </table>

            <div className='flex flex-row my-5'>
              <button
                disabled={isSubmitting}
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}