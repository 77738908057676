import EyeSlashSvg from 'assets/svgs/EyeSlashSvg'
import EyeSvg from 'assets/svgs/EyeSvg'
import React, { useState } from 'react'

const CustomInput = ({
  label, placeholder = '', data = '', setData = '', isPassword, type, name, ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setData(value);
  }

  return (
    <div className='flex flex-col gap-1.5 w-full'>
      {
        label &&
        <label className='font-semibold text-dark text-start'>
          {label}
        </label>
      }
      <div className='relative flex items-center'>
        <input
          type={isPassword ? ( showPassword ? 'text' : 'password' ) : type ?? 'text'}
          placeholder={placeholder}
          className='border py-3 px-4 rounded-full placeholder:text-grayText placeholder:font-medium placeholder:text-[17px] outline-none focus:border-primary w-full p-2.5'
          name={name}
          value={data}
          onChange={handleChange}
          {...props}
        />
        { isPassword && <div className='absolute top-1/2 sm:right-3 right-2 -translate-y-1/2 text-xl w-fit cursor-pointer text-gray-500'
          onClick={toggleShowPassword}
        >
          {
            showPassword ? <EyeSlashSvg /> : <EyeSvg />
          }
        </div>}
      </div>
    </div>
  );
}

export default CustomInput