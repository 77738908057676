import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './quotationsComparison.css';
import { Link, useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const QuotationsComparison = () => {
  const [message, setMessage] = useState(null);
  const [quotations, setQuotations] = useState(null);
  const [HTTPMethod, setHTTPMethod] = useState('POST');
  const [demand, setDemand] = useState(null);
  const [quotationsComparison, setQuotationsComparison] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();

  async function fetchData() {
    let url = baseURL + "/quotations/of-demand/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const quotations = jsonData.data.docs;

    let getDemandURL = baseURL + "/demands/" + params.id;
    const getDemandResponse = await fetch(getDemandURL, {
      method: "GET",
      headers: headers
    });
    const getDemandJSONData = await getDemandResponse.json();
    if (!getDemandResponse.ok) {
      toast.error(getDemandJSONData.data.message);
      setMessage(getDemandJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const demand = getDemandJSONData.data.doc;

    const getQuotationsComparisonURL = baseURL + "/quotationscomparisons/of-demand/" + params.id;
    const getQuotationsComparisonResponse = await fetch(getQuotationsComparisonURL, {
      method: "GET",
      headers: headers
    });
    const getQuotationsComparisonJSONData = await getQuotationsComparisonResponse.json();
    if (!getQuotationsComparisonResponse.ok) {
      if (!(getQuotationsComparisonResponse.status === 404)) { //ignoring 404
        toast.error(getQuotationsComparisonJSONData.data.message);
        setMessage(getQuotationsComparisonJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
    }
    const existingQuotationsComparison = getQuotationsComparisonJSONData.data.doc;

    setQuotationsComparison(existingQuotationsComparison);
    setQuotations(quotations);
    setDemand(demand);
    if (existingQuotationsComparison) {
      setHTTPMethod("PUT");
    }
    setIsLoading(false);

  }

  async function sendAPIRequest(payload) {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    if (HTTPMethod === "POST") {
      let url = baseURL + "/quotationscomparisons/create";
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });
      const jsonData = await response.json();
      if (jsonData.success) {
        toast.success(jsonData.data.message);
        setMessage(jsonData.data.message);
        return Promise.resolve("success");
      }
      else {
        toast.error(jsonData.data.message);
        setMessage(jsonData.data.message);
        return Promise.reject("failure");
      }
    } else if (HTTPMethod === "PUT") {
      let url = baseURL + "/quotationscomparisons/update/" + quotationsComparison._id;
      const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(payload),
      });
      const jsonData = await response.json();
      if (jsonData.success) {
        toast.success(jsonData.data.message);
        setMessage(jsonData.data.message);
        return Promise.resolve("success");
      }
      else {
        toast.error(jsonData.data.message);
        setMessage(jsonData.data.message);
        return Promise.reject("failure");
      }
    }
  }

  async function handleCreateQuotationComparisonForm(event) {
    event.preventDefault();
    let errors = [];
    let createQuotationComparisonForm = event.target;

    setIsSubmitting(true);

    let ceoChoice = createQuotationComparisonForm.elements.namedItem("ceoChoice");
    let ceoComment = createQuotationComparisonForm.elements.namedItem("ceoComment");
    if (ceoChoice) {
      ceoChoice = ceoChoice.value; //if no radio option selected, value is empty string
      ceoComment = ceoComment.value;
    }

    let mdChoice = createQuotationComparisonForm.elements.namedItem("mdChoice");
    let mdComment = createQuotationComparisonForm.elements.namedItem("mdComment");
    if (mdChoice) {
      mdChoice = mdChoice.value;
      mdComment = mdComment.value;
    }

    let cooChoice = createQuotationComparisonForm.elements.namedItem("cooChoice");
    let cooComment = createQuotationComparisonForm.elements.namedItem("cooComment");
    if (cooChoice) {
      cooChoice = cooChoice.value;
      cooComment = cooComment.value;
    }

    let pmChoice = createQuotationComparisonForm.elements.namedItem("pmChoice");
    let pmComment = createQuotationComparisonForm.elements.namedItem("pmComment");
    if (pmChoice) {
      pmChoice = pmChoice.value;
      pmComment = pmComment.value;
    }

    console.log(ceoChoice);
    console.log(mdChoice);
    console.log(cooChoice);
    console.log(pmChoice);

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        demandID: params.id,
        projectID: demand.projectObjectId._id,
        ceoChoice: ceoChoice ? ceoChoice : undefined,
        cooChoice: cooChoice ? cooChoice : undefined,
        mdChoice: mdChoice ? mdChoice : undefined,
        pmChoice: pmChoice ? pmChoice : undefined,
        ceoComment: ceoComment ? ceoComment : undefined,
        cooComment: cooComment ? cooComment : undefined,
        mdComment: mdComment ? mdComment : undefined,
        pmComment: pmComment ? pmComment : undefined,
      };
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Compare Quotations</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let tableHeaderElementsList = [];
  let tableSubHeaderElementsList = [];
  let tableBodyRowsList = [];

  if (quotations && quotations.length !== 0) {
    tableHeaderElementsList = quotations.map((quotation, index) => {
      return (
        <th key={quotation._id} colSpan="2">
          {`Quotation ID: ${quotation.quotationID} from `}
          <Link 
            to={`/vendors/${quotation.vendorID._id}`}
            className='p-1 px-3 font-normal text-white bg-primary rounded-xl'
          >
            {quotation.vendorID.vendorCompanyName}
          </Link>
        </th>
      );
    });
  }

  if (quotations && quotations.length !== 0) {
    tableSubHeaderElementsList = quotations.map((quotation, index) => {
      return (
        <React.Fragment key={quotation._id}>
          <th>Vendor's Quote</th>
          <th>Vendor's Rate</th>
        </React.Fragment>
      );
    });
  }

  if (demand.materials && demand.materials.length !== 0) {
    for (let i = 0; i < demand.materials.length; i++) {
      let tdElements = [];
      let stockItemName = <td>{demand.materials[i].stockItemName}</td>;
      let demandQuantity = <td>{demand.materials[i].quantity}</td>;
      tdElements.push(stockItemName);
      tdElements.push(demandQuantity);
      for (let j = 0; j < quotations.length; j++) { //nested for loop
        let vendorQuote = quotations[j].materials[i].quoteQuantity;
        let vendorRate = quotations[j].materials[i].rate;
        tdElements.push(<td>{vendorQuote}</td>);
        tdElements.push(<td>{vendorRate}</td>);
      }
      tableBodyRowsList.push(<tr>{tdElements}</tr>);
    }
  }

  console.log("Before QuotationsComparison() rendering");

  if (quotations && quotations.length === 0) { //the case when DB has zero quotations
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Compare Quotations</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              No quotations found for this demand. Please create quotations first.
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className='w-full space-y-4'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Compare Quotations</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg border border-primary p-8'>
          <h3 className='text-base mb-3'>Project Name: {quotations[0].projectID.projectName}</h3>
          <form id="createQuotationComparisonForm" onSubmit={handleCreateQuotationComparisonForm} className="space-y-5 text-gray-900">
            <div className='border border-[#6843BE] overflow-x-auto rounded-lg pb-4'>
              <table>
                <thead>
                  <tr>
                    <th scope="col" colSpan={2}></th>
                    {tableHeaderElementsList}
                  </tr>
                  <tr>
                    <th scope="col">Material</th>
                    <th scope="col">Demand</th>
                    {tableSubHeaderElementsList}
                  </tr>
                </thead>
                <tbody className='text-[13px]'>
                  {tableBodyRowsList}
                </tbody>
              </table>
            </div>

            {/* First let's show existing remarks of everyone */}

            <div className='flex flex-col gap-y-3 text-sm text-gray-900 font-normal text-left'>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Chief Executive Officer says:
                <div className='p-2'>
                  {quotationsComparison?.ceoComment ? quotationsComparison?.ceoComment : <i>No comments yet</i>}
                </div>
                <div className='p-2'>
                  <span className="font-bold">CEO Decision on vendor selection: </span>
                  {
                    quotationsComparison?.ceoChoice ?
                      `Quotation ID ${quotationsComparison.ceoChoice.quotationID} from ${quotationsComparison.ceoChoice.vendorID.vendorCompanyName}` :
                      <i>No decision yet</i>
                  }
                </div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Managing Director says:
                <div className='p-2'>
                  {quotationsComparison?.mdComment ? quotationsComparison?.mdComment : <i>No comments yet</i>}
                </div>
                <div className='p-2'>
                  {`Vendor Suggestion: `}
                  {
                    quotationsComparison?.mdChoice ?
                      `Quotation ID ${quotationsComparison.mdChoice.quotationID} from ${quotationsComparison.mdChoice.vendorID.vendorCompanyName}` :
                      <i>No suggestion yet</i>
                  }
                </div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Chief Operations Officer says:
                <div className='p-2'>
                  {quotationsComparison?.cooComment ? quotationsComparison?.cooComment : <i>No comments yet</i>}
                </div>
                <div className='p-2'>
                  {`Vendor Suggestion: `}
                  {
                    quotationsComparison?.cooChoice ?
                      `Quotation ID ${quotationsComparison.cooChoice.quotationID} from ${quotationsComparison.cooChoice.vendorID.vendorCompanyName}` :
                      <i>No suggestion yet</i>
                  }
                </div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Project Manager says:
                <div className='p-2'>
                  {quotationsComparison?.pmComment ? quotationsComparison?.pmComment : <i>No comments yet</i>}
                </div>
                <div className='p-2'>
                  {`Vendor Suggestion: `}
                  {
                    quotationsComparison?.pmChoice ?
                      `Quotation ID ${quotationsComparison.pmChoice.quotationID} from ${quotationsComparison.pmChoice.vendorID.vendorCompanyName}` :
                      <i>No suggestion yet</i>
                  }
                </div>
              </div>
            </div>

            {
              (
                userData.doc.isSuperAdmin ||
                (userData.doc.isAdmin && userData.doc.managementRank === "ceo")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Chief Executive Officer:
                  </div>
                  <div className='w-full'>
                    {
                      quotations.map((quotation, index) => {
                        let isChecked = false;
                        if (quotationsComparison) { //exists when we are updating the quotations comparison document of DB
                          if (quotation._id === quotationsComparison.ceoChoice?._id) {
                            isChecked = true;
                          }
                        }
                        return (
                          <div key={quotation._id}>
                            <input
                              type="radio"
                              name="ceoChoice"
                              defaultValue={quotation._id}
                              defaultChecked={isChecked}
                              className="me-1"
                            />
                            {`Quotation ID ${quotation.quotationID} from ${quotation.vendorID.vendorCompanyName}`}
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea 
                      name="ceoComment" 
                      className='w-full md:w-1/2 p-2 border outline-none'
                      defaultValue={quotationsComparison?.ceoComment}
                      placeholder='Please enter your comments here.'>
                    </textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                (userData.doc.isAdmin && userData.doc.managementRank === "md")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Managing Director:
                  </div>
                  <div className='w-full'>
                    {
                      quotations.map((quotation, index) => {
                        let isChecked = false;
                        if (quotationsComparison) { //exists when we are updating the quotations comparison document of DB
                          if (quotation._id === quotationsComparison.mdChoice?._id) {
                            isChecked = true;
                          }
                        }
                        return (
                          <div key={quotation._id}>
                            <input
                              type="radio"
                              name="mdChoice"
                              defaultValue={quotation._id}
                              defaultChecked={isChecked}
                              className="me-1"
                            />
                            {`Quotation ID ${quotation.quotationID} from ${quotation.vendorID.vendorCompanyName}`}
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea
                      name="mdComment"
                      className='w-full md:w-1/2 p-2 border outline-none'
                      defaultValue={quotationsComparison?.mdComment}
                      placeholder='Please enter your comments here.'>
                    </textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                (userData.doc.isAdmin && userData.doc.managementRank === "coo")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Chief Operations Officer:
                  </div>
                  <div className='w-full'>
                    {
                      quotations.map((quotation, index) => {
                        let isChecked = false;
                        if (quotationsComparison) { //exists when we are updating the quotations comparison document of DB
                          if (quotation._id === quotationsComparison.cooChoice?._id) {
                            isChecked = true;
                          }
                        }
                        return (
                          <div key={quotation._id}>
                            <input
                              type="radio"
                              name="cooChoice"
                              defaultValue={quotation._id}
                              defaultChecked={isChecked}
                              className="me-1"
                            />
                            {`Quotation ID ${quotation.quotationID} from ${quotation.vendorID.vendorCompanyName}`}
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea
                      name="cooComment"
                      className='w-full md:w-1/2 p-2 border outline-none'
                      defaultValue={quotationsComparison?.cooComment}
                      placeholder='Please enter your comments here.'>
                    </textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.role.create.includes("projects")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Project Manager:
                  </div>
                  <div className='w-full'>
                    {
                      quotations.map((quotation, index) => {
                        let isChecked = false;
                        if (quotationsComparison) { //exists when we are updating the quotations comparison document of DB
                          if (quotation._id === quotationsComparison.pmChoice?._id) {
                            isChecked = true;
                          }
                        }
                        return (
                          <div key={quotation._id}>
                            <input
                              type="radio"
                              name="pmChoice"
                              defaultValue={quotation._id}
                              defaultChecked={isChecked}
                              className="me-1"
                            />
                            {`Quotation ID ${quotation.quotationID} from ${quotation.vendorID.vendorCompanyName}`}
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea
                      name="pmComment"
                      className='w-full md:w-1/2 p-2 border outline-none'
                      defaultValue={quotationsComparison?.pmComment}
                      placeholder='Please enter your comments here.'>
                    </textarea>
                  </div>
                </div>
              )
            }

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}