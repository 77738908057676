import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';

export const LeaveRules = () => {
  const [message, setMessage] = useState(null);
  const [leaveRules, setLeaveRules] = useState(null);
  const [leaveRulesTotalCount, setLeaveRulesTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/leaverules?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const leaveRules = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;

    setLeaveRules(leaveRules);
    setLeaveRulesTotalCount(docsCount);
    setIsLoading(false);
  }

  const handleDelete = async () => {

  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("leaverules")
    ) &&
    <Link to="/leave-rules/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>New Leave Rules</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>New Leave Rules</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let leaveRulesList = [];

  if (leaveRules && leaveRules.length !== 0) {
    leaveRulesList = leaveRules.map((leave, index) => {
      let editLink = `/leave-rules/edit/${leave._id}`
      return (
        <tr key={leave._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{leave.leaveRuleID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{leave.roleID.name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{leave.casualLeaves}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{leave.medicalLeaves}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link>
          </td>
          <td className="text-primary px-6 py-4 whitespace-nowrap text-center">
            <button onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }
  else if (leaveRules && leaveRules.length === 0) {
    leaveRulesList =
      <tr>
        <td className="text-gray-900 px-6 py-4 whitespace-nowrap">No rules found!</td>
      </tr>
  }

  console.log("Before LeavesRules() rendering");

  return (
    <Layout>
      <div className="space-y-5">

        <div className='flex flex-row justify-between items-center px-3 my-2'>
          <h2 className='text-2xl text-primary font-bold'>Leaves Rules</h2>
          {NewDocumentLink}
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE] mt-8'>

          <table className="w-full table-auto overflow-x-auto">

            <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Role</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Casual Leaves</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Medical Leaves</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>

            <tbody className='text-[13px] p-4'>
              {leaveRulesList}
            </tbody>

            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(leaveRulesTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </Layout>
  );
}
