import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import styles from './vendorsEdit.module.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';

export const VendorsEdit = () => {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState(null);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let getVendorURL = baseURL + "/vendors/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getVendorResponse = await fetch(getVendorURL, {
      method: "GET",
      headers: headers
    });
    const getVendorJSONData = await getVendorResponse.json();
    const vendor = getVendorJSONData.data.doc;

    setVendor(vendor);
    setLoading(false)
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/vendors/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch( url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setMessage(jsonData.data.message);
    }
    else {
      //setMessage("An error occurred.");
      setMessage( jsonData.data.message );
    }
  }

  async function handleEditVendorForm(event) {
    event.preventDefault();
    let errors = [];
    let editVendorForm = event.target;
    let vendorCompanyName = editVendorForm.elements.namedItem("vendorCompanyName").value;
    let vendorEmail = editVendorForm.elements.namedItem("vendorEmail").value;
    let vendorAddress = editVendorForm.elements.namedItem("vendorAddress").value;
    let vendorPhone = editVendorForm.elements.namedItem("vendorPhone").value;

    let payload = {
      vendorCompanyName,
      vendorEmail,
      vendorAddress,
      vendorPhone
    };

    if(errors.length > 0) {
      let errorMessage = errors.join(', ');
      setMessage(errorMessage);
    }
    else {
      sendAPIRequest(payload);
    }

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // if (!vendor) { //on first render
  //   return (
  //     <main className={styles["vendors-edit"]}>
  //       <h2>Edit a vendor</h2>
  //       {message && message}
  //       <div>Fetching data from the server. Please wait!</div>
  //     </main>
  //   );
  // }

  console.log("Before VendorsEdit() rendering");

  return (
    <Layout>
    <div className='space-y-4'>
    <div className='flex flex-row justify-between items-center px-3 '>
    <h2 className='text-2xl text-primary font-bold	'>Edit a vendor</h2>
    </div>
   {loading?<Loader/>: <div className='overflow-x-auto rounded-lg  border border-[#6843BE] p-4 '>
      {/* <h2>Edit a vendor</h2> */}
      {message && message}
      <form id="editVendorForm" onSubmit={handleEditVendorForm}>
        Vendor Company Name: <input type="text" name="vendorCompanyName" defaultValue={vendor.vendorCompanyName}  className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /><br /><br />
        Vendor Email: <input type="text" name="vendorEmail" defaultValue={vendor.vendorEmail}  className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /><br /><br />
        Vendor Address: <input type="text" name="vendorAddress" defaultValue={vendor.vendorAddress}  className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /><br /><br />
        Vendor Phone: <input type="text" name="vendorPhone" defaultValue={vendor.vendorPhone}  className='border border-[#8E8EA1] text-black py-1 rounded-[10px] px-3 outline-none text-sm' /><br /><br />
        <input type="submit" name="submit" value="Submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9 my-4'/>
      </form>
    </div>}
    </div>
    </Layout>
   
  )
}
