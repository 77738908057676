import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './purchaseOrdersNew.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const PurchaseOrdersNew = () => {
  const [message, setMessage] = useState(null);
  const [quotation, setQuotation] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [grossTotal, setGrossTotal] = useState("0.00");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getQuotationURL = baseURL + "/quotations/" + params.id;
    const getQuotationResponse = await fetch(getQuotationURL, {
      method: "GET",
      headers: headers
    });
    const getQuotationJSONData = await getQuotationResponse.json();
    if (!getQuotationResponse.ok) {
      toast.error(getQuotationJSONData.data.message);
      setMessage(getQuotationJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const quotation = getQuotationJSONData.data.doc;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    setEmployees(employees);
    setQuotation(quotation);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/purchaseorders/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    console.log(JSON.stringify(payload))
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsDisabled(true);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleNewPurchaseOrderForm(event) {
    event.preventDefault();
    let errors = [];
    let newPurchaseOrderForm = event.target;

    setIsSubmitting(true);

    let purchaseOrderCreator = newPurchaseOrderForm.elements.namedItem("purchaseOrderCreator");
    if (purchaseOrderCreator) {
      if (!purchaseOrderCreator.value) {
        errors.push("Purchase order creator is required");
      }
      else {
        purchaseOrderCreator = purchaseOrderCreator.value;
      }
    }

    let materials = [];

    let tbody = event.target.querySelector(`tbody`);
    const currentNumberOfItems = tbody.children.length;
    for (let i = 1; i <= currentNumberOfItems; i++) {
      let row = tbody.querySelector(`tr#itemCount` + i);
      const stockItemObjectId = row.getAttribute(`data-stockid`);
      const stockItemName = row.querySelector(`#nameItemCount${i}`).childNodes[0].nodeValue;
      const demandQuantity = parseFloat(row.querySelector(`#demandQuantityItemCount${i}`).childNodes[0].nodeValue);
      const quoteQuantity = parseFloat(row.querySelector(`#quoteQuantityItemCount${i}`).childNodes[0].nodeValue);
      let rate = parseFloat(row.querySelector(`#rateItemCount${i}`).childNodes[0].nodeValue);
      let orderedQuantity = parseFloat(row.querySelector(`input[name="orderedQuantityItemCount${i}"`).value);
      
      if (isNaN(demandQuantity)) {
        errors.push("Demand quantity is not a number");
      }
      if (isNaN(quoteQuantity)) {
        errors.push("Quote quantity is not a number");
      }
      if (isNaN(rate)) {
        errors.push("Quoted rate is not a number");
      }
      if (isNaN(orderedQuantity)) {
        errors.push(`${stockItemName} ordered quantity is not a number`);
      }
      
      materials.push({
        stockItemObjectId,
        stockItemName,
        demandQuantity,
        quoteQuantity,
        rate,
        orderedQuantity,
      });

    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        vendorID: quotation.vendorID._id,
        projectID: quotation.projectID._id,
        quotationID: params.id,
        purchaseOrderCreator: purchaseOrderCreator ? purchaseOrderCreator : undefined,
        materials,
        purchaseOrderGrossAmount: parseFloat(grossTotal),
      };
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  function handleQuantityChange(e) {
    let td = e.target.parentElement;
    let tbody = td.parentElement.parentElement;
    let sum = 0.0;
    const currentNumberOfItems = tbody.children.length;
    for (let i = 1; i <= currentNumberOfItems; i++) {
      let row = tbody.querySelector(`tr#itemCount` + i);
      let rate = parseFloat(row.querySelector(`#rateItemCount${i}`).childNodes[0].nodeValue);
      let orderedQuantity = parseFloat(row.querySelector(`input[name="orderedQuantityItemCount${i}"`).value);
      if (isNaN(rate)) {
        rate = 0.0;
      }
      if (isNaN(orderedQuantity)) {
        orderedQuantity = 0.0;
      }
      sum = sum + (orderedQuantity*rate);
    }
    if (isNaN(sum)) {
      setGrossTotal(0);
    }
    else {
      setGrossTotal(sum.toFixed(2));
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a new purchase order</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];
  let quotationItemsList = [];

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (quotation && quotation.materials.length !== 0) {
    let count = 0;
    quotationItemsList = quotation.materials.map((material, index) => {
      count++;
      return (
        <tr onChange={handleQuantityChange} key={material._id} id={`itemCount` + count} data-stockid={material.stockItemObjectId}>
          <td id={`nameItemCount` + count}>{`${material.stockItemName}`}</td>
          <td id={`demandQuantityItemCount` + count}>{`${material.demandQuantity}`}</td>
          <td id={`quoteQuantityItemCount` + count}>{`${material.quoteQuantity}`}</td>
          <td id={`rateItemCount` + count}>{`${material.rate}`}</td>
          <td><input name={`orderedQuantityItemCount` + count} type="number" min="0" step="0.01" defaultValue={0} className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm' /></td>
        </tr>
      );
    })
  }

  console.log("Before PurchaseOrders() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create a new purchase order</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg border border-primary p-8 text-gray-900'>
          <form id="newPurchaseOrderForm" onSubmit={handleNewPurchaseOrderForm} className="space-y-5">

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Quotation ID</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{quotation.quotationID}</div>
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Project Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{quotation.projectID.projectName}</div>
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Vendor Company Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{quotation.vendorID.vendorCompanyName}</div>
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Quotation Creator</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{quotation.quotationCreator.full_name}</div>
              </div>

              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                &&
                <div className="flex flex-col space-y-3 w-full md:w-1/3">
                  <label className=" text-base font-normal text-left">Purchase Order Creator</label>
                  <select name="purchaseOrderCreator" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                    <option value={""}>Select an employee</option>
                    {employeesList}
                  </select>
                </div>
              }

            </div>
            <div className='overflow-x-auto rounded-lg border'>
              <table>
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Demand</th>
                    <th>Quote</th>
                    <th>Rate</th>
                    <th>Quantity to order</th>
                  </tr>
                </thead>
                <tbody>
                  {quotationItemsList}
                </tbody>
              </table>
            </div>
            <div className='flex flex-row justify-end my-5'>
              Gross Amount: <span className='pl-1'>{grossTotal}</span>
            </div>
            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting || isDisabled} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
