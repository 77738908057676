import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './demandsNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const DemandsNew = () => {
  const [message, setMessage] = useState(null);
  const [projects, setProjects] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [stockCategories, setstockCategories] = useState(null);
  const [stockItems, setStockItems] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isStockCategoriesLoading, setIsStockCategoriesLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function fetchData() {
    let url = baseURL + "/projects/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const projects = jsonData.data.docs;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    let getStockCategoriesURL = baseURL + "/stockCategories/";
    const getStockCategoriesResponse = await fetch(getStockCategoriesURL, {
      method: "GET",
      headers: headers
    });
    const getStockCategoriesJSONData = await getStockCategoriesResponse.json();
    if (!getStockCategoriesResponse.ok) {
      toast.error(getStockCategoriesJSONData.data.message);
      setMessage(getStockCategoriesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const stockCategories = getStockCategoriesJSONData.data.docs;

    setProjects(projects);
    setEmployees(employees);
    setstockCategories(stockCategories);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/demands/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleStockCategoryChange(e) {
    if (e.target.value) {
      setIsStockCategoriesLoading(true);
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };
      let getStockItemsURL = baseURL + "/stockitems/of-category/" + e.target.value;
      const getStockItemsResponse = await fetch(getStockItemsURL, {
        method: "GET",
        headers: headers
      });
      const getStockItemsJSONData = await getStockItemsResponse.json();
      if (!getStockItemsResponse.ok) {
        toast.error(getStockItemsJSONData.data.message);
        setMessage(getStockItemsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      const stockItems = getStockItemsJSONData.data.docs;
      setStockItems(stockItems);
      setIsStockCategoriesLoading(false);
    }
  }

  async function handleCreateDemandForm(event) {
    event.preventDefault();
    let errors = [];
    let createDemandForm = event.target;

    setIsSubmitting(true);

    let projectObjectId = createDemandForm.elements.namedItem("projectObjectId");
    if (projectObjectId) {
      if (!projectObjectId.value) {
        errors.push("Project is required");
      }
      else {
        projectObjectId = projectObjectId.value;
      }
    }

    let demandCreator = createDemandForm.elements.namedItem("demandCreator");
    if (demandCreator) {
      if (!demandCreator.value) {
        errors.push("Demand creator is required");
      }
      else {
        demandCreator = demandCreator.value;
      }
    }

    let materials = [];
    let demandItemsDiv = document.getElementById("demandItemsList");
    const currentNumberOfItems = demandItemsDiv.children.length;

    for (let i = 1; i <= currentNumberOfItems; i++) {
      let itemDiv = demandItemsDiv.querySelector(`#itemCount` + i);
      const stockItemObjectId = itemDiv.getAttribute(`data-stockid`);
      const stockItemName = itemDiv.querySelector(`label`).childNodes[0].nodeValue;
      const quantity = parseFloat(itemDiv.querySelector(`input`).value);

      if (isNaN(quantity)) {
        errors.push("Quantity is not a number");
      }

      if (quantity > 0) {
        materials.push({
          stockItemObjectId,
          stockItemName,
          quantity
        });
      }
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        projectObjectId,
        demandCreator: demandCreator ? demandCreator : undefined,
        materials
      };
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a new demand</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let projectsList = [];
  let employeesList = [];
  let stockCategoriesList = [];
  let stockItemsList = [];

  if (projects && projects.length !== 0) {
    projectsList = projects.map((project, index) => {
      return (
        <option key={project._id} value={project._id}>{project.projectName}</option>
      );
    });
  }

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (stockCategories && stockCategories.length !== 0) {
    stockCategoriesList = stockCategories.map((stockCategory, index) => {
      return (
        <option key={stockCategory._id} value={stockCategory._id}>{stockCategory.stockCategoryName}</option>
      );
    });
  }

  if (stockItems && stockItems.length !== 0) {
    let count = 0;
    stockItemsList = stockItems.map((stockItem, index) => {
      count++;
      return (
        <div key={stockItem._id} id={`itemCount` + count} data-stockid={stockItem._id} className='flex flex-col space-y-3 w-full md:w-1/3' >
          <label className='text-base font-normal text-left'>{`${stockItem.stockItemName} (${stockItem.stockItemUnitOfMeasure})`}</label>
          <input type="number" min="0" step="0.01" defaultValue={0} className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm' />
        </div>
      );
    });
  }

  console.log("Before DemandsNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Create a new demand</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <form id="createDemandForm" onSubmit={handleCreateDemandForm}>
          <div className='overflow-x-auto rounded-lg border border-primary p-8 text-gray-900 space-y-5'>
            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-gray-900 text-base font-normal text-left'>Project *</label>
                <select name="projectObjectId" className='border border-[#8E8EA1] text-gray-900 w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                  <option value={""}>Select a project</option>
                  {projectsList}
                </select>
              </div>

              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                && (
                  <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                    <label className='text-gray-900 text-base font-normal text-left'>Demand Creator *</label>
                    <select name="demandCreator" className='border border-[#8E8EA1] text-gray-900 w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                      <option value={""}>Select an employee</option>
                      {employeesList}
                    </select>
                  </div>
                )
              }

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-gray-900 text-base font-normal text-left'>Stock Category * {
                  isStockCategoriesLoading && <ClipLoader size={17} />
                }</label>
                <select name="demandType" className='border border-[#8E8EA1] text-gray-900 w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' onChange={handleStockCategoryChange}>
                  <option value="">Select stock category</option>
                  {stockCategoriesList}
                </select>
              </div>

            </div>

            <div className='flex flex-row justify-start'>
              <div className='text-sm text-red-900'>
                Select the stock category first. Please make the quantity a non-zero value if you would like to save the material in demand document.
              </div>
            </div>

            <div id="demandItemsList" className='flex flex-col gap-y-3'>
              {stockItemsList}
            </div>

            <div className='flex flex-row my-5'>
              <button 
                disabled={isSubmitting} 
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
