import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './quotationsEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const QuotationsEdit = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [vendors, setVendors] = useState(null);
  //const [demand, setDemand] = useState(null);
  const [quotation, setQuotation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let url = baseURL + "/vendors/";
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const vendors = jsonData.data.docs;

    let getQuotationURL = baseURL + "/quotations/" + params.id;
    const getQuotationResponse = await fetch(getQuotationURL, {
      method: "GET",
      headers: headers
    });
    const getQuotationJSONData = await getQuotationResponse.json();
    if (!getQuotationResponse.ok) {
      toast.error(getQuotationJSONData.data.message);
      setMessage(getQuotationJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const quotation = getQuotationJSONData.data.doc;

    /*let getDemandURL = baseURL + "/demands/" + quotation.demandID._id;
    const getDemandResponse = await fetch(getDemandURL, {
      method: "GET",
      headers: headers
    });
    const getDemandJSONData = await getDemandResponse.json();
    if (!getDemandResponse.ok) {
      toast.error(getDemandJSONData.data.message);
      setMessage(getDemandJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const demand = getDemandJSONData.data.doc;*/

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    setVendors(vendors);
    //setDemand(demand);
    setEmployees(employees);
    setQuotation(quotation);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    console.log("Inside sendAPIRequest");
    let url = baseURL + "/quotations/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditQuotationForm(event) {
    event.preventDefault();
    let errors = [];
    let editQuotationForm = event.target;

    setIsSubmitting(true);

    let vendorID = editQuotationForm.elements.namedItem("vendorID");
    if (vendorID) {
      if (!vendorID.value) {
        errors.push("Vendor is required");
      }
      else {
        vendorID = vendorID.value;
      }
    }

    let quotationCreator = editQuotationForm.elements.namedItem("quotationCreator");
    if (quotationCreator) {
      if (!quotationCreator.value) {
        errors.push("Quotation creator is required");
      }
      else {
        quotationCreator = quotationCreator.value;
      }
    }

    let qsConsent = editQuotationForm.elements.namedItem("qsConsent");
    let qsComment = editQuotationForm.elements.namedItem("qsComment");
    if (qsConsent) {
      if (qsConsent.checked) {
        qsConsent = "accept"
      }
      else {
        qsConsent = "pending"
      }
      qsComment = qsComment.value;
    }

    let projectManagerConsent = editQuotationForm.elements.namedItem("projectManagerConsent");
    let projectManagerComment = editQuotationForm.elements.namedItem("projectManagerComment");
    if (projectManagerConsent) {
      if (projectManagerConsent.checked) {
        projectManagerConsent = "accept"
      }
      else {
        projectManagerConsent = "pending"
      }
      projectManagerComment = projectManagerComment.value;
    }

    let materials = [];
    let quotationItemsDiv = document.querySelector(`#editQuotationForm #quotationItemsList`);
    const currentNumberOfItems = quotationItemsDiv.children.length;

    for (let i = 1; i <= currentNumberOfItems; i++) {
      let itemDiv = quotationItemsDiv.querySelector(`#itemCount` + i);
      const stockItemObjectId = itemDiv.getAttribute(`data-stockid`);
      const stockItemName = itemDiv.querySelector(`#nameItemCount${i}`).childNodes[0].nodeValue;
      const demandQuantity = parseFloat(itemDiv.querySelector(`#demandQuantityItemCount${i}`).childNodes[0].nodeValue);
      const quoteQuantity = parseFloat(itemDiv.querySelector(`input[name="quoteItemCount${i}"`).value);
      const rate = parseFloat(itemDiv.querySelector(`input[name="rateItemCount${i}"`).value);

      if (isNaN(demandQuantity)) {
        errors.push("Demand quantity is not a number");
      }
      if (isNaN(quoteQuantity)) {
        errors.push("Quote quantity is not a number");
      }
      if (isNaN(rate)) {
        errors.push("Quoted rate is not a number");
      }

      materials.push({
        stockItemObjectId,
        stockItemName,
        demandQuantity,
        quoteQuantity,
        rate
      });
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        vendorID,
        projectID: quotation.projectID._id,
        demandID: quotation.demandID._id,
        quotationCreator: quotationCreator ? quotationCreator : undefined,
        materials,
        qsConsent: qsConsent ? qsConsent : undefined,
        projectManagerConsent: projectManagerConsent ? projectManagerConsent : undefined,
        qsComment: qsComment ? qsComment : undefined,
        projectManagerComment: projectManagerComment ? projectManagerComment : undefined,
      };
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit a quotation</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let vendorsList = [];
  let employeesList = [];
  let quotationItemsList = [];

  if (vendors && vendors.length !== 0) {
    vendorsList = vendors.map((vendor, index) => {
      return (
        <option key={vendor._id} value={vendor._id}>{vendor.vendorCompanyName}</option>
      );
    });
  }

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (quotation.materials && quotation.materials.length !== 0) {
    let count = 0;
    quotationItemsList = quotation.materials.map((material, index) => {
      count++;
      return (
        <tr key={material._id} id={`itemCount` + count} data-stockid={material.stockItemObjectId} >
          <td id={`nameItemCount` + count}>{`${material.stockItemName}`}</td>
          <td id={`demandQuantityItemCount` + count}>{`${material.demandQuantity}`}</td>
          <td><input name={`quoteItemCount` + count} type="number" min="0" step="0.01" defaultValue={material.quoteQuantity} className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm' /></td>
          <td><input name={`rateItemCount` + count} type="number" min="0" step="0.01" defaultValue={material.rate} className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm' /></td>
        </tr>
      );
    });
  }

  console.log("Before QuotationsEdit() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit a quotation</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg border border-primary p-8'>
          <form id="editQuotationForm" onSubmit={handleEditQuotationForm} className="space-y-5 text-gray-900">
            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3 my-3'>
              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Project Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{quotation.projectID.projectName}</div>
              </div>
              <div className="flex flex-col space-y-3 w-full md:w-1/3">
                <label className="text-base font-normal text-left">Vendor</label>
                <select disabled name="vendorID" defaultValue={quotation.vendorID._id} className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                  <option value={""}>Select a vendor</option>
                  {vendorsList}
                </select>
              </div>
              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                &&
                <div className="flex flex-col space-y-3 w-full md:w-1/3">
                  <label className="text-base font-normal text-left">Quotation Creator</label>
                  <select name="quotationCreator" defaultValue={quotation.quotationCreator._id} className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                    <option value={""}>Select an employee</option>
                    {employeesList}
                  </select>
                </div>
              }
            </div>
            <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
              <table>
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Demand</th>
                    <th>Quote</th>
                    <th>Rate</th>
                  </tr>
                </thead>
                <tbody id="quotationItemsList">
                  {quotationItemsList}
                </tbody>
              </table>
            </div>

            <div className='flex flex-col gap-y-3 text-sm text-gray-900 font-normal text-left'>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Project Manager says: <div className='p-2'>{quotation.projectManagerComment ? quotation.projectManagerComment : <i>No comments yet</i>}</div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                QS says: <div className='p-2'>{quotation.qsComment ? quotation.qsComment : <i>No comments yet</i>}</div>
              </div>
            </div>

            {
              (
                userData.doc.isSuperAdmin ||
                userData.role.create.includes("projects")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Project Manager:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="projectManagerConsent" value="accept" defaultChecked={quotation.projectManagerConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="projectManagerComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={quotation.projectManagerComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.role.create.includes("projects")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    QS:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="qsConsent" value="accept" defaultChecked={quotation.qsConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="qsComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={quotation.qsComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
