import React from "react";
import { createContext, useState } from "react";

export const AuthContext = createContext(null);

export const AuthContextProvider = (props) => {
  const [userData, setUserData] = useState(props.localStorageData);

  const addUserData = ({ doc, token, role }) => {
    setUserData({ doc, token, role });
    localStorage.setItem("technoPropertiesData", btoa(JSON.stringify({ doc, token, role })));
  }

  const removeUserData = () => {
    setUserData({ doc: null, token: null, role: null });
    localStorage.setItem("technoPropertiesData", btoa(JSON.stringify({ doc: null, token: null, role: null })));
  }

  const contextValue = {
    userData,
    addUserData,
    removeUserData
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
}