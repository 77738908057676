import './styles.css';
import ReactPaginate from 'react-paginate';
import ArrowLeftSvg from 'assets/svgs/ArrowLeft';
import ArrowRightSvg from 'assets/svgs/ArrowRight';

const Pagination = ({ pageCount, setPage, currentPage }) => {
  const handlePageClick = ({ selected }) => {
    setPage(selected+1);
  }

  return (
    <div className='w-full flex py-3 relative px-2'>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<ArrowRightSvg />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={<ArrowLeftSvg />}
        className='flex items-center gap-4 pagination'
        forcePage={currentPage - 1}
      />
    </div>
  )
}

export default Pagination