import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import './admins.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { FaPlus } from 'react-icons/fa6';

export const Admins = () => {
  const [message, setMessage] = useState(null);
  const [admins, setAdmins] = useState(null);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    let url = baseURL + "/users/admins/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      setMessage(jsonData.data.message);
      return; //early return
    }
    const admins = jsonData.data.docs;
    setAdmins(admins);
  }

  const handleDelete = async (e) => {
    let deleteButton = e.target;
    let id = deleteButton.getAttribute("data-id");
    let url = baseURL + "/admins/delete/" + id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    /*const response = await fetch(url, {
      method: "DELETE",
      headers: headers
    });
    const jsonData = await response.json();
    setMessage(jsonData.data.message);*/
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (!admins) { //on first render
    return (
      <Layout>
        <div>
          <h2>Admins</h2>
          {message && message}
          <div>Fetching data from the server. Please wait!</div>
        </div>
      </Layout>
    );
  }

  let adminsList = [];

  if (admins && admins.length !== 0) {
    adminsList = admins.map((admin, index) => {
      let editLink = "/admins/edit/" + admin._id;
      return (
        <tr key={admin._id}>
          <td className="text-gray-900  px-6 py-3 whitespace-nowrap">{admin.fullName}</td>
          <td className="text-gray-900  px-6 py-3 whitespace-nowrap">{admin.email}</td>
          <td className="text-gray-900  px-6 py-3 whitespace-nowrap">{admin.role?.name}</td>
          <td className="text-gray-900  px-6 py-3 whitespace-nowrap">{admin.role_slug}</td>
          <td className="text-gray-900  px-6 py-3 whitespace-nowrap">{admin.managementRank}</td>
          {/* <td className=" text-gray-900  px-6 py-3 whitespace-nowrap"><Link to={editLink}>Edit</Link></td> */}
          <td className="text-gray-900  px-6 py-3 whitespace-nowrap"><button data-id={admin._id} onClick={handleDelete}>Delete</button></td>
        </tr>
      );
    });
  }
  else if (admins && admins.length === 0) {
    return (
      <Layout>
        <div>
          <h2>Admins</h2>
          {message && message}
          <div>No admins in the system. Please create one.</div>
        </div>
      </Layout>
    );
  }

  console.log("Before Admins() rendering");

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold	'>All Admin</h2>
          <Link to="/admins/new">
            <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
              <span><FaPlus /></span>
              <span>Add Admin</span>
            </button>
          </Link>
        </div>
        <div className='flex flex-row justify-between'>
          {message && message}
        </div>
        <div className='overflow-hidden rounded-lg  border border-[#6843BE] '>
          <table className="w-full  table-auto overflow-x-auto   ">
            <thead className="text-[14px] border border-b-[#6843BE]   ">
              <tr>
                <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Full Name</th>
                <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Email</th>
                <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Role</th>
                <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Role Slug</th>
                <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Management Rank</th>
                {/* <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Edit</th> */}
                <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody>
              {adminsList}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}
