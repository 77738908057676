import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './employeesAttendancesNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const EmployeesAttendancesNew = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [businessHours, setBusinessHours] = useState(null);

  const [employee, setEmployee] = useState("");
  const [status, setStatus] = useState("");
  const [arrivalTime, setArrivalTime] = useState(new Date());
  const [isDeparture, setIsDeparture] = useState(false);
  const [departureTime, setDepartureTime] = useState(new Date());
  const [season, setSeason] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    let getBusinessHoursURL = baseURL + "/workinghours/";
    const getBusinessHoursResponse = await fetch(getBusinessHoursURL, {
      method: "GET",
      headers: headers
    });
    const getBusinessHoursJSONData = await getBusinessHoursResponse.json();
    if (!getBusinessHoursResponse.ok) {
      toast.error(getBusinessHoursJSONData.data.message);
      setMessage(getBusinessHoursJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const businessHours = getBusinessHoursJSONData.data.docs;

    setEmployees(employees);
    setBusinessHours(businessHours);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeesattendances1/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateAttendanceForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!employee) {
      errors.push(<div>Employee name can't be empty</div>);
    }

    if (!status) {
      errors.push(<div>Status is required</div>);
    }

    if (!season) {
      errors.push(<div>Season is required</div>);
    }

    let payload = {
      employeeID: employee,
      season: season
    }

    let employeeDocument = employees.find((emp) => emp._id === employee);

    if (status === "Present") {

      payload.status = status;
      if (employeeDocument)
        payload.shift = employeeDocument?.shift; //employeeDocument is undefined if employee is not selected

      let seasonBusinessHours = businessHours.find((bh) => {
        return (bh.season === season && bh.shift === employeeDocument.shift)
      });

      console.log(seasonBusinessHours);

      //Finding how late employee arrived or how early he left
      let arrivalSpread = 4;
      let departureSpread = 4;
      let oneHour = 1 * 60 * 60 * 1000; //mins * secs * ms
      let arrivalDepartureSpread = 14 * oneHour;

      let openingTime = new Date(arrivalTime.getTime());
      let closingTime;
      if (isDeparture) {
        closingTime = new Date(departureTime.getTime());
      }
      let lateArrivalMins = null; //could be a negative value if arrived early
      let earlyDepartureMins = null;

      if (seasonBusinessHours) {
        openingTime.setHours(seasonBusinessHours.openingHour);
        openingTime.setMinutes(seasonBusinessHours.openingMin);

        if ((Math.abs(arrivalTime.getTime() - openingTime.getTime()) > (arrivalSpread * oneHour))) {
          errors.push(<div>Arrival time can't be later or earlier than {arrivalSpread} hours of working shift's starting time.</div>);
        }
        else {
          lateArrivalMins = (arrivalTime.getTime() - openingTime.getTime()) / (1 * 60 * 1000); //ms to minutes
        }

        if (isDeparture) {
          closingTime.setHours(seasonBusinessHours.closingHour);
          closingTime.setMinutes(seasonBusinessHours.closingMin);

          if (Math.abs(departureTime.getTime() - closingTime.getTime()) > (departureSpread * oneHour)) {
            errors.push(<div>Departure time can't be later or earlier than {departureSpread} hours of working shift's closing time.</div>);
          }
          else {
            earlyDepartureMins = (departureTime.getTime() - closingTime.getTime()) / (1 * 60 * 1000); //ms to minutes
          }

          if ( Math.abs(arrivalTime.getTime() - departureTime.getTime()) > (arrivalDepartureSpread) ) {
            errors.push(<div>Invalid arrival or departure times</div>);
          }
        }

        if (lateArrivalMins !== null) {
          payload.lateArrivalMins = lateArrivalMins;
          payload.arrivalTime = arrivalTime;
        }
        if (isDeparture && earlyDepartureMins !== null) {
          payload.earlyDepartureMins = earlyDepartureMins;
          payload.departureTime = departureTime;
        }
      }
      else {
        errors.push(<div>Office Timings for specified season and selected employee's working shift not found</div>);
      }
    }
    else { //absent or on leave
      payload.status = status;
      if (employeeDocument)
        payload.shift = employeeDocument.shift;
    }

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage([...errors]);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Mark Employee Attendance</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before employeesAttendancesNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Mark Employee Attendance</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form onSubmit={handleCreateAttendanceForm}>

          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Employee Name *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="employee"
                    options={
                      employees && employees.length !== 0 && employees.map((employee, index) => {
                        return {
                          value: employee._id,
                          label: `${employee.full_name} ${employee.custom_id}`
                        }
                      })
                    }
                    handleChange={(selectedOption) => setEmployee(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Status *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="status"
                    options={
                      [
                        { value: 'Absent', label: 'Absent' },
                        { value: 'Present', label: 'Present' },
                        { value: 'OnLeave', label: 'On Leave' }
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setStatus(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Season *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="season"
                    options={
                      [
                        { value: 'Summer', label: 'Summer' },
                        { value: 'Winter', label: 'Winter' },
                        { value: 'Ramadan', label: 'Ramadan' }
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setSeason(selectedOption.value)}
                  />
                </div>
              </div>

            </div>

            {
              status === "Present" && (
                <div className='flex flex-col gap-y-5'>

                  <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                    <div>
                      <label className='font-normal'>Arrival Time *</label>
                    </div>
                    <div className='w-full md:w-11/12'>
                      <DatePicker
                        selected={arrivalTime}
                        onChange={(date) => {
                          setArrivalTime(date);
                        }}
                        showTimeInput
                        timeInputLabel="Time:"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className='border border-[#8E8EA1] py-2 rounded-md px-3 outline-none'
                      />
                    </div>
                  </div>

                  <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                    <div>
                      <input
                        type="checkbox"
                        name="isDeparture"
                        checked={isDeparture}
                        onChange={(e) => setIsDeparture(e.target.checked)}
                      />{" "}
                      <label className='font-normal'>Set Departure Time</label>
                    </div>
                    {
                      isDeparture && (
                        <React.Fragment>
                          <div>
                            <label className='font-normal'>Departure Time *</label>
                          </div>
                          <div className='w-full md:w-11/12'>
                            <DatePicker
                              selected={departureTime}
                              onChange={(date) => setDepartureTime(date)}
                              showTimeInput
                              timeInputLabel="Time:"
                              dateFormat="MMMM d, yyyy h:mm aa"
                              className='border border-[#8E8EA1] py-2 rounded-md px-3 outline-none'
                            />
                          </div>
                        </React.Fragment>
                      )
                    }

                  </div>

                </div>
              )
            }

            <div className='flex flex-row my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div >
    </Layout >
  );
}