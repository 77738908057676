import React from 'react';
import { baseURL } from 'config/api.js';
import { useState } from 'react';
//import './myComplaintsNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';

export const MyComplaintsNew = () => {
  const [message, setMessage] = useState(null);

  const [type, setType] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeescomplaints/my-complaints/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateEmployeeComplaintForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!type) {
      errors.push(<div>Complaint type cannot be empty</div>);
    }

    if (!subject) {
      errors.push(<div>Complaint subject cannot be empty</div>);
    }

    let payload = {
      type,
      subject,
      description: description ? description : undefined
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  console.log("Before MyComplaintsNew() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create complaint or suggestion</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form id="createEmployeeLeaveForm" onSubmit={handleCreateEmployeeComplaintForm}>

          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Type *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="type"
                    options={
                      [
                        { value: 'complaint', label: 'Complaint' },
                        { value: 'suggestion', label: 'Suggestion' },
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setType(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Subject *</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col gap-y-3'>
              <div>
                <label className='font-normal'>Description</label>
              </div>
              <div>
                <textarea
                  rows="4"
                  cols="50"
                  name="description"
                  value={description}
                  onChange={(e) => {
                    e.target.value ? setDescription(e.target.value) : setDescription('');
                  }}
                  className='border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
                >
                </textarea>
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <button 
                disabled={isSubmitting || isDisabled} 
                type="submit" 
                id="submit" 
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div >
    </Layout >
  );
}