import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import './employees.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Pagination from 'components/global/pagination';
import Loader from 'components/global/Loader';
import { FaPlus } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';

export const Employees = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [leaves, setLeaves] = useState(null);
  const [totalEmployees, setTotalEmployees] = useState(null);
  const [company, setCompany] = useState("allCompanies");
  const [companies, setCompanies] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  async function fetchData() {
    let url = `${baseURL}/employees?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employeesArray = jsonData.data.docs;

    let getCompaniesURL = baseURL + "/companies";
    const getCompaniesResponse = await fetch(getCompaniesURL, {
      method: "GET",
      headers: headers
    });
    const getCompaniesJSONData = await getCompaniesResponse.json();
    if (!getCompaniesResponse.ok) {
      toast.error(getCompaniesJSONData.data.message);
      setMessage(getCompaniesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const companies = getCompaniesJSONData.data.docs;

    setEmployees(employeesArray);
    setTotalEmployees(jsonData.data.docsCount);
    setLeaves(jsonData.data.employeeLeaves);
    setCompanies(companies);
    setIsLoading(false);
  }

  const handleCompanyChange = async (e) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    setCompany(e.target.value);

    let url;
    if (e.target.value === "allCompanies")
      url = `${baseURL}/employees?page=${page}`;
    else
      url = `${baseURL}/employees?companyID=${e.target.value}&page=${page}`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    const employees = jsonData.data.docs;
    setEmployees(employees);
    setTotalEmployees(jsonData.data.docsCount);
    setLeaves(jsonData.data.employeeLeaves);
    setIsLoading(false);
  }

  const handleDelete = async (e) => {
    let deleteButton = e.target;
    let id = deleteButton.getAttribute("data-id");
    let url = baseURL + "/employees/delete/" + id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    /*const response = await fetch(url, {
      method: "DELETE",
      headers: headers
    });
    const jsonData = await response.json();
    setMessage(jsonData.data.message);*/
  }

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("employees")
    ) &&
    <Link to="/employees/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>Add Employee</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Employees</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      let editLink = "/employees/edit/" + employee._id;
      let tasksLink = "/employeestasks/of-employee/" + employee._id;

      let employment_status;

      if (employee.employment_status === 0)
        employment_status = "Active";
      else if (employee.employment_status === 1)
        employment_status = "On Leave";
      else if (employee.employment_status === 2)
        employment_status = "Terminated";
      else if (employee.employment_status === 3)
        employment_status = "Resigned";

      return (
        <tr key={index}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.employeeID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.custom_id}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.email}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.roleID.name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employment_status}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.company.name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.department.department_name}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link to={tasksLink}>
              <button className='text-white bg-[#04A00A] px-4 py-[5px] rounded-2xl'>View</button>
            </Link>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link></td>
          <td className=" text-primary px-6 py-4 whitespace-nowrap text-center">
            <button data-id={employee._id} onClick={handleDelete}  >
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button></td>
        </tr>
      );
    });
  }

  let employeesOnLeave = [];

  if (leaves && leaves.length > 0) {
    leaves.forEach((leave, index) => {
      // seconds * minutes * hours * milliseconds = 1 day 
      var day = 60 * 60 * 24 * 1000;
      let sd = new Date(leave.startDate);
      let ed = new Date(leave.endDate);
      ed = new Date(ed.getTime() + day);
      const offsetMilliSeconds = (new Date().getTimezoneOffset()) * 60 * 1000;
      sd = new Date(sd.getTime() + offsetMilliSeconds);
      ed = new Date(ed.getTime() + offsetMilliSeconds);
      let now = new Date();
      if ((now >= sd) && (now <= ed)) { //checking if employee is on leave
        if (!employeesOnLeave.includes(leave.employeeID._id)) {
          employeesOnLeave.push(leave.employeeID._id);
        }
      }
    })
  }

  console.log("Before Employees() rendering");

  return (
    <Layout>
      <div className='space-y-5 px-3 sm:px-0'>

        <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center px-3 gap-y-3'>

          <h2 className='text-2xl text-center text-primary font-bold'>Employees</h2>

          <div className='flex flex-row gap-3 justify-around'>

            <div className='flex flex-row items-center bg-[#90288F] px-3 py-2 rounded-3xl'>
              <div className='flex flex-row items-center text-white text-sm space-x-1'>
                <select
                  name="company"
                  onChange={handleCompanyChange}
                  value={company}
                  className='bg-[#90288F] rounded-[40px] outline-none text-sm'
                >
                  <option value="allCompanies">All Companies</option>
                  {
                    companies && companies.length !== 0 && companies.map((c, index) => {
                      return (
                        <option key={c._id} value={c._id}>{c.name}</option>
                      );
                    })
                  }
                </select>
              </div>
            </div>

            <div>
              {NewDocumentLink}
            </div>

          </div>

        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='flex flex-col sm:flex-row gap-3'>

          <div className='flex flex-col bg-gradient-to-r p-2 from-[#00A843] to-[#1FD071] w-full md:w-1/4 rounded-lg cursor-pointer'>
            <div className='flex justify-center py-2'>
              <div className='h-10 w-10 bg-white flex justify-center items-center text-2xl rounded-full'>
                <FaUsers className='text-[#00A843]' />
              </div>
            </div>
            <div className='text-center text-white'>
              <h2 className='font-semibold'>Total Employees</h2>
              <p className='text-xl font-normal'>{totalEmployees}</p>
            </div>
          </div>

          <div className='flex flex-col bg-gradient-to-r p-2 from-[#003AD2] to-[#0097EC] w-full md:w-1/4 rounded-lg cursor-pointer'>
            <div className='flex justify-center py-2'>
              <div className='h-10 w-10 bg-white flex justify-center items-center text-2xl rounded-full'>
                <FaUsers className='text-[#003AD2]' />
              </div>
            </div>
            <div className='text-center text-white'>
              <h2 className='font-semibold'>Today Present</h2>
              <p className='text-xl font-normal'>{totalEmployees - employeesOnLeave.length}</p>
            </div>
          </div>

          <div className='flex flex-col bg-gradient-to-r p-2 from-[#9750F0] to-[#5C03CB] w-full md:w-1/4 rounded-lg cursor-pointer'>
            <div className='flex justify-center py-2'>
              <div className='h-10 w-10 bg-white flex justify-center items-center text-2xl rounded-full'>
                <FaUsers className='text-[#9750F0]' />
              </div>
            </div>
            <div className='text-center text-white'>
              <h2 className='font-semibold'>Today on Leave</h2>
              <p className='text-xl font-normal'>{employeesOnLeave.length}</p>
            </div>
          </div>

          <div className='flex flex-col bg-gradient-to-r p-2 from-[#F39034] to-[#FF2727] w-full md:w-1/4 rounded-lg cursor-pointer'>
            <div className='flex justify-center py-2'>
              <div className='h-10 w-10 bg-white flex justify-center items-center text-2xl rounded-full'>
                <FaUsers className='text-[#F39034]' />
              </div>
            </div>
            <div className='text-center text-white'>
              <h2 className='font-semibold'>Today Late</h2>
              <p className='text-xl font-normal'>02</p>
            </div>
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto ">
            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Full Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Employee ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Email</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Role</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Status</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Company</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Department</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Tasks</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {employeesList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='9'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(totalEmployees / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
