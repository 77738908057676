import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './materialIssuesNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const MaterialIssuesNew = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [stockItems, setStockItems] = useState(null);
  const [projects, setProjects] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    let getStockItemsURL = baseURL + "/stockitems/";
    const getStockItemsResponse = await fetch(getStockItemsURL, {
      method: "GET",
      headers: headers
    });
    const getStockItemsJSONData = await getStockItemsResponse.json();
    if (!getStockItemsResponse.ok) {
      toast.error(getStockItemsJSONData.data.message);
      setMessage(getStockItemsJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const stockItems = getStockItemsJSONData.data.docs;

    let getProjectsURL = baseURL + "/projects/";
    const getProjectsResponse = await fetch(getProjectsURL, {
      method: "GET",
      headers: headers
    });
    const getProjectsJSONData = await getProjectsResponse.json();
    if (!getProjectsResponse.ok) {
      toast.error(getProjectsJSONData.data.message);
      setMessage(getProjectsJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const projects = getProjectsJSONData.data.docs;

    setEmployees(employees);
    setStockItems(stockItems);
    setProjects(projects);
    setIsLoading(false);

  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/materialissues/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateMaterialIssuesForm(event) {
    event.preventDefault();
    let errors = [];
    let createMaterialIssuesForm = event.target;

    setIsSubmitting(true);
    setIsDisabled(true);

    let projectObjectId = createMaterialIssuesForm.elements.namedItem("projectObjectId");
    let stockItemObjectId = createMaterialIssuesForm.elements.namedItem("stockItemObjectId");
    let materialIssueDate = createMaterialIssuesForm.elements.namedItem("materialIssueDate").value;
    let materialIssueQuantity = createMaterialIssuesForm.elements.namedItem("materialIssueQuantity");

    let materialIssueCreator = createMaterialIssuesForm.elements.namedItem("materialIssueCreator");
    if (materialIssueCreator) {
      if (!materialIssueCreator.value) {
        errors.push("Material issue slip creator is required");
      }
      else {
        materialIssueCreator = materialIssueCreator.value;
      }
    }

    if (!projectObjectId.value) {
      errors.push("Project is required");
    }
    else {
      projectObjectId = projectObjectId.value;
    }

    if (!stockItemObjectId.value) {
      errors.push("Stock Item is required");
    }
    else {
      stockItemObjectId = stockItemObjectId.value;
    }

    if (!materialIssueQuantity.value) {
      errors.push("Quantity is required and should be greater than zero");
    }
    else {
      materialIssueQuantity = materialIssueQuantity.value;
    }

    let payload = {
      projectObjectId,
      materialIssueCreator: materialIssueCreator ? materialIssueCreator : undefined,
      stockItemObjectId,
      materialIssueDate,
      materialIssueQuantity,
    };

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Issue materials to project</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];
  let stockItemsList = [];
  let projectsList = [];

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (stockItems && stockItems.length !== 0) {
    stockItemsList = stockItems.map((stockItem, index) => {
      return (
        <option key={stockItem._id} value={stockItem._id}>{stockItem.stockItemSKU + " " + stockItem.stockItemName}</option>
      );
    });
  }

  if (projects && projects.length !== 0) {
    projectsList = projects.map((project, index) => {
      return (
        <option key={project._id} value={project._id}>{project.projectName}</option>
      );
    });
  }

  console.log("Before MaterialIssuesNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Issue materials to project</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form id="createMaterialIssuesForm" onSubmit={handleCreateMaterialIssuesForm}>
          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div>
              Project: {" "}
              <select
                name="projectObjectId"
                className='border border-[#8E8EA1] py-2 w-full md:w-1/3 rounded-[40px] px-3 outline-none text-sm'
              >
                <option value={""}>Select a project</option>
                {projectsList}
              </select>
            </div>

            <div>
              Stock Item: {" "}
              <select
                name="stockItemObjectId"
                className='border border-[#8E8EA1] py-2 w-full md:w-1/3 rounded-[40px] px-3 outline-none text-sm'
              >
                <option value={""}>Select a material</option>
                {stockItemsList}
              </select>
            </div>

            {
              (
                userData.doc.isSuperAdmin ||
                userData.doc.isAdmin
              )
              &&
              <div>
                Material Issue Creator: {" "}
                <select
                  name="materialIssueCreator"
                  className='border border-[#8E8EA1] py-2 w-full md:w-1/3 rounded-[40px] px-3 outline-none text-sm'
                >
                  <option value={""}>Select an employee</option>
                  {employeesList}
                </select>
              </div>
            }

            <div>
              Issue Date: {" "}
              <input
                type="date"
                name="materialIssueDate"
                className='border border-[#8E8EA1] w-full md:w-1/4 py-2 rounded-[40px] px-3 outline-none text-sm'
              />
            </div>

            <div>
              Material Issue Quantity: {" "}
              <input
                type="number"
                name="materialIssueQuantity"
                min={0}
                step={0.01}
                className='border border-[#8E8EA1] w-full md:w-1/4 py-2 rounded-[40px] px-3 outline-none text-sm'
              />
            </div>

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting || isDisabled} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>
        </form>
      </div>
    </Layout>
  )
}
