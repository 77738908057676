import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './employeesComplaints.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';

export const EmployeesComplaints = () => {
  const [message, setMessage] = useState(null);
  const [complaints, setComplaints] = useState(null);
  const [complaintsTotalCount, setComplaintsTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/employeescomplaints?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const complaints = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;

    setComplaints(complaints);
    setComplaintsTotalCount(docsCount);
    setIsLoading(false);
  }

  const handleDelete = async () => {

  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("employeecomplaints")
    ) &&
    <Link to="/employees-complaints/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>New Complaint</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>Employees Complaints</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let complaintsList = [];

  if (complaints && complaints.length !== 0) {
    complaintsList = complaints.map((complaint, index) => {
      let editLink = `/employees-complaints/edit/${complaint._id}`

      let type;

      if (complaint.type === "complaint")
        type = "Complaint";
      else if (complaint.type === "suggestion")
        type = "Suggestion";

      let status;

      if (complaint.status === "pending")
        status = "Pending";
      else if (complaint.status === "solved")
        status = "Solved";
      else if (complaint.status === "unsolvable")
        status = "Unsolvable";


      return (
        <tr key={complaint._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{complaint.employeeComplaintID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{complaint.employeeID.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{complaint.employeeID.custom_id}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{type}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{complaint.subject}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{status}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link>
          </td>
          <td className="text-primary px-6 py-4 whitespace-nowrap text-center">
            <button onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }
  else if (complaints && complaints.length === 0) {
    complaintsList =
      <tr>
        <td className="text-gray-900 px-6 py-4 whitespace-nowrap">No complaints found!</td>
      </tr>
  }

  console.log("Before EmployeesComplaints() rendering");

  return (
    <Layout>
      <div className="space-y-5">

        <div className='flex flex-row justify-between items-center px-3 my-2'>
          <h2 className='text-2xl text-primary font-bold'>Employees Complaints</h2>
          {NewDocumentLink}
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE] mt-8'>

          <table className="w-full table-auto overflow-x-auto">

            <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Full Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Employee ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Type</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Subject</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Status</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>

            <tbody className='text-[13px] p-4'>
              {complaintsList}
            </tbody>

            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(complaintsTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </Layout >
  );
}
