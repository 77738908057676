import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './materialReceiptSlipsEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const MaterialReceiptSlipsEdit = () => {
  const [message, setMessage] = useState(null);
  const [materialReceiptSlip, setMaterialReceiptSlip] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getMaterialReceiptSlipURL = baseURL + "/materialreceiptslips/" + params.id;
    const getMaterialReceiptSlipResponse = await fetch(getMaterialReceiptSlipURL, {
      method: "GET",
      headers: headers
    });
    const getMaterialReceiptSlipJSONData = await getMaterialReceiptSlipResponse.json();
    if (!getMaterialReceiptSlipResponse.ok) {
      toast.error(getMaterialReceiptSlipJSONData.data.message);
      setMessage(getMaterialReceiptSlipJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const materialReceiptSlip = getMaterialReceiptSlipJSONData.data.doc;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    setEmployees(employees);
    setMaterialReceiptSlip(materialReceiptSlip);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/materialreceiptslips/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function handleEditMaterialReceiptSlipForm(event) {
    event.preventDefault();
    let errors = [];
    let editMaterialReceiptSlipForm = event.target;

    setIsSubmitting(true);

    let materialReceiptSlipCreator = editMaterialReceiptSlipForm.elements.namedItem("materialReceiptSlipCreator");
    if (materialReceiptSlipCreator) {
      if (!materialReceiptSlipCreator.value) {
        errors.push("Material Receipt Slip creator is required");
      }
      else {
        materialReceiptSlipCreator = materialReceiptSlipCreator.value;
      }
    }

    let materials = [];

    let tbody = event.target.querySelector(`tbody`);
    const currentNumberOfItems = tbody.children.length;
    for (let i = 1; i <= currentNumberOfItems; i++) {
      let row = tbody.querySelector(`tr#itemCount` + i);
      const stockItemObjectId = row.getAttribute(`data-stockid`);
      const stockItemName = row.querySelector(`#nameItemCount${i}`).childNodes[0].nodeValue;
      const demandQuantity = parseFloat(row.querySelector(`#demandQuantityItemCount${i}`).childNodes[0].nodeValue);
      const quoteQuantity = parseFloat(row.querySelector(`#quoteQuantityItemCount${i}`).childNodes[0].nodeValue);
      let rate = parseFloat(row.querySelector(`#rateItemCount${i}`).childNodes[0].nodeValue);
      let orderedQuantity = parseFloat(row.querySelector(`#orderedQuantityItemCount${i}`).childNodes[0].nodeValue);
      let receivedQuantity = parseFloat(row.querySelector(`input[name="receivedQuantityItemCount${i}"`).value);

      if (isNaN(demandQuantity)) {
        errors.push("Demand quantity is not a number");
      }
      if (isNaN(quoteQuantity)) {
        errors.push("Quote quantity is not a number");
      }
      if (isNaN(rate)) {
        errors.push("Quoted rate is not a number");
      }
      if (isNaN(orderedQuantity)) {
        errors.push(`${stockItemName} ordered quantity is not a number`);
      }
      if (isNaN(receivedQuantity)) {
        errors.push(`${stockItemName} received quantity is not a number`);
      }

      materials.push({
        stockItemObjectId,
        stockItemName,
        demandQuantity,
        quoteQuantity,
        rate,
        orderedQuantity,
        receivedQuantity
      });
    }

    let mdConsent = editMaterialReceiptSlipForm.elements.namedItem("mdConsent");
    let mdComment = editMaterialReceiptSlipForm.elements.namedItem("mdComment");
    if (mdConsent) {
      if (mdConsent.checked) {
        mdConsent = "accept"
      }
      else {
        mdConsent = "pending"
      }
      mdComment = mdComment.value;
    }

    let cooConsent = editMaterialReceiptSlipForm.elements.namedItem("cooConsent");
    let cooComment = editMaterialReceiptSlipForm.elements.namedItem("cooComment");
    if (cooConsent) {
      if (cooConsent.checked) {
        cooConsent = "accept"
      }
      else {
        cooConsent = "pending"
      }
      cooComment = cooComment.value;
    }

    let pmConsent = editMaterialReceiptSlipForm.elements.namedItem("pmConsent");
    let pmComment = editMaterialReceiptSlipForm.elements.namedItem("pmComment");
    if (pmConsent) {
      if (pmConsent.checked) {
        pmConsent = "accept"
      }
      else {
        pmConsent = "pending"
      }
      pmComment = pmComment.value;
    }

    let smConsent = editMaterialReceiptSlipForm.elements.namedItem("smConsent");
    let smComment = editMaterialReceiptSlipForm.elements.namedItem("smComment");
    if (smConsent) {
      if (smConsent.checked) {
        smConsent = "accept"
      }
      else {
        smConsent = "pending"
      }
      smComment = smComment.value;
    }

    let deliveryChallan = editMaterialReceiptSlipForm.elements.namedItem("deliveryChallan").files[0];
    if (deliveryChallan) { //undefined if no file was selected
      const clientFileName = deliveryChallan.name;
      if (((deliveryChallan.size / 1024) / 1024) > 5) { //if greater than 5 megabytes
        errors.push("Attached file size should be less than 5 MB");
      }
      else {
        const fileAsBase64 = await convertToBase64(deliveryChallan);
        deliveryChallan = { clientFileName, fileAsBase64 };
      }
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        vendorID: materialReceiptSlip.vendorID._id,
        projectID: materialReceiptSlip.purchaseOrderID.quotationID.projectID._id,
        purchaseOrderID: materialReceiptSlip.purchaseOrderID._id,
        materialReceiptSlipCreator: materialReceiptSlipCreator ? materialReceiptSlipCreator : undefined,
        materials,
        mdConsent: mdConsent ? mdConsent : undefined,
        cooConsent: cooConsent ? cooConsent : undefined,
        pmConsent: pmConsent ? pmConsent : undefined,
        smConsent: smConsent ? smConsent : undefined,
        cooComment: cooComment ? cooComment : undefined,
        mdComment: mdComment ? mdComment : undefined,
        pmComment: pmComment ? pmComment : undefined,
        smComment: smComment ? smComment : undefined,
        deliveryChallan: deliveryChallan ? deliveryChallan : undefined
      };
      try {
        await sendAPIRequest(payload);
        setIsDisabled(true);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit a material receipt slip</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];
  let poItemsList = [];

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (materialReceiptSlip && materialReceiptSlip.materials.length !== 0) {
    let count = 0;
    poItemsList = materialReceiptSlip.materials.map((material, index) => {
      count++;
      return (
        <tr key={material._id} id={`itemCount` + count} data-stockid={material.stockItemObjectId}>
          <td id={`nameItemCount` + count}>{`${material.stockItemName}`}</td>
          <td id={`demandQuantityItemCount` + count}>{`${material.demandQuantity}`}</td>
          <td id={`quoteQuantityItemCount` + count}>{`${material.quoteQuantity}`}</td>
          <td id={`rateItemCount` + count}>{`${material.rate}`}</td>
          <td id={`orderedQuantityItemCount` + count}>{material.orderedQuantity}</td>
          <td>
            <input
              name={`receivedQuantityItemCount` + count}
              type="number"
              defaultValue={material.receivedQuantity}
              min="0"
              step="0.01"
              className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm'
            />
          </td>
        </tr>
      );
    })
  }

  console.log("Before MaterialReceiptSlipsEdit() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit a material receipt slip</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <div className='overflow-x-auto rounded-lg border border-primary p-8 text-gray-900'>
          <form id="editMaterialReceiptSlipForm" onSubmit={handleEditMaterialReceiptSlipForm} className="space-y-5">

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Project Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{materialReceiptSlip.purchaseOrderID.quotationID.projectID.projectName}</div>
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className="text-base font-normal text-left">Vendor Company Name</label>
                <div className="w-full md:w-11/12 py-2 text-sm">{materialReceiptSlip.vendorID.vendorCompanyName}</div>
              </div>

              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                && (
                  <div className="flex flex-col space-y-3 w-full md:w-1/3">
                    <label className="text-base font-normal text-left">Material Receipt Slip Creator</label>
                    <select
                      disabled
                      name="materialReceiptSlipCreator"
                      defaultValue={materialReceiptSlip.materialReceiptSlipCreator._id}
                      className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                    >
                      <option value={""}>Select an employee</option>
                      {employeesList}
                    </select>
                  </div>
                )
              }
            </div>

            <div className='overflow-x-auto rounded-lg border'>
              <table>
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Demand</th>
                    <th>Quote</th>
                    <th>Rate</th>
                    <th>Ordered Quantity</th>
                    <th>Received Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsList}
                </tbody>
              </table>
            </div>

            <div className='flex flex-col gap-y-3 text-sm text-gray-900 font-normal text-left'>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                MD says: <div className='p-2'>{materialReceiptSlip.mdComment ? materialReceiptSlip.mdComment : <i>No comments yet</i>}</div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                COO says: <div className='p-2'>{materialReceiptSlip.cooComment ? materialReceiptSlip.cooComment : <i>No comments yet</i>}</div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Project Manager says: <div className='p-2'>{materialReceiptSlip.pmComment ? materialReceiptSlip.pmComment : <i>No comments yet</i>}</div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Store Manager says: <div className='p-2'>{materialReceiptSlip.smComment ? materialReceiptSlip.smComment : <i>No comments yet</i>}</div>
              </div>
            </div>

            {
              (
                userData.doc.isSuperAdmin ||
                (userData.doc.isAdmin && userData.doc.managementRank === "md")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Managing Director:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="mdConsent" value="accept" defaultChecked={materialReceiptSlip.mdConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="mdComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={materialReceiptSlip.mdComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                (userData.doc.isAdmin && userData.doc.managementRank === "coo")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Chief Operations Officer:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="cooConsent" value="accept" defaultChecked={materialReceiptSlip.cooConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="cooComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={materialReceiptSlip.cooComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.role.create.includes("projects")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Project Manager:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="pmConsent" value="accept" defaultChecked={materialReceiptSlip.pmConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="pmComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={materialReceiptSlip.pmComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.role.create.includes("materialreceiptslips")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Store Manager:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="smConsent" value="accept" defaultChecked={materialReceiptSlip.smConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="smComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={materialReceiptSlip.smComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            <div className='flex flex-col lg:flex-row border border-[#8E8EA1] rounded-[10px]'>
              <div className='border border-r-[#8E8EA1] p-3 lg:basis-3/12'>
                Delivery Challan:
                <input
                  type="file"
                  name="deliveryChallan"
                  className='border border-[#8E8EA1] text-[#8E8EA1] mt-2 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>
              <div className='border border-r-[#8E8EA1] p-3 lg:basis-7/12'>
                {
                  materialReceiptSlip.deliveryChallan?.clientFileName && (
                    <img 
                      src={staticFilesURL + "/materialreceiptslips/" + materialReceiptSlip.deliveryChallan.fileNameOnServerDisk} 
                      alt="photograph of delivery challan"
                    />
                  )
                }
              </div>
              <div className='p-3 lg:basis-2/12'>
                <a
                  className='btn-primary justify-center'
                  href={ materialReceiptSlip.deliveryChallan?.clientFileName && (
                    `${staticFilesURL}/materialreceiptslips/${materialReceiptSlip.deliveryChallan.fileNameOnServerDisk}`
                  )}
                  download
                >
                  Download
                </a>
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting || isDisabled} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
