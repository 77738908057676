import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './employeesBonuses.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { FaPlus } from "react-icons/fa6";
//import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Pagination from 'components/global/pagination';
import { toast } from 'react-toastify';

export const EmployeesBonuses = () => {
  const [message, setMessage] = useState(null);
  const [employeesBonuses, setEmployeesBonuses] = useState(null);
  const [employeesBonusesTotalCount, setEmployeesBonusesTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/employeesbonuses?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employeesBonuses = jsonData.data.docs;

    setEmployeesBonuses(employeesBonuses);
    setEmployeesBonusesTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  const handleDelete = async (e) => {
    let deleteButton = e.target.parentElement;
    let id = deleteButton.getAttribute("data-id");
    if (!id) { //the case when e.target was button element and not svg element
      return; //early return
    }
    setIsLoading(true);
    let url = baseURL + "/employeesbonuses/delete/" + id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers
      });
      const jsonData = await response.json();
      if (!response.ok) {
        toast.error(jsonData.data.message);
        setMessage(jsonData.data.message);
        setIsLoading(false);
        return; //early return
      }
      setEmployeesBonuses(employeesBonuses.filter((e) => e._id !== id));
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoading(false);
    }
    catch (error) {
      toast.error("An error occurred while communicating with the server.");
      setMessage("An error occurred while communicating with the server.");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("employeebonus")
    ) &&
    <Link to="/employees-increments/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>New Employee Bonus</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Employees Bonuses</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesBonusesList = [];

  if (employeesBonuses && employeesBonuses.length !== 0) { //on second render
    employeesBonusesList = employeesBonuses.map((employeeBonus, index) => {
      //const editLink = "/employeesBonuses/edit/" + employeeBonus._id;

      let bonusType;

      if (employeeBonus.bonusType === "yearEnd")
        bonusType = "Year End";
      else if (employeeBonus.bonusType === "eidAlFitr")
        bonusType = "Eid-al-Fitr";
      else if (employeeBonus.bonusType === "eidAlAdha")
        bonusType = "Eid-al-Adha";
      else if (employeeBonus.bonusType === "other")
        bonusType = "Other";

      const awardees = []
      for (const employee of employeeBonus.employeeIDs) {
        awardees.push(`${employee.full_name}`);
      }
      const displayAwardees = awardees.join(', ');

      let bonusDate = new Date(Date.parse(employeeBonus.bonusMonth));

      return (
        <tr key={employeeBonus._id}>
          <td className="px-6 py-3 whitespace-nowrap">{employeeBonus.employeeBonusID}</td>
          <td className="px-6 py-3 whitespace-nowrap">{displayAwardees}</td>
          <td className="px-6 py-3 whitespace-nowrap">{bonusType}</td>
          <td className="px-6 py-3 whitespace-nowrap">{`${bonusDate.getMonth()+1} / ${bonusDate.getFullYear()}`}</td>
          <td className="px-6 py-3 whitespace-nowrap">{employeeBonus.bonusAmount}</td>
          <td className="px-6 py-3 whitespace-nowrap">{employeeBonus.createdAt.split('T')[0]}</td>
          <td className="text-primary px-6 py-4 whitespace-nowrap text-center">
            <button data-id={employeeBonus._id} onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }

  console.log("Before EmployeesBonuses() rendering");

  return (
    <Layout>
      <div className="space-y-4 text-gray-900">

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Employees Bonuses</h2>
          {NewDocumentLink}
        </div>

        <div className='flex flex-row justify-between'>
          {message && message}
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto">
            <thead className="text-[14px] border border-b-[#6843BE]">
              <tr className='bg-lightSlate'>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Employees</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Bonus Type</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">For Month</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Bonus Amount</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Creation Date</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {employeesBonusesList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(employeesBonusesTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
