import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './eventsEdit.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { useParams } from "react-router-dom";
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const EventsEdit = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const params = useParams();

  const [title, setTitle] = useState("");
  const [allDay, setAllDay] = useState(false);
  const [people, setPeople] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getEventURL = baseURL + "/events/" + params.id;
    const getEventResponse = await fetch(getEventURL, {
      method: "GET",
      headers: headers
    });
    const getEventJSONData = await getEventResponse.json();
    if (!getEventResponse.ok) {
      toast.error(getEventJSONData.data.message);
      setMessage(getEventJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const event = getEventJSONData.data.doc;

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    let peopleIDs = []

    for (let obj of event.people) {
      peopleIDs.push(obj._id);
    }

    setEmployees(employees);
    setTitle(event.title);
    setPeople(peopleIDs);
    setAllDay(event.allDay);
    setStartDate(new Date(Date.parse(event.startDate)));
    setEndDate(new Date(Date.parse(event.endDate)));
    setStatus(event.status);
    setDescription(event.description);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/events/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditEventForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!title) {
      errors.push(<div>Event title cannot be empty</div>);
    }

    if (!status) {
      errors.push(<div>Event status is required</div>);
    }

    if (endDate.getTime() < startDate.getTime()) {
      errors.push(<div>Event start date, time can't be earlier than end date, time.</div>);
    }

    let allDayStart, allDayEnd;

    if (allDay) {
      allDayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      allDayEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
    }
    else {
      if (endDate.getTime() === startDate.getTime()) {
        errors.push(<div>Event start date, time can't be equal to end date, time.</div>);
      }
    }

    let payload = {
      people: people ? people : [],
      title,
      allDay,
      startDate: allDay ? allDayStart : startDate,
      endDate: allDay ? allDayEnd : endDate,
      status,
      description: description ? description : ""
    }

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit event</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesOptionsList = [];
  let peopleDefaultList = [];


  if (employees && employees.length !== 0) {
    employeesOptionsList = employees.map((employee, index) => {
      return {
        value: employee._id,
        label: `${employee.full_name} ${employee.custom_id}`
      }
    });

    //peopleDefaultList = employeesOptionsList.filter(option => people.find((el) => option.value === el._id));
    peopleDefaultList = employeesOptionsList.filter(option => people.includes(option.value));

  }

  console.log("Before EventsEdit() rendering");
  console.log(startDate.toISOString())
  console.log(endDate.toISOString())


  return (
    <Layout>
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit event</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form onSubmit={handleEditEventForm}>

          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Title *</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    People
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <Select
                    isMulti
                    name="people"
                    defaultValue={peopleDefaultList}
                    onChange={(selectedOptions) => {
                      let peopleList = [];
                      if (selectedOptions && selectedOptions.length > 0) {
                        peopleList = selectedOptions.map((option) => {
                          return option.value; //an ObjectId
                        });
                      }
                      setPeople(peopleList);
                    }}
                    options={
                      employees && employees.length !== 0 && employees.map((employee, index) => {
                        return {
                          value: employee._id,
                          label: `${employee.full_name} ${employee.custom_id}`
                        }
                      })
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#8E8EA1',
                      }),
                    }}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Status *
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="status"
                    defaultValue={status}
                    options={
                      [
                        { value: 'onSchedule', label: 'On Schedule' },
                        { value: 'cancelled', label: 'Cancelled' },
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setStatus(selectedOption.value)}
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div className='w-full md:w-11/12'>
                  <input
                    type="checkbox"
                    name="allDay"
                    defaultChecked={allDay}
                    onChange={(e) => setAllDay(e.target.checked)}
                  />{" "}
                  <label className='font-normal'>
                    All day *
                  </label>
                </div>
              </div>

            </div>

            {
              allDay ? (
                <React.Fragment>
                  <div className="flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center">

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>Start Date *</label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          dateFormat="MMMM d, yyyy"
                          className='border border-[#8E8EA1] py-2 rounded-md px-3 outline-none'
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>End Date *</label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          dateFormat="MMMM d, yyyy"
                          className='border border-[#8E8EA1] py-2 rounded-md px-3 outline-none'
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center">

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>Start Date *</label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          showTimeInput
                          timeInputLabel="Time:"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          className='border border-[#8E8EA1] py-2 rounded-md px-3 outline-none'
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>End Date *</label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          showTimeInput
                          timeInputLabel="Time:"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          className='border border-[#8E8EA1] py-2 rounded-md px-3 outline-none'
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            }

            <div className='flex flex-col gap-y-3'>
              <div>
                <label className='font-normal'>Description</label>
              </div>
              <div>
                <textarea
                  rows="4"
                  cols="50"
                  name="description"
                  value={description}
                  onChange={(e) => {
                    e.target.value ? setDescription(e.target.value) : setDescription('');
                  }}
                  className='border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
                >
                </textarea>
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div >
    </Layout >
  );
}