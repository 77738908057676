import React, { useState, useEffect, useContext } from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const TaskTabAttachments = ({ initialAttachments, setTask, initialEmployees, params }) => {
  const [attachments, setAttachments] = useState(initialAttachments);

  const employees = initialEmployees;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeestasks/update-attachments/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setTask(jsonData.data.doc);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  /*const handleTaskFileAttachmentChange = async (event) => {
    const file = event.target.files[0];
    try {
      if (((file.size / 1024) / 1024) > 4) { //if greater than 4 megabytes
        toast.error(`File size too large: ${file.name} Limit is 4MB per file`);
        setMessage(`File size too large: ${file.name} Limit is 4MB per file`);
        event.target.value = null
      }
      else {
        const dataURL = await convertToBase64(file);
        setAttachments([...attachments, {
          clientFileName: file.name,
          fileAsBase64: dataURL
        }]);
      }
    }
    catch (error) {
      console.log(error);
    }
  };*/

  const sendDeleteFileRequest = async (fileObjectId, fileNameOnServerDisk) => {
    setIsDeleting(true);
    let payload = {
      fileObjectId: fileObjectId,
      employeeTaskObjectId: params.id,
      fileNameOnServerDisk
    }
    let url = `${baseURL}/employeestasks/delete-attachment`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setIsDeleting(false);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setIsDeleting(false);
      return Promise.reject("failure");
    }
  }

  async function handleAttachmentsEdit(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    let payload = {
      attachments
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  const handleAddNewFileAttachment = (e) => {

  }

  console.log("Before TaskTabAttachments() rendering");

  return (
    <React.Fragment>
      {
        attachments && attachments.length > 0 && attachments.map((attachment, index) => {
          let link = "#"
          if (attachment.fileNameOnServerDisk)
            link = staticFilesURL + "/employees/" + attachment.fileNameOnServerDisk;
          return (
            <div key={index} className='flex flex-row gap-3'>
              <div className='md:w-4/6'>
                <a
                  key={attachment._id}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {attachment.clientFileName}
                </a>
              </div>
              <div className='md:w-2/6'>
                <button
                  onClick={async (e) => {
                    e.preventDefault();
                    let fileObjectId = e.target.getAttribute("data-fileobjectid");
                    let clientfilename = e.target.getAttribute("data-clientfilename");
                    if (fileObjectId) {
                      try {
                        await sendDeleteFileRequest(fileObjectId, attachment.fileNameOnServerDisk);
                        setAttachments(attachments.filter((file) => file._id !== fileObjectId));
                      }
                      catch (error) {
                        console.log(error);
                      }
                    }
                    else {
                      setAttachments(attachments.filter((file) => file.clientFileName !== clientfilename));
                    }
                  }}
                  data-fileobjectid={attachment._id}
                  data-clientfilename={attachment.clientFileName}
                  className='text-white bg-primary rounded-[40px] w-[100px] h-9'
                >
                  {isDeleting ? <ClipLoader size={17} color="#fff" /> : "Delete"}
                </button>
              </div>
            </div>
          );
        })
      }

    </React.Fragment>
  );
}