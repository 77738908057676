import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './singleEmployeeReport.css';
import { Link, useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import SelectBox from 'components/global/SelectBox.jsx';
import { ClipLoader } from 'react-spinners';

export const SingleEmployeeReport = () => {
  const [message, setMessage] = useState(null);

  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { userData } = useContext(AuthContext);

  async function fetchData() {
    let getEmployeesURL = baseURL + "/employees";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setIsLoading(false)
  }

  async function fetchReport(payload) {
    let url = baseURL + "/reports/single-employee-report";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  const handleEmployeeChange = async (e) => {
    e.preventDefault();

    let errors = [];

    setIsSubmitting(true);

    if (!employee) {
      errors.push(<div>Employee cannot be empty</div>);
    }

    if (!startDate) {
      errors.push(<div>Start date cannot be empty</div>);
    }

    if (!endDate) {
      errors.push(<div>End date cannot be empty</div>);
    }

    let payload = {
      employeeID: employee,
      startDate,
      endDate,
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
    }
    else {
      try {
        console.log("Now sending")
        await fetchReport(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);


  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-semibold'>Single Employee Report</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before SingleEmployeeReport() rendering");

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-semibold'>Single Employee Report</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='rounded-lg border border-[#6843BE] m-4 md:m-0 p-4 text-sm'>

          <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='text-[#22242C] font-normal'>
                  Employee Name *
                </label>
              </div>
              <div className='w-full md:w-11/12'>
                <SelectBox
                  name="employee"
                  options={
                    employees && employees.length !== 0 && employees.map((employee, index) => {
                      return {
                        value: employee._id,
                        label: `${employee.full_name} ${employee.custom_id}`
                      }
                    })
                  }
                  handleChange={(selectedOption) => setEmployee(selectedOption.value)}
                />
              </div>
            </div>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='text-[#22242C] font-normal'>
                  Start Date *
                </label>
              </div>
              <div className='w-full md:w-11/12'>
                <input
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                />
              </div>
            </div>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='text-[#22242C] font-normal'>
                  End Date *
                </label>
              </div>
              <div className='w-full md:w-11/12'>
                <input
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                />
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <button 
                disabled={isSubmitting}
                onClick={handleEmployeeChange}
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </div>

      </div>
    </Layout >
  );
}
